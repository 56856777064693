<template>
  <page :loading="!contentLoaded">
    <template v-slot:title>
      Gedeelde logs
    </template>
    <template>
      <v-data-table
        :items="table.items"
        :headers="table.headers"
      >
        <template v-slot:item.sender="{ item }">
          {{ item.sender.first_name }} {{ item.sender.last_name }}
        </template>
        <template v-slot:item.created_at="{ item }">
          {{ item.created_at | formatDate }}
        </template>
        <template v-slot:item.actions="{ item }">
          <v-btn icon @click="openDownloadDialog(item)"
            ><v-icon>mdi-download</v-icon></v-btn
          >
        </template>
      </v-data-table>
    </template>
    <v-dialog v-model="downloadLogDialog.show" width="600">
      <v-sheet>
        <v-container>
          <h1 class="headline">Logfile ophalen</h1>
          <v-divider />
          <v-text-field
            label="Wachtwoord"
            :type="downloadLogDialog.showPassword ? 'text' : 'password'"
            v-model="downloadLogDialog.password"
            :append-icon="downloadLogDialog.showPassword ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"
            @click:append="downloadLogDialog.showPassword = !downloadLogDialog.showPassword"
          />
          <v-btn color="primary" @click="downloadLogFile">Downloaden</v-btn>
        </v-container>
      </v-sheet>
    </v-dialog>
  </page>
</template>

<script>
import RepositoryFactory from "@/repositories/RepositoryFactory";

export default {
  name: "SharedLogsOverview",
  data() {
    return {
      table: {
        headers: [
          {
            text: "ID",
            value: "id"
          },
          {
            text: "Afzender",
            value: "sender"
          },
          {
            text: "Geëxporteerd op",
            value: "created_at"
          },
          {
            text: "Acties",
            value: "actions"
          }
        ],
        items: undefined
      },
      downloadLogDialog: {
        show: false,
        selectedLog: undefined,
        password: "",
        showPassword: false
      }
    };
  },
  methods: {
    openDownloadDialog(log) {
      this.downloadLogDialog.selectedLog = log;
      this.downloadLogDialog.show = true;
    },
    downloadLogFile() {
      let data = {
        password: this.downloadLogDialog.password
      };
      RepositoryFactory.get("sharedLogs")
        .get(data, this.downloadLogDialog.selectedLog.id)
        .then(({ data }) => {
          const downloadUrl = window.URL.createObjectURL(new Blob([data]));
          const link = document.createElement("a");
          link.href = downloadUrl;
          link.setAttribute("download", "logfile.csv");
          document.body.appendChild(link);
          link.click();
          link.remove();
        });
    }
  },
  computed: {
    contentLoaded() {
      return this.table.items !== undefined;
    }
  },
  mounted() {
    RepositoryFactory.get("sharedLogs")
      .index()
      .then(res => (this.table.items = res.data.data));
  }
};
</script>

<style scoped></style>
