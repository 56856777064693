<template>
  <page :loading="false">
    <template v-slot:title>
      Registratie
    </template>
    <template>
      <information-block>
        <template v-slot:title>Registreren</template>
        <template v-slot:content>
          <p>
            Voer een wachtwoord in om uw account aan te maken. Hierna kunt u uw
            tweefactor authenticatie activeren.
          </p>
          <p>Sla deze stap niet over.</p>
        </template>
      </information-block>
      <v-form class="col-2-grid" v-model="formValidation.valid">
        <v-text-field
          label="Organisatie"
          disabled
          :value="computedQueryVariables.organisation_name || organisation.name"
        />
        <span />
        <v-text-field
          label="Wachtwoord"
          :rules="formValidation.passwordRules"
          v-model="input.password"
          :append-icon="formValidation.showPassword ? 'mdi-eye' : 'mdi-eye-off'"
          :type="formValidation.showPassword ? 'text' : 'password'"
          @click:append="
            formValidation.showPassword = !formValidation.showPassword
          "
        />
        <v-text-field
          label="Wachtwoord bevestigen"
          :rules="formValidation.passwordConfRules"
          v-model="input.passwordConfirmation"
          :append-icon="
            formValidation.showPasswordConfirmation ? 'mdi-eye' : 'mdi-eye-off'
          "
          :type="formValidation.showPasswordConfirmation ? 'text' : 'password'"
          @click:append="
            formValidation.showPasswordConfirmation = !formValidation.showPasswordConfirmation
          "
        />
      </v-form>
      <v-btn
        :disabled="!formValidation.valid"
        @click="register"
        :loading="formValidation.loading"
        color="primary"
        >Registeren</v-btn
      >
    </template>
    <google-auth-popup
      :username="google2FA.username"
      :qr-code="google2FA.activationQR"
    />
  </page>
</template>

<script>
import GoogleAuthPopup from "@/views/Auth/GoogleAuthPopup";
import RepositoryFactory from "@/repositories/RepositoryFactory";
import QRCode from "qrcode";
export default {
  name: "Registration",
  props: {
    signedLink: String
  },
  components: {
    GoogleAuthPopup
  },
  data() {
    return {
      formValidation: {
        valid: false,
        showPassword: false,
        showPasswordConfirmation: false,
        passwordRules: [
          v => !!v || "Wachtwoord is verplicht",
          v => v.length >= 12 || "Wachtwoord moet minstens 12 tekens bevatten"
        ],
        passwordConfRules: [
          v => !!v || "Wachtwoordbevestiging is verplicht",
          v => v === this.input.password || "Wachtwoorden komen niet overeen"
        ],
        loading: false
      },
      input: {
        password: "",
        passwordConfirmation: ""
      },
      google2FA: {
        activationQR: undefined,
        username: ""
      },
      organisation: {}
    };
  },
  methods: {
    register() {
      let signedLink = atob(this.signedLink);
      let signedParameters = signedLink.substr(signedLink.indexOf("?") + 1);
      this.formValidation.loading = true;
      (this.computedQueryVariables.permissions &&
      this.computedQueryVariables.permissions.includes("admin")
        ? RepositoryFactory.get("auth").registerAdmin(
            this.input,
            signedParameters
          )
        : RepositoryFactory.get("auth").register(this.input, signedParameters)
      )
        .then(res => {
          let dataUrl = res.data.google2fa;
          QRCode.toDataURL(dataUrl).then(url => {
            this.google2FA.username = res.data.user.email;
            this.google2FA.activationQR = url;
          });
        })
        .catch(err => {
          this.$store.dispatch(
            "notifications/setErrorMessage",
            this.$options.filters.translate(err.response.data.message)
          );

          let errors = Object.keys(err.response.data.errors);
          if (errors.indexOf("email") >= 0) {
            this.$store.dispatch(
              "notifications/setErrorMessage",
              "error.email_taken"
            );
          }
        })
        .finally(() => {
          this.formValidation.loading = false;
        });
    }
  },
  computed: {
    computedQueryVariables() {
      let url = atob(this.signedLink);
      let queryParams = url.slice(url.indexOf("?") + 1).split("&");
      let queryObj = {};
      queryParams.map(el => {
        let indexVal = el.split("=");
        if (indexVal[0] && indexVal[0].includes("permissions")) {
          queryObj["permissions"] = [
            ...(queryObj["permissions"] || []),
            decodeURIComponent(indexVal[1])
          ];
        } else queryObj[indexVal[0]] = decodeURIComponent(indexVal[1]);
      });
      return queryObj;
    }
  }
};
</script>

<style scoped></style>
