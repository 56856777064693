<template>
  <div>
    <v-container class="col-md-4">
      <v-sheet elevation="4">
        <v-container>
          <div class="d-flex align-end justify-center mb-5">
            <img src="@/assets/logo_extended.png" width="230" />
          </div>
          <h1 class="display-1">Inloggen</h1>
          <v-divider class="mt-2" />
          <v-form
            v-model="formValidation.valid"
            @submit.prevent="formValidation.valid ? login : undefined"
            @keyup.enter="login"
          >
            <v-text-field
              label="E-Mail adres"
              :rules="formValidation.emailRules"
              v-model="input.email"
            />
            <v-text-field
              label="Wachtwoord"
              :append-icon="
                formValidation.showPassword ? 'mdi-eye' : 'mdi-eye-off'
              "
              :type="formValidation.showPassword ? 'text' : 'password'"
              :rules="formValidation.passwordRules"
              v-model="input.password"
              @click:append="
                formValidation.showPassword = !formValidation.showPassword
              "
            />
            <!--            <v-text-field-->
            <!--              label="Authenticator code"-->
            <!--              :rules="formValidation.google2FARules"-->
            <!--              v-model="input.google2FA"-->
            <!--            />-->
            <label for="2fa-token">Authenticator code</label>
            <v-otp-input
              id="2fa-token"
              length="6"
              type="number"
              v-model="input.google2FA"
              :rules="formValidation.google2FARules"
            />
            <v-btn
              text
              color="primary"
              block
              @click="forgotPasswordDialog.show = true"
              >Wachtwoord vergeten</v-btn
            >
            <v-btn
              color="primary"
              type="submit"
              tile
              :disabled="!formValidation.valid"
              @click="login"
              :loading="request.loading"
              >Inloggen</v-btn
            >
          </v-form>
        </v-container>
      </v-sheet>
      <v-dialog v-model="forgotPasswordDialog.show" width="600">
        <v-sheet>
          <v-container>
            <h1 class="headline">Wachtwoord vergeten</h1>
            <v-divider class="mt-2 mb-2" />
            <information-block>
              <template v-slot:title>Nieuw wachtwoord aanvragen</template>
              <template v-slot:content>
                <p>
                  U bent uw wachtwoord vergeten en wilt een nieuwe aanvragen.
                  Door hieronder het e-mail adres in te vullen dat gekoppeld is
                  aan uw account, word een e-mail verstuurd waar u een nieuw
                  wachtwoord kunt instellen.
                </p>
                <p>
                  Let wel, als uw wachtwoord gereset is, zijn bepaalde gegevens
                  die voorheen versleuteld zijn opgeslagen niet meer
                  beschikbaar.
                </p>
              </template>
            </information-block>
            <v-form v-model="forgotPasswordDialog.valid">
              <v-text-field
                label="E-mail adres"
                v-model="forgotPasswordDialog.input.email"
                :rules="forgotPasswordDialog.rules.emailRules"
              />
              <v-btn
                @click="requestPasswordResetMail"
                :loading="forgotPasswordDialog.loading"
                color="primary"
                :disabled="!forgotPasswordDialog.valid"
                >Verzenden</v-btn
              >
            </v-form>
          </v-container>
        </v-sheet>
      </v-dialog>
    </v-container>
    <v-footer color="white" fixed class="d-flex justify-center">
      <span>
        Waarschuwing: Oneigenlijk gebruik van deze software is niet toegestaan
      </span>
    </v-footer>
  </div>
</template>

<script>
import RepositoryFactory from "@/repositories/RepositoryFactory";

export default {
  name: "Login",
  data() {
    return {
      formValidation: {
        valid: false,
        showPassword: false,
        emailRules: [v => !!v || "E-Mail adres is verplicht"],
        passwordRules: [v => !!v || "Wachtwoord is verplicht"],
        google2FARules: [
          v => !!v || "Google Authenticator code is verplicht",
          v => v.length === 6 || "Vul uw code volledig in"
        ]
      },
      input: {
        email: "",
        password: "",
        google2FA: ""
      },
      request: {
        loading: false,
        response: undefined
      },
      forgotPasswordDialog: {
        show: false,
        loading: false,
        valid: false,
        input: {
          email: ""
        },
        rules: {
          emailRules: [
            v => !!v || "Dit veld is verplicht",
            v => v.includes("@") || "Vul een geldig E-mail adres in"
          ]
        }
      }
    };
  },
  methods: {
    login() {
      this.request.loading = true;
      this.$store
        .dispatch("auth/authenticate", this.input)
        .then(() => this.$router.push({ name: "Home" }))
        .catch(err => {
          let message = "";
          if (err.response.status === 401) message = err.response.data.message;
          else message = "error.generic";
          this.$store.dispatch(
            "notifications/addFeedbackNotification",
            message
          );
        })
        .finally(() => {
          this.request.loading = false;
        });
    },
    requestPasswordResetMail() {
      this.forgotPasswordDialog.loading = true;
      RepositoryFactory.get("auth")
        .requestNewPassword(this.forgotPasswordDialog.input)
        .then(() => {
          this.$store.dispatch(
            "notifications/addFeedbackNotification",
            "feedback.resetPasswordMailSuccess"
          );
        })
        .catch(() => {
          this.$store.dispatch(
            "notifications/addFeedbackNotification",
            "error.generic"
          );
        })
        .finally(() => {
          this.forgotPasswordDialog.loading = false;
          this.forgotPasswordDialog.show = false;
        });
    }
  }
};
</script>

<style scoped></style>
