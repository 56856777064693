<template>
  <v-list-item>
    <v-list-item-content>
      <v-list-item-title>{{ this.title }}</v-list-item-title>
      <v-list-item-subtitle>{{ this.userValue }}</v-list-item-subtitle>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
export default {
  name: "UserDetailsListItem",
  props: {
    userValue: {
      required: false
    },
    title: {
      type: String,
      required: true
    }
  }
};
</script>

<style scoped></style>
