<template>
  <v-container>
    <table>
      <col width="150">
      <tr>
        <td>Huidig saldo</td>
        <td>{{ computedBalance }}</td>
      </tr>
    </table>
  </v-container>
</template>

<script>
import RepositoryFactory from "@/repositories/RepositoryFactory";

export default {
  name: "ManageOTK",
  props: {
    license: {
      type: Object
    }
  },
  data() {
    return {
      form: {
        valid: false
      },
      balance: undefined
    };
  },
  methods: {
    getBalance() {
      RepositoryFactory.get("otkProduct")
        .getBalance(this.license.id)
        .then(res => {
          this.balance = res.data;
        })
        .catch()
        .finally();
    }
  },
  computed: {
    computedBalance() {
      return this.balance ? this.balance : "Aan het laden...";
    }
  },
  mounted() {
    this.getBalance();
  }
};
</script>

<style scoped>

</style>
