<template>
  <v-card>
    <v-container>
    <v-row>
        <!-- First name -->
        <v-col cols="6">
            <UserDetailsListItem title="Voornaam" :user-value="user.first_name" />
        </v-col>
        <v-col cols="6">
            <UserDetailsListItem title="Achternaam" :user-value="user.last_name" />
        </v-col>

        <v-col cols="6">
            <UserDetailsListItem title="Emailadres" :user-value="user.email" />
        </v-col>
        <v-col cols="6">
            <UserDetailsListItem title="Organisatie" :user-value="currentOrganisation.name" />
        </v-col>

        <v-col cols="6">
            <UserDetailsListItem title="Telefoonnummer" :user-value="user.phone" />
        </v-col>
        <v-col cols="6">
            <UserDetailsListItem title="Mobiele nummer" :user-value="user.mobile" />
        </v-col>

        <v-col cols="6">
            <UserDetailsListItem title="Afdeling" :user-value="user.department" />
        </v-col>
        <v-col cols="6">
            <UserDetailsListItem title="Functie" :user-value="user.job_title" />
        </v-col>

        <v-col cols="6">
          <UserDetailsListItem title="Activatiedatum" :user-value="user.created_at | formatDate" />
        </v-col>
    </v-row>
    </v-container>
  </v-card>
</template>

<script>
import UserDetailsListItem from "@/components/Licenses/LicenseManagement/UserDetailsListItem";
import { mapGetters } from "vuex";

export default {
    name: "UserDetailsCard",
    components: { UserDetailsListItem },
    props: {
        user: {
          type: Object,
          required: true
        }
    },
    computed: {
      ...mapGetters("organisations", [
        "getOrganisation"
      ]),
      currentOrganisation() {
        let orgId = this.user.organisation_id;
        return this.getOrganisation(orgId);
      },
    }
  };
</script>

<style scoped></style>