var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"observer"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('validation-provider',{attrs:{"name":"Voornaam","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"prefix":"*","error-messages":errors,"label":"Voornaam","required":""},model:{value:(_vm.input.first_name),callback:function ($$v) {_vm.$set(_vm.input, "first_name", $$v)},expression:"input.first_name"}})]}}])}),_c('validation-provider',{attrs:{"name":"Achternaam","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"prefix":"*","error-messages":errors,"label":"Achternaam","required":""},model:{value:(_vm.input.last_name),callback:function ($$v) {_vm.$set(_vm.input, "last_name", $$v)},expression:"input.last_name"}})]}}])}),_c('validation-provider',{attrs:{"name":"Emailadres","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"prefix":"*","error-messages":errors,"label":"Emailadres","required":""},model:{value:(_vm.input.email),callback:function ($$v) {_vm.$set(_vm.input, "email", $$v)},expression:"input.email"}})]}}])}),_c('validation-provider',{attrs:{"name":"Mobiele telefoonnummer","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Mobiele telefoonnummer"},model:{value:(_vm.input.mobile),callback:function ($$v) {_vm.$set(_vm.input, "mobile", $$v)},expression:"input.mobile"}}),_c('v-text-field',{attrs:{"error-messages":errors,"label":"Vast telefoonnummer"},model:{value:(_vm.input.phone),callback:function ($$v) {_vm.$set(_vm.input, "phone", $$v)},expression:"input.phone"}})]}}])}),_c('validation-provider',{attrs:{"name":"Afdeling","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"prefix":"*","error-messages":errors,"label":"Afdeling","required":""},model:{value:(_vm.input.department),callback:function ($$v) {_vm.$set(_vm.input, "department", $$v)},expression:"input.department"}})]}}])}),_c('validation-provider',{attrs:{"name":"Functie","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"prefix":"*","error-messages":errors,"label":"Functie"},model:{value:(_vm.input.job_title),callback:function ($$v) {_vm.$set(_vm.input, "job_title", $$v)},expression:"input.job_title"}})]}}])}),(_vm.isDigitaleOpsporing)?_c('v-container',{attrs:{"fluid":""}},[_c('v-checkbox',{attrs:{"label":("Is " + (_vm.input.first_name) + " " + (_vm.input.last_name) + " een klantbeheerder?")},model:{value:(_vm.input.admin),callback:function ($$v) {_vm.$set(_vm.input, "admin", $$v)},expression:"input.admin"}})],1):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }