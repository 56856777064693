<template>
  <div style="padding: 15px;">
    <information-block v-if="!asAdmin && user.edit_locked">
      <template v-slot:title>Mededeling betreft uw primaire gebruikersgegevens</template>
      <template v-slot:content>
        <p>
          U kunt uw voornaam, achternaam en emailadres niet meer wijzigen. Wilt u dit wel, neem contact op met uw accountbeheerder voor meer informatie.
        </p>
      </template>
    </information-block>
    <v-form ref="form">
      <v-row>
        <v-col cols="6">
          <v-text-field v-if="editLocked"
            validate-on-blur
            prefix="*"
            v-model="user.first_name"
            :rules="rules.firstNameRules"
            label="Voornaam"
            placeholder="Voornaam"
            :append-icon="copyTooltip.show && copyTooltip.target === 'first_name' ? 'mdi-check' : 'mdi-clipboard-text-multiple-outline'"
            @click:append="copyValueToClipboard(user.first_name, 'first_name')"
          >
          </v-text-field>
          <v-text-field v-else
            disabled
            v-model="user.first_name"
            label="Voornaam"
            placeholder="Voornaam"
            :append-icon="copyTooltip.show && copyTooltip.target === 'first_name' ? 'mdi-check' : 'mdi-clipboard-text-multiple-outline'"
            @click:append="copyValueToClipboard(user.first_name, 'first_name')"
          >
          </v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field v-if="editLocked"
            validate-on-blur
            prefix="*"
            v-model="user.last_name"
            :rules="rules.lastNameRules"
            label="Achternaam"
            placeholder="Achternaam"
            :append-icon="copyTooltip.show && copyTooltip.target === 'last_name' ? 'mdi-check' : 'mdi-clipboard-text-multiple-outline'"
            @click:append="copyValueToClipboard(user.last_name, 'last_name')"
          ></v-text-field>
          <v-text-field v-else
            disabled
            v-model="user.last_name"
            label="Achternaam"
            placeholder="Achternaam"
            :append-icon="copyTooltip.show && copyTooltip.target === 'last_name' ? 'mdi-check' : 'mdi-clipboard-text-multiple-outline'"
            @click:append="copyValueToClipboard(user.last_name, 'last_name')"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="6">
          <v-text-field v-if="editLocked"
            validate-on-blur
            prefix="*"
            v-model="user.email"
            :rules="rules.emailRules"
            label="Emailadres"
            placeholder="Emailadres"
            required
            :append-icon="copyTooltip.show && copyTooltip.target === 'email' ? 'mdi-check' : 'mdi-clipboard-text-multiple-outline'"
            @click:append="copyValueToClipboard(user.email, 'email')"
          ></v-text-field>
          <v-text-field v-else
            disabled
            v-model="user.email"
            label="Emailadres"
            placeholder="Emailadres"
            :append-icon="copyTooltip.show && copyTooltip.target === 'email' ? 'mdi-check' : 'mdi-clipboard-text-multiple-outline'"
            @click:append="copyValueToClipboard(user.email, 'email')"
          >
          </v-text-field>
        </v-col>
        <v-col cols="6"></v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <v-text-field
            validate-on-blur
            prefix="*"
            v-model="user.phone"
            :rules="rules.phoneRules"
            label="Telefoonnummer"
            placeholder="Telefoonnummer"
            required
            :append-icon="copyTooltip.show && copyTooltip.target === 'phone' ? 'mdi-check' : 'mdi-clipboard-text-multiple-outline'"
            @click:append="copyValueToClipboard(user.phone, 'phone')"
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field
            validate-on-blur
            prefix="*"
            v-model="user.mobile"
            :rules="rules.mobileRules"
            label="Mobiele nummer"
            placeholder="Mobiele nummer"
            required
            :append-icon="copyTooltip.show && copyTooltip.target === 'mobile' ? 'mdi-check' : 'mdi-clipboard-text-multiple-outline'"
            @click:append="copyValueToClipboard(user.mobile, 'mobile')"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="6">
          <v-text-field
            validate-on-blur
            v-model="user.department"
            label="Afdeling"
            placeholder="Afdeling"
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field
            validate-on-blur
            v-model="user.job_title"
            label="Functie"
            placeholder="Functie"
            :append-icon="copyTooltip.show && copyTooltip.target === 'job_title' ? 'mdi-check' : 'mdi-clipboard-text-multiple-outline'"
            @click:append="copyValueToClipboard(user.job_title, 'job_title')"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-btn class="mr-4 mt-5" color="primary" @click="submit">
        Opslaan
      </v-btn>
      <v-btn class="mt-5" color="error" @click="cancel">
        Annuleren
      </v-btn>
    </v-form>
  </div>
</template>

<script>
export default {
  name: "UserDetailsForm",
  props: {
    user: Object
  },

  data() {
    return {
      valid: false,
      input: {
        first_name: "",
        last_name: "",
        email: "",
        mobile: "",
        phone: "",
        department: "",
        job_title: ""
      },
      rules: {
        firstNameRules: [v => !!v || "Dit veld is verplicht"],
        lastNameRules: [v => !!v || "Dit veld is verplicht"],
        emailRules: [v => !!v || "Dit veld is verplicht"],
        departmentRules: [v => !!v || "Dit veld is verplicht"],
        mobileRules: [v => !!v || "Dit veld is verplicht"]
      },
      copyTooltip: {
        show: false,
        target: null
      }
    };
  },

  computed: {
    asAdmin() {
      return this.$route.name === "admin.organisations.details";
    },
    editLocked() {
      if (this.asAdmin || !this.user.edit_locked) {
        return true;
      }
      return false;
    }
  },

  methods: {
    submit() {
      if (this.$refs.form.validate()) {
        this.$emit("submit", this.user);
      }
    },
    cancel() {
      this.$emit("cancel");
    },
    copyValueToClipboard(value, target) {
      navigator.clipboard.writeText(value || "")
        .then(() => {
          this.copyTooltip.show = true;
          this.copyTooltip.target = target;
          setTimeout(() => {
            this.copyTooltip.show = false;
          }, 2000);
        })
    }
  }
};
</script>

<style scoped></style>
