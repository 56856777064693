<template>
  <page :loading="!contentLoaded">
    <template v-slot:title>Systeembeheer</template>
    <template v-slot:default>
      <v-data-table
        :headers="adminDatatable.headers"
        :items="adminDatatable.users"
        @click:row="selectUser"
        class="row-pointer"
        :search="search"
      >
        <template v-slot:top>
          <v-text-field
            prepend-icon="mdi-magnify"
            label="Zoeken..."
            v-model="search"
          />
        </template>
        <template v-slot:item.permissions="{ item }">
          <v-icon v-if="item.permissions.includes('admin')"
            >mdi-hammer-wrench</v-icon
          >
          <v-icon v-if="item.permissions.includes('sales')">mdi-cash</v-icon>
          <v-icon v-if="item.permissions.includes('accounts')"
            >mdi-account-multiple</v-icon
          >
          <v-icon v-if="item.permissions.includes('billing')"
            >mdi-receipt</v-icon
          >
        </template>
      </v-data-table>
      <v-btn color="primary" text @click="newAdminDialog.show = true"
        >Nieuwe beheerder</v-btn
      >
      <v-dialog v-model="newAdminDialog.show" width="600">
        <v-sheet>
          <v-container>
            <h1 class="headline">Nieuwe beheerder</h1>
            <admin-form v-model="newAdminDialog.form" />
            <v-btn
              color="primary"
              :disabled="!newAdminDialog.form.valid"
              :loading="newAdminDialog.loading"
              @click="inviteNewAdminUser"
              >Uitnodigen</v-btn
            >
          </v-container>
        </v-sheet>
      </v-dialog>
      <v-dialog v-model="editAdminDialog.show" width="600">
        <v-sheet>
          <v-container>
            <h1 class="headline">Beheerder bewerken</h1>
            <admin-form
              v-model="editAdminDialog.form"
              :user="editAdminDialog.selectedUser"
            />
            <v-btn
              color="primary"
              :disabled="!editAdminDialog.form.valid"
              :loading="editAdminDialog.loading"
              @click="editAdminUser"
              >Bewerken</v-btn
            >
          </v-container>
        </v-sheet>
      </v-dialog>
    </template>
  </page>
</template>

<script>
import RepositoryFactory from "@/repositories/RepositoryFactory";
import AdminForm from "@/components/forms/AdminForm";
export default {
  name: "SysAdminDashboard",
  components: {
    AdminForm
  },
  data() {
    return {
      search: "",
      adminDatatable: {
        users: undefined,
        headers: [
          {
            text: "ID",
            value: "id"
          },
          {
            text: "Voornaam",
            value: "first_name"
          },
          {
            text: "Achternaam",
            value: "last_name"
          },
          {
            text: "Email",
            value: "email"
          },
          {
            text: "Permissies",
            value: "permissions",
            width: 150
          }
        ]
      },
      newAdminDialog: {
        show: false,
        loading: false,
        form: {
          input: {},
          valid: false
        }
      },
      editAdminDialog: {
        show: false,
        loading: false,
        form: {
          input: {},
          valid: false
        },
        selectedUser: undefined
      }
    };
  },
  methods: {
    getAllAdminAccounts() {
      RepositoryFactory.get("users")
        .getAdmins()
        .then(res => {
          this.adminDatatable.users = res.data.data;
        });
    },
    inviteNewAdminUser() {
      this.newAdminDialog.loading = true;
      RepositoryFactory.get("invite")
        .inviteAdmin(this.newAdminDialog.form.input)
        .then(() => {
          this.$store.dispatch(
            "notifications/addFeedbackNotification",
            "feedback.admin.admin_invited"
          );
          this.newAdminDialog.show = false;
        })
        .catch(() => {
          this.$store.dispatch(
            "notifications/addFeedbackNotification",
            "error.generic"
          );
        })
        .finally(() => {
          this.newAdminDialog.loading = false;
        });
    },
    editAdminUser() {
      this.editAdminDialog.loading = true;
      RepositoryFactory.get("users")
        .update(
          this.editAdminDialog.form.input,
          this.editAdminDialog.selectedUser.id
        )
        .then(() => {
          this.editAdminDialog.show = false;
        })
        .catch(() => {
          this.$store.dispatch(
            "notifications/addFeedbackNotification",
            "error.generic"
          );
        })
        .finally(() => {
          this.editAdminDialog.loading = false;
        });
    },
    selectUser(user) {
      this.editAdminDialog.selectedUser = user;
      this.editAdminDialog.show = true;
    },
    closeEditAdminDialog() {
      this.editAdminDialog.selectedUser = undefined;
    }
  },
  computed: {
    contentLoaded() {
      return this.adminDatatable.users !== undefined;
    }
  },
  watch: {
    editAdminDialog: {
      handler(value) {
        if (!value.show) {
          this.closeEditAdminDialog();
        }
      },
      deep: true
    }
  },
  mounted() {
    this.getAllAdminAccounts();
  }
};
</script>

<style scoped>
.row-pointer > .v-data-table__wrapper > table > tbody > tr:hover {
  cursor: pointer;
}
</style>
