<template>
  <v-card>
    <v-container>
      <span style="font-weight: bold;">Uw accountbeheerder:</span>
      <v-divider class="mb-3 mt-3"></v-divider>
      <span v-if="adminContact">
        {{ adminContact.name }}
      </span>
      <span v-else>
        Team Digitale Opsporing
      </span><br/>
      <span v-if="adminContact">
        {{ adminContact.email }}
      </span>
      <span v-else>
        support@digitaleopsporing.nl
      </span>
    </v-container>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "AdminTile",
  data() {
    return {};
  },
  computed: {
    ...mapGetters("auth", ["user"]),
    ...mapGetters("organisations", ["getOrganisation"]),
    administrator: function() {
      return this.getOrganisation(this.user.organisation_id)
        ? this.getOrganisation(this.user.organisation_id).members.find(el =>
            el.permissions.includes("customer")
          ) || false
        : undefined;
    },
    adminContact: function () {
      let org = this.getOrganisation(this.user.organisation_id);
      if (!org) {
        return undefined;
      }
      if (org.contacts.some(el => el.type === 'ADMIN')) {
        return org.contacts.find(el => el.type === 'ADMIN')
      } else {
        let contact = org.members.find(el =>
          el.permissions.includes("customer"));
        contact.name = `${contact.first_name} ${contact.last_name}`;
        return contact;
      }
    }
  }
};
</script>

<style scoped></style>
