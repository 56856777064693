<!-- eslint-disable -->
<template>
  <v-dialog v-model="dialog.show" width="700" v-if="user" >
    <v-card max-width="700" class="mx-auto">
    <v-toolbar color="teal" dark>
      <v-icon class="toolbar-title-icon">mdi-card</v-icon>
      <v-toolbar-title>Licentiebeheer</v-toolbar-title>
      <div class="flex-grow-1"></div>
      <v-btn fab small class="btnCloseDialog" @click="dialog.show = false"><v-icon>mdi-close</v-icon></v-btn>
    </v-toolbar>

    <v-list two-line>
      <v-list-item class="pa-0">
        <UserDetailsListItem :user-value="user.first_name" :title="'Voornaam'"/>
        <UserDetailsListItem :user-value="user.last_name" :title="'Achternaam'"/>
      </v-list-item>

      <v-list-item class="pa-0">
        <UserDetailsListItem :user-value="user.email" :title="'Emailadres'"/>
        <UserDetailsListItem :user-value="currentOrganisation.name" :title="'Organisatie'"/>
      </v-list-item>

      <v-list-item class="pa-0">
        <UserDetailsListItem :user-value="user.phone" :title="'Telefoonnummer'"/>
        <UserDetailsListItem :user-value="user.mobile" :title="'Mobiele nummer'"/>
      </v-list-item>

      <v-list-item class="pa-0">
        <UserDetailsListItem :user-value="user.department" :title="'Afdeling'"/>
        <UserDetailsListItem :user-value="user.job_title" :title="'Functie'"/>
      </v-list-item>
    </v-list>

    <v-divider></v-divider>

    <v-list two-line flat>
      <v-list-item class="pa-0">
        <v-list-item>
          <v-list-item-content>
            <template>
              <LicenseActionCard @licenseUpdated="licenseUpdater" :isAdmin="isAdmin" product="irc" :webaccess-license="webaccessLicense" :darkweb-license="darkwebLicense" :irc-license="ircLicense" :otk-license="otkLicense" :license="ircLicense" :licenses="ircLicenses" :user="user" v-on:close-dialog="closeDialog"/>
            </template>
          </v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-list-item-content>
            <LicenseActionCard :isAdmin="isAdmin" product="webaccess" :irc-license="ircLicense" :otk-license="otkLicense" :license="webaccessLicense" :licenses="webaccessLicenses" :user="user" v-on:close-dialog="closeDialog"/>
          </v-list-item-content>
        </v-list-item>
      </v-list-item>

      <v-list-item class="pa-0">
        <v-list-item>
          <v-list-item-content>
            <LicenseActionCard :isAdmin="isAdmin" product="otk" :irc-license="ircLicense" :otk-license="otkLicense" :license="otkLicense" :licenses="otkLicenses" :user="user" v-on:close-dialog="closeDialog"/>
          </v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-list-item-content>
            <LicenseActionCard :isAdmin="isAdmin" product="darkweb" :irc-license="ircLicense" :otk-license="otkLicense" :license="darkwebLicense" :licenses="darkwebLicenses" :user="user" v-on:close-dialog="closeDialog"/>
          </v-list-item-content>
        </v-list-item>
      </v-list-item>
    </v-list>
    <div class="pr-4 pl-4 pb-3" v-if="ircLicense">
      <v-btn color="error" outlined block @click="revokeAllLicenses.show = true">Alle licenties intrekken</v-btn>
    </div>

    <v-divider></v-divider>

      <!-- Remove max width when adding edit user button -->
<!--    <v-list two-line>-->
<!--      <v-list-item>-->
<!--        <v-list-item-content>-->
<!--          <v-btn color="error" @click="deleteUserDialog.show = true" :disabled="!canDelete" v-if="canDelete">Gebruiker verwijderen</v-btn>-->
<!--          <v-tooltip v-else bottom>-->
<!--            <template v-slot:activator="{ on }">-->
<!--              <div v-on="on">-->
<!--                <v-btn disabled>Gebruiker verwijderen</v-btn>-->
<!--              </div>-->
<!--            </template>-->
<!--            Gebruiker kan momenteel niet worden verwijderd.-->
<!--          </v-tooltip>-->
<!--        </v-list-item-content>-->

<!--        <v-list-item-content class="ml-4">-->
<!--          <v-btn color="warning" @click="editUserDialog.show = true">Gebruiker wijzigen</v-btn>-->
<!--        </v-list-item-content>-->
<!--      </v-list-item>-->
<!--    </v-list>-->
  </v-card>
    <v-dialog v-model="deleteUserDialog.show" width="600">
      <v-sheet>
        <v-container>
          <p>U staat op het punt een gebruiker te verwijderen.</p>
          <p>Weet u dit zeker?</p>
          <v-btn @click="deleteUser" :loading="deleteUserDialog.loading" color="error">Verwijderen</v-btn>
        </v-container>
      </v-sheet>
    </v-dialog>
    <v-dialog v-model="revokeAllLicenses.show" width="600">
      <v-sheet>
        <v-container>
          <p>U staat op het punt alle licenties van een gebruiker in the trekken</p>
          <p>Weet u dit zeker?</p>
          <v-btn @click="revokeAllLicensesOfUser" :loading="revokeAllLicenses.loading" color="error">Licenties intrekken</v-btn>
        </v-container>
      </v-sheet>
    </v-dialog>
  </v-dialog>
</template>

<script>
import RepositoryFactory from "@/repositories/RepositoryFactory";
import LicenseActionCard from "@/components/Licenses/LicenseManagement/LicenseActionCard";
import UserDetailsListItem from "@/components/Licenses/LicenseManagement/UserDetailsListItem";

export default {
  name: "UserDetailsAndLicensesDialog",
  components: {
    UserDetailsListItem,
    LicenseActionCard
  },
  props: {
    user: Object,
    currentOrganisation: {
      type: Object,
      default: undefined
    }
  },
  data() {
    return {
      dialog: {
        show: false
      },
      deleteUserDialog: {
        show: false,
        loading: false
      },
      revokeAllLicenses: {
        show: false,
        loading: false
      }
    };
  },
  methods: {
    licenseUpdater() {
      console.log('license updated');
    },

    deleteUser() {
      this.deleteUserDialog.loading = true;
      RepositoryFactory.get("users")
        .delete(this.user.id)
        .then(() => {
          this.dialog.show = false;
          this.deleteUserDialog.loading = false;
          this.$store.dispatch(
            "notifications/addFeedbackNotification",
            "feedback.users.deleted"
          );
        });
    },
    revokeAllLicensesOfUser() {
      this.revokeAllLicenses.loading = true;
      RepositoryFactory.get("users")
        .revokeAllLicenses(this.user.id)
        .then(() => {
          this.revokeAllLicenses.show = false;
          this.$store.dispatch(
            "notifications/addFeedbackNotification",
            "feedback.licenses.revoked"
          );
        })
        .catch(() => {
          this.$store.dispatch(
            "notifications/addFeedbackNotification",
            "error.generic"
          );
        })
        .finally(() => {
          this.revokeAllLicenses.loading = false;
        });
    },
    closeDialog() {
      this.dialog.show = false;
    }
  },
  computed: {
    isAdmin() {
      if (!this.user.permissions) {
        return false;
      }
      return this.user.permissions.includes("admin");
    },
    canDelete() {
      if (!this.user) return false;
      if (this.user.licenses.length > 0) return false;
      return this.$store.getters["auth/user"].id !== this.user.id;
    },
    ircLicense() {
      return this.user.licenses.find(el => el.product === "irc");
    },
    ircLicenses() {
      return this.currentOrganisation.licenses.filter(
        el => el.product === "irc"
      );
    },
    otkLicense() {
      return this.user.licenses.find(el => el.product === "otk");
    },
    otkLicenses() {
      return this.currentOrganisation.licenses.filter(
        el => el.product === "otk"
      );
    },
    darkwebLicense() {
      return this.user.licenses.find(el => el.product === "darkweb");
    },
    darkwebLicenses() {
      return this.currentOrganisation.licenses.filter(
        el => el.product === "darkweb"
      );
    },
    webaccessLicense() {
      return this.user.licenses.find(el => el.product === "webaccess");
    },
    webaccessLicenses() {
      return this.currentOrganisation.licenses.filter(
        el => el.product === "webaccess"
      );
    }
  },
  watch: {
    user: {
      handler(val) {
        if (val !== undefined) {
          this.dialog.show = true;
        }
      },
      deep: true
    },
    dialog: {
      handler(val) {
        if (!val.show) {
          this.$emit("dialogClose");
        }
      },
      deep: true
    }
  }
};
</script>

<style scoped>
.v-application .teal {
  background-color: #28749c !important;
}
.btnCloseDialog {
  background-color: #28749c !important;
}
.toolbar-title-icon {
  margin-right: 1rem;
}
.balance-row {
  max-width: 700px;
  margin-left: 4px;
  margin-right: 4px;
  margin-top: 12px;
  margin-bottom: 12px;
}
</style>
