<template>
  <v-card class="d-flex flex flex-column justify-space-between">
    <div>
      <v-card-title>OTK</v-card-title>
      <v-card-subtitle>
        Geldig tot: {{ otkLicense.expiration_date | formatDate }}<br />
        <template v-if="otkLicense.meta.balance">
          Saldo: &euro;{{ otkLicense.meta.balance }}
        </template>
      </v-card-subtitle>
    </div>
    <v-container class="justify-end align-content-end">
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <div v-on="revokingDisabled.locked ? on : null">
            <v-btn
              color="warning"
              @click="openLicenseRevokeDialog"
              :disabled="revokingDisabled.locked"
              >Licentie intrekken</v-btn
            >
          </div>
        </template>
        {{ revokingDisabled.reason | translate }}
      </v-tooltip>
    </v-container>
    <v-dialog v-model="revokeDialog.show" width="600">
      <v-sheet>
        <v-container>
          <h1 class="headline">Licentie intrekken</h1>
          <p>U staat op het punt een licentie in te trekken</p>
          <p>Weet u dit zeker?</p>
          <v-btn
            @click="revokeLicense"
            class="mr-2"
            :loading="revokeDialog.loading"
            color="primary"
            >Bevestigen</v-btn
          >
          <v-btn color="error" @click="revokeDialog.show = false"
            >Annuleren</v-btn
          >
        </v-container>
      </v-sheet>
    </v-dialog>
  </v-card>
</template>

<script>
import RepositoryFactory from "@/repositories/RepositoryFactory";

export default {
  props: {
    otkLicense: Object,
    user: Object
  },
  name: "OTKLicenseActions",
  data() {
    return {
      input: {
        meta: {
          logging: false
        }
      },
      revokeDialog: {
        show: false,
        loading: false
      },
      loading: {
        logging: false
      }
    };
  },
  methods: {
    updateLicense() {
      this.loading.logging = true;
      RepositoryFactory.get("otkLicenses")
        .update(this.input, this.otkLicense.id)
        .then(() => console.log("test"))
        .catch(() => (this.input.logging = !this.input.logging))
        .finally(() => (this.loading.logging = false));
    },
    openLicenseRevokeDialog() {
      this.revokeDialog.show = true;
    },
    revokeLicense() {
      if (!this.revokingDisabled.locked) {
        let data = this.otkLicense;
        data.user_id = null;
        this.revokeDialog.loading = true;
        RepositoryFactory.get("otkLicenses")
          .update(data, this.otkLicense.id)
          .then(() => {
            this.$store.dispatch("organisations/loadOrganisations");
            this.revokeDialog.show = false;
          })
          .catch(err => {
            this.$store.dispatch(
              "notifications/addFeedbackNotification",
              err.response.data.message || "error.generic"
            );
          })
          .finally(() => {
            this.revokeDialog.loading = false;
          });
      }
    }
  },
  computed: {
    revokingDisabled() {
      let locked = { locked: false };
      if (this.user.licenses.some(el => el.product === "darkweb")) {
        locked.locked = true;
        locked.reason = "license.prereq.darkweb";
      } else if (
        this.licenseTimeLocked &&
        !this.$store.getters["auth/user"].permissions.includes("admin")
      ) {
        locked.locked = true;
        locked.reason = "license.prereq.timelock";
      }
      return locked;
    },
    licenseTimeLocked() {
      let assignmentDate = new Date(this.otkLicense.assignment_date);
      let timeLockExpiration = assignmentDate.setMonth(
        assignmentDate.getMonth() + 3
      );
      let today = new Date();
      return Date.parse(today) < timeLockExpiration;
    },
  },
  mounted() {
    this.input.meta = this.otkLicense.meta || { logging: false };
  }
};
</script>

<style scoped></style>
