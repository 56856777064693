import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from "vuex-persist";
import auth from "@/store/modules/auth";
import notifications from "@/store/modules/Notifications";
import organisations from "@/store/modules/Organisations";
import { setAuthHeader } from "@/repositories/AxiosFactory";

Vue.use(Vuex);

const vuexStorage = new VuexPersistence({
  key: "DigiPortal",
  storage: localStorage,
  modules: ["auth", "organisations"]
});

const store = new Vuex.Store({
  modules: {
    auth: auth,
    notifications: notifications,
    organisations: organisations
  },
  plugins: [vuexStorage.plugin]
});

export default store;

// To ensure the Authorization token is set upon loading VuexPersistence
if (store.getters["auth/token"]) {
  setAuthHeader(store.getters["auth/token"]);
}
