import axios from "axios";
import Vue from "@/main";

//const baseDomain = "https://backend.devportal.digitaleopsporing.nl";
const baseDomain = "https://backend.portal.digitaleopsporing.nl";
// const baseDomain = "http://digiportal.test";
//const baseDomain = "http://127.0.0.1:8000";
const baseURL = `${baseDomain}/api/v1`;

const apiAxios = axios.create({
  baseURL,
  timeout: 40000,
  params: {},
  rejectUnauthorized: true
});

export const setAuthHeader = token => {
  apiAxios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  console.log("Token has been set");
  return Promise.resolve();
};

export const destroyAuthHeader = () => {
  apiAxios.defaults.headers.common["Authorization"] = undefined;
};

apiAxios.interceptors.response.use(
  config => config,
  error => {
    if (error.response && error.response.status === 401) {
      Vue.$store.dispatch("auth/logout").then(() => {
        Vue.$router.push({ name: "auth.login" });
      });
    }
    return Promise.reject(error);
  }
);

export default apiAxios;
