<template>
  <page :loading="false">
    <template v-slot:title>OSINT Toolkit Saldobeheer</template>
    <template v-slot:default>
      <form>
        <v-checkbox v-model="legacy" label="Legacy modus" />
        <div class="col-2-form">
          <v-text-field v-model="input.username" label="Gebruikersnaam" />
          <v-text-field v-model="input.amount" label="Bedrag" />
        </div>
        <v-btn color="primary">Opwaarderen</v-btn>
      </form>
    </template>
  </page>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "OTKBalance",
  data: () => ({
    input: {
      username: "",
      amount: undefined
    },
    legacy: true
  }),
  methods: {},
  computed: {
    ...mapGetters("organisations", ["getAllLicenses"]),
    otkUsernames() {
      return this.getAllLicenses.filter(el => el.product === "otk");
    }
  }
};
</script>

<style scoped>
.col-2-form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 1rem;
}
</style>
