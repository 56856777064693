<template>
  <page :loading="!contentLoaded">
    <template v-slot:title>
      {{ currentOrganisation.id }} {{ currentOrganisation.name }}
      <div class="subtitle-1">Facturering</div>
    </template>
    <template v-slot:tabs>
      <v-tabs v-model="tabs.currentTab">
        <v-tab>Licenties</v-tab>
<!--        <v-tab>Billing historie</v-tab>-->
      </v-tabs>
    </template>
    <template v-slot:default>
      <v-tabs-items :value="tabs.currentTab">
        <v-tab-item>
          <v-form class="d-flex">
            <v-checkbox
              label="Alleen verlopen licenties"
              class="mr-5"
              v-model="table.filters.expiredOnly"
            />
            <v-checkbox
              label="Bijna verlopen licenties"
              v-model="table.filters.expiringOnly"
            />
          </v-form>
          <v-data-table
            :headers="table.headers"
            :items="computedLicenses"
            show-select
            v-model="selectedLicenses"
          >
            <template v-slot:item.expiration_date="{ item }">
              <span
                class="red--text"
                v-if="expirationState(item.expiration_date) === 'expired'"
                >{{ item.expiration_date }}</span
              >
              <span
                class="orange--text"
                v-if="expirationState(item.expiration_date) === 'expiring'"
                >{{ item.expiration_date }}</span
              >
              <span
                class="green--text"
                v-if="expirationState(item.expiration_date) === 'valid'"
                >{{ item.expiration_date }}</span
              >
            </template>
            <template v-slot:item.user_id="{ item }">
              <v-icon color="green" v-if="item.user_id">mdi-check</v-icon>
              <v-icon color="red" v-else>mdi-close</v-icon>
            </template>
          </v-data-table>
          <v-btn
            color="primary"
            @click="openBillCreatedDialog"
            :disabled="!selectedLicenses.length > 0"
            >Selectie verlengen</v-btn
          >
        </v-tab-item>
        <v-tab-item></v-tab-item>
      </v-tabs-items>
      <v-dialog width="600" v-model="createBillingDialog.show">
        <v-sheet>
          <v-container>
            <h1 class="headline">Licenties verlengen</h1>
            <v-divider class="mb-3" />
            <p class="body-1">
              U staat op het punt om {{ selectedLicenses.length }}
              {{ selectedLicenses.length > 1 ? "licenties" : "licentie" }} te
              verlengen.
            </p>
            <p class="body-1">
              Hiervoor wordt een bill gegenereerd die u kunt terugvinden in de
              billing historie.
            </p>
            <v-form>
              <v-menu min-width="auto">
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-on="on"
                    prepend-icon="mdi-calendar"
                    label="Nieuwe verloopdatum"
                    :value="createBillingDialog.date"
                    readonly
                  />
                </template>
                <v-date-picker
                  v-model="createBillingDialog.date"
                  :show-current="createBillingDialog.date"
                />
              </v-menu>
              <v-btn
                :loading="createBillingDialog.loading"
                color="primary"
                @click="createExtensionBill"
                >Verlengen</v-btn
              >
            </v-form>
          </v-container>
        </v-sheet>
      </v-dialog>
    </template>
  </page>
</template>

<script>
import { mapGetters } from "vuex";
import RepositoryFactory from "@/repositories/RepositoryFactory";
export default {
  props: {
    organisation: {
      type: String,
      required: true
    }
  },
  name: "CustomerDetails",
  data() {
    return {
      tabs: {
        currentTab: 0
      },
      table: {
        headers: [
          {
            text: "ID",
            value: "id"
          },
          {
            text: "Product",
            value: "product"
          },
          {
            text: "Verloopdatum",
            value: "expiration_date"
          },
          {
            text: "Gekoppeld",
            value: "user_id"
          }
        ],
        filters: {
          expiredOnly: false,
          expiringOnly: false
        }
      },
      createBillingDialog: {
        show: false,
        date: undefined,
        loading: false
      },
      selectedLicenses: []
    };
  },
  methods: {
    expirationState(date) {
      let unixDate = new Date(date).getTime();
      let oneMonth = new Date();
      oneMonth.setMonth(oneMonth.getMonth() + 1);
      let now = new Date();
      if (unixDate < now.getTime()) return "expired";
      if (unixDate < oneMonth.getTime()) return "expiring";
      else return "valid";
    },
    openBillCreatedDialog() {
      this.createBillingDialog.date = this.oneYearFromNow;
      this.createBillingDialog.show = true;
    },
    createExtensionBill() {
      this.createBillingDialog.loading = true;
      let data = {
        expiration_date: this.createBillingDialog.date,
        licenses: this.selectedLicenses.map(el => el.id),
        organisation_id: this.currentOrganisation.id
      };
      RepositoryFactory.get("billing")
        .store(data)
        .then(() => {
          this.$store.dispatch("organisations/loadOrganisations");
          this.createBillingDialog.show = false;
          this.$store.dispatch(
            "notifications/addFeedbackNotification",
            "feedback.billing.created"
          );
        })
        .catch(() => {
          this.$store.dispatch(
            "notifications/setErrorMessage",
            "error.generic"
          );
        })
        .finally(() => {
          this.createBillingDialog.loading = false;
        });
    }
  },
  computed: {
    ...mapGetters("organisations", ["organisations", "getOrganisation"]),
    computedLicenses() {
      if (!this.currentOrganisation) return [];
      return this.currentOrganisation.licenses.filter(el => {
        let conditions = [];
        this.table.filters.expiredOnly ? conditions.push("expired") : null;
        this.table.filters.expiringOnly ? conditions.push("expiring") : null;

        return conditions.length > 0
          ? conditions.some(
              filter => filter === this.expirationState(el.expiration_date)
            )
          : true;
      });
    },
    currentOrganisation() {
      return this.getOrganisation(this.organisation);
    },
    oneYearFromNow() {
      let date = new Date();
      date.setFullYear(date.getFullYear() + 1);
      return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
    },
    contentLoaded() {
      return this.currentOrganisation !== undefined;
    }
  },
  mounted() {
    if (!this.organisations || this.organisations.length === 0) {
      this.$store.dispatch("organisations/loadOrganisations");
    }
  }
};
</script>

<style scoped></style>
