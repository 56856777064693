import AxiosFactory from "@/repositories/AxiosFactory";

const resource = "users";

export default {
  delete(id) {
    return AxiosFactory.delete(`${resource}/${id}`);
  },
  getAdmins() {
    return AxiosFactory.get(`${resource}?permissions=admin`);
  },
  update(data, id) {
    return AxiosFactory.put(`${resource}/${id}`, data);
  },
  reloadSelf() {
    return AxiosFactory.get(`${resource}/self?include[]=licenses`);
  },
  reset(id) {
    return AxiosFactory.delete(`${resource}/reset/${id}`);
  },
  sendReset2faMail(id) {
    return AxiosFactory.post(`${resource}/${id}/send-reset-2fa-mail`);
  },
  reset2fa(endpoint, data) {
    return AxiosFactory.post(`${endpoint}`, data);
  },
  checkReset2fa(id) {
    return AxiosFactory.post(`${resource}/${id}/check-reset-2fa-request`);
  },
  getBalance(username) {
    return AxiosFactory.post(`${resource}/${username}/getBalance`);
  },
  revokeAllLicenses(id) {
    return AxiosFactory.delete(`${resource}/${id}/revokeLicenses`);
  },
  updateAllLicenses(data, id) {
    return AxiosFactory.put(`${resource}/${id}/updateLicenses`, data);
  },
  changePassword(data, id) {
    return AxiosFactory.put(`${resource}/${id}/change-password`, data);
  }
};
