import AxiosFactory from "@/repositories/AxiosFactory";

const resource = "licenses/irc";

export default {
  store(data) {
    return AxiosFactory.post(resource, data);
  },
  update(data, id) {
    return AxiosFactory.put(`${resource}/${id}`, data);
  },
  changeIRCPassword(data, id) {
    return AxiosFactory.post(`${resource}/${id}/change-password`, data);
  },
  activate2FA(id) {
    return AxiosFactory.post(`${resource}/${id}/activate2fa`);
  },
  delete(id) {
    return AxiosFactory.delete(`${resource}/${id}`);
  }
};
