<template>
  <page :loading="!contentLoaded">
    <template v-slot:title>Demo orders</template>
    <template v-slot:default>
      <v-data-table
        :headers="table.headers"
        :items="trialOrders"
      >
        <template v-slot:item.email="{ item }">
          <span>{{ item.email }}</span>
        </template>
        <template v-slot:item.quantity="{ item }">
          <span>{{ item.trial_accounts.length }}</span>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon medium @click="openRowDialog(item)" title="Order bekijken">
            mdi-book-open
          </v-icon>
          <v-icon medium @click="openDeleteOrderDialog(item)" title="Verwijderen">
            mdi-delete
          </v-icon>
        </template>
        <template v-slot:footer>
          <v-divider />
          <v-layout justify-end>
            <v-btn text color="primary" @click="openFormDialog"
              >Nieuwe demo accounts</v-btn
            >
          </v-layout>
        </template>
      </v-data-table>

      <!-- Dialogs -->
      <v-dialog v-model="formDialog" width="600">
        <v-card>
          <v-toolbar color="teal" dark>
            <v-icon class="toolbar-title-icon">mdi-account</v-icon>
            <v-toolbar-title>Demo accounts aanmaken</v-toolbar-title>
          </v-toolbar>
          <v-sheet class="pa-2">
            <div></div>
            <v-container>
              <validation-observer ref="observer">
                <v-form ref="form" v-model="valid" @submit.prevent="submit">
                  <validation-provider
                    v-slot="{ errors }"
                    name="Aantal demo accounts"
                    rules="required"
                  >
                    <v-text-field
                      v-model="input.quantity"
                      label="Aantal accounts"
                      :error-messages="errors"
                      required
                      type="number"
                      min="1"
                    ></v-text-field>
                  </validation-provider>

                  <validation-provider
                    v-slot="{ errors }"
                    name="Emailadres"
                    rules="required"
                  >
                    <v-text-field
                      v-model="input.email"
                      label="Emailadres ontvanger"
                      required
                      :error-messages="errors"
                    ></v-text-field>
                  </validation-provider>

                  <div class="subheading mt-3">
                    Verloopdatum
                  </div>
                  <v-row class="ml-1 mt-1 mb-5">
                    <v-date-picker
                      v-model="input.datepicker"
                      :min="setMinDatepicker"
                      :max="setMaxDatepicker"
                    ></v-date-picker>
                  </v-row>

                  <v-spacer></v-spacer>

                  <v-btn
                    color="primary"
                    @click="saveTrialAccounts"
                    :disabled="isDisabled"
                    :loading="isLoading"
                    >Opslaan</v-btn
                  >
                </v-form>
              </validation-observer>
            </v-container>
          </v-sheet>
        </v-card>
      </v-dialog>
      <v-dialog v-model="rowDialog" width="600">
        <v-card>
          <v-toolbar color="teal" dark>
            <v-icon class="toolbar-title-icon">mdi-account</v-icon>
            <v-toolbar-title>Demo order</v-toolbar-title>
          </v-toolbar>
          <v-sheet class="pa-2">
            <v-container>
              <v-data-table
                v-if="selectedOrder"
                :headers="table.headersOrderDialog"
                :items="selectedOrder.trial_accounts"
              >
                <template v-slot:item.username="{ item }">
                  <span>{{ item.username }}</span>
                </template>
                <template v-slot:item.irc_password="{ item }">
                  <span>{{ item.irc_password }}</span>
                </template>
                <template v-slot:item.actions="{ item }">
                  <v-icon medium @click="openResendDialog(item)" title="Verstuur uitnodiging">
                    mdi-email-send
                  </v-icon>
                  <v-icon medium @click="openDeleteAccountDialog(item)" title="Verwijderen">
                    mdi-delete
                  </v-icon>
                </template>
              </v-data-table>
            </v-container>
          </v-sheet>
        </v-card>
      </v-dialog>
      <v-dialog v-model="resendDialog" max-width="500px">
        <v-toolbar color="teal" dark>
          <v-icon class="toolbar-title-icon">mdi-email-send</v-icon>
          <v-toolbar-title>Uitnodiging opnieuw versturen</v-toolbar-title>
        </v-toolbar>
        <v-sheet>
          <v-container>
            <p>Wilt u de uitnodiging nogmaals versturen?</p>
            <v-btn color="primary" @click="resendTrialAccount" :loading="isLoading">Versturen</v-btn>
          </v-container>
        </v-sheet>
      </v-dialog>
      <v-dialog v-model="deleteOrderDialog" max-width="500px">
        <v-toolbar color="teal" dark>
          <v-icon class="toolbar-title-icon">mdi-account-remove</v-icon>
          <v-toolbar-title>Demo order verwijderen</v-toolbar-title>
        </v-toolbar>
        <v-sheet>
          <v-container>
            <p>U staat op het punt om een demo order te verwijderen</p>
            <p>Weet u dit zeker?</p>
            <v-btn color="error" @click="deleteTrialOrder">Verwijderen</v-btn>
          </v-container>
        </v-sheet>
      </v-dialog>
      <v-dialog v-model="deleteAccountDialog" max-width="500px">
        <v-toolbar color="teal" dark>
          <v-icon class="toolbar-title-icon">mdi-account-remove</v-icon>
          <v-toolbar-title>Demo account verwijderen</v-toolbar-title>
        </v-toolbar>
        <v-sheet>
          <v-container>
            <p>U staat op het punt om een demo account te verwijderen</p>
            <p>Weet u dit zeker?</p>
            <v-btn color="error" @click="deleteTrialAccount">Verwijderen</v-btn>
          </v-container>
        </v-sheet>
      </v-dialog>
    </template>
  </page>
</template>

<script>
import RepositoryFactory from "@/repositories/RepositoryFactory";
import { required } from "vee-validate/dist/rules";
import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode
} from "vee-validate";

setInteractionMode("eager");
extend("required", {
  ...required,
  message: "{_field_} is een verplicht veld."
});

export default {
  name: "DemoLicenses",

  components: {
    ValidationObserver,
    ValidationProvider
  },

  data() {
    return {
      // Data.
      trialOrders: undefined,
      selectedOrder: undefined,
      selectedAccount: undefined,

      // Forms.
      input: {
        quantity: undefined,
        email: undefined,
        datepicker: this.setToCurrentDate
      },
      valid: false,

      // Dialogs.
      formDialog: false,
      rowDialog: false,
      deleteOrderDialog: false,
      deleteAccountDialog: false,
      resendDialog: false,

      // Loaders.
      isLoading: false,

      // DataTables.
      table: {
        headers: [
          {
            text: "ID",
            value: "id"
          },
          {
            text: "Emailadres",
            value: "email"
          },
          {
            text: "Einddatum",
            value: "end_date"
          },
          {
            text: "Aantal accounts",
            value: "quantity"
          },
          {
            text: "Acties",
            value: "actions",
            sortable: false
          }
        ],
        headersOrderDialog: [
          {
            text: "ID",
            value: "id"
          },
          {
            text: "Gebruikersnaam",
            value: "username"
          },
          {
            text: "Wachtwoord",
            value: "irc_password"
          },
          {
            text: "Acties",
            value: "actions",
            sortable: false
          }
        ]
      }
    };
  },

  created() {
    this.fetchTrialOrders();
  },

  methods: {
    // Dialog handlers.
    openFormDialog() {
      this.formDialog = true;
    },
    openRowDialog(item) {
      this.selectedOrder = item;
      this.rowDialog = true;
    },
    openDeleteAccountDialog(item) {
      this.selectedAccount = item;
      this.deleteAccountDialog = true;
    },
    openDeleteOrderDialog(item) {
      this.selectedOrder = item;
      this.deleteOrderDialog = true;
    },
    openResendDialog(item) {
      this.selectedAccount = item;
      this.resendDialog = true;
    },

    // Repository handlers.
    fetchTrialOrders() {
      RepositoryFactory.get("trialOrders")
        .index()
        .then(response => {
          this.trialOrders = response.data.data;
        })
        .catch(error => {
          console.log(error);
        });
    },
    saveTrialAccounts() {
      this.isLoading = true;

      let data = {
        quantity: this.input.quantity,
        email: this.input.email,
        end_date: this.input.datepicker
      };

      RepositoryFactory.get("trialOrders")
        .store(data)
        .then(response => {
          this.trialOrders.push(response.data.data);
        })
        .catch(error => {
          this.formDialog = false;
          this.$store.dispatch(
            "notifications/addFeedbackNotification",
            "error.generic"
          );
          console.log(error);
        })
        .finally(() => {
          this.isLoading = false;
          this.formDialog = false;

          this.input.quantity = undefined;
          this.input.email = undefined;
          this.input.datepicker = this.setToCurrentDate;
          this.$store.dispatch(
            "notifications/addFeedbackNotification",
            "feedback.trial_orders.created"
          );
        });

      //this.isLoading = false;
    },
    resendTrialAccount() {
      if (!this.selectedAccount || !this.selectedOrder) { return; }
      this.isLoading = true;
      RepositoryFactory.get("trialAccounts")
        .update(this.selectedAccount.id, {})
        .then(() => {
          this.$store.dispatch(
            "notifications/addFeedbackNotification",
            "feedback.trial_accounts.resend"
          );
        })
        .catch(error => {
          this.$store.dispatch(
            "notifications/addFeedbackNotification",
            "error.generic"
          );
          console.log(error);
        })
        .finally(() => {
          this.isLoading = false;
          this.resendDialog = false;
        });
    },
    deleteTrialAccount() {
      if (!this.selectedAccount) { return; }
      RepositoryFactory.get("trialAccounts")
        .delete(this.selectedAccount.id)
        .then(() => {
          this.selectedOrder.trial_accounts = this.selectedOrder.trial_accounts.filter(
            account => account.id !== this.selectedAccount.id
          );
        })
        .catch(error => {
          this.deleteAccountDialog = false;
          this.$store.dispatch(
            "notifications/addFeedbackNotification",
            "error.generic"
          );
          console.log(error);
        })
        .finally(() => {
          this.deleteAccountDialog = false;
          this.$store.dispatch(
            "notifications/addFeedbackNotification",
            "feedback.trial_accounts.deleted"
          );
        });
    },
    deleteTrialOrder() {
      if (!this.selectedOrder) { return; }
      RepositoryFactory.get("trialOrders")
        .delete(this.selectedOrder.id)
        .then(() => {
          this.trialOrders = this.trialOrders.filter(
            order => order.id !== this.selectedOrder.id
          );
        })
        .catch(error => {
          this.deleteOrderDialog = false;
          this.$store.dispatch(
            "notifications/addFeedbackNotification",
            "error.generic"
          );
          console.log(error);
        })
        .finally(() => {
          this.deleteOrderDialog = false;
          this.$store.dispatch(
            "notifications/addFeedbackNotification",
            "feedback.trial_orders.deleted"
          );
        });
    },

    // State handlers.
    updateValue() {
      this.$refs.observer.validate().then(success => {
        if (success) {
          this.valid = true;
        } else {
          this.valid = false;
        }
      });
    }
  },

  computed: {
    setMinDatepicker() {
      return new Date().toISOString().substr(0, 10);
    },
    setMaxDatepicker() {
      return new Date(new Date().setMonth(new Date().getMonth() + 3))
        .toISOString()
        .substr(0, 10);
    },
    setToCurrentDate() {
      return new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10);
    },
    contentLoaded() {
      return this.trialOrders !== undefined;
    },
    isDisabled() {
      return (
        this.input.email === undefined ||
        this.input.quantity === undefined ||
        this.valid === false
      );
    },
  },

  watch: {
    input: {
      handler() {
        this.updateValue();
      },
      deep: true
    }
  }
};
</script>

<style scoped></style>
