import Vue from "vue";
import VueI18n from "vue-i18n";

Vue.use(VueI18n);

function loadLocaleMessages() {
  const locales = {
    nl: require("./locales/nl.json"),
    en: require("./locales/en.json")
  };
  return locales;
}

export default new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || "nl",
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "nl",
  messages: loadLocaleMessages()
});
