import Vue from "vue";
import VueRouter from "vue-router";
// import Home from "../views/Home.vue";
import Login from "@/views/Auth/Login";
import Registration from "@/views/Auth/Registration";
import Organisations from "@/views/Admin/Organisations";
import NewOrganisation from "@/views/Admin/NewOrganisation";
import OrganisationDetails from "@/views/Shared/OrganisationDetails";
import NotFound from "@/views/Error/NotFound";
import LicenseManagement from "@/views/Admin/Licenses/LicenseManagement";
import LoggingOverview from "@/views/User/Products/IRC/LoggingOverview";
import SharedLogsOverview from "@/views/Customer/SharedLogsOverview";
import store from "@/store";
import IRCOverview from "@/views/User/Products/IRC/IRCOverview";
import ResetPassword from "@/views/Auth/ResetPassword";
import CustomerOverview from "@/views/Admin/Billing/CustomerOverview";
import CustomerDetails from "@/views/Admin/Billing/CustomerDetails";
import ClientOverview from "@/views/Admin/Sales/ClientOverview";
import DashboardV2 from "@/views/Shared/Dashboard/DashboardV2";
import IRCDashboard from "@/views/User/Products/IRC/IRCDashboard";
import SysAdminDashboard from "@/views/Admin/SysAdmin/SysAdminDashboard";
import OTKBalance from "@/views/Admin/Billing/OTKBalance";
import Details from "@/views/User/Details";
import Support from "@/views/User/Support";
import DemoLicenses from "@/views/Admin/Licenses/DemoLicenses";
import GlobalOverview from "@/views/Admin/Users/GlobalOverview";
import CustomDetailsV2 from "@/views/Admin/Billing/CustomDetailsV2";
import ResetTwoFactor from "@/views/Auth/ResetTwoFactor";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    redirect: { name: "Dashboard" }
  },
  {
    path: "/",
    name: "Dashboard",
    component: DashboardV2
  },
  // Authentication routes
  {
    path: "/auth/login",
    name: "auth.login",
    component: Login,
    meta: {
      layout: "NoSidebar",
      noAuth: true,
      title: "Inloggen"
    }
  },
  {
    path: "/auth/register/:signedLink",
    name: "auth.register",
    component: Registration,
    props: true,
    meta: {
      layout: "NoSidebar",
      noAuth: true,
      title: "Registreren"
    }
  },
  {
    path: "/auth/reset-password/:signedLink",
    name: "auth.resetPassword",
    component: ResetPassword,
    props: true,
    meta: {
      layout: "NoSidebar",
      noAuth: true,
      title: "Wachtwoord resetten"
    }
  },

  {
    path: "/auth/reset-2fa/:signedUrl",
    name: "auth.reset2fa",
    component: ResetTwoFactor,
    props: true,
    meta: {
      layout: "NoSidebar",
      noAuth: true,
      title: "Tweefactorauthenticatie resetten"
    }
  },

  // Admin routes
  {
    path: "/admin/users",
    name: "admin.users",
    component: GlobalOverview,
    meta: {
      layout: "Default",
      title: "Gebruikers",
      requiresAdmin: true
    }
  },
  {
    path: "/admin/organisations",
    name: "admin.organisations",
    component: Organisations,
    meta: {
      layout: "Default",
      title: "Organisaties",
      requiresAdmin: true
    }
  },
  {
    path: "/admin/organisations/new",
    name: "admin.organisations.new",
    component: NewOrganisation,
    meta: {
      layout: "Default",
      title: "Nieuwe organisatie",
      requiresAdmin: true
    }
  },
  {
    path: "/admin/organisations/:organisation",
    name: "admin.organisations.details",
    component: OrganisationDetails,
    props: true,
    meta: {
      layout: "Default",
      title: "Organisatie details",
      requiresAdmin: true
    }
  },
  {
    path: "/admin/organisations/:organisation/license-management",
    name: "admin.organisations.licenseManagement",
    component: LicenseManagement,
    props: true,
    meta: {
      layout: "Default",
      title: "Licentie management",
      requiresAdmin: true
    }
  },

  // Sysadmin routes
  {
    path: "/admin/sysadmin",
    name: "admin.sysadmin",
    component: SysAdminDashboard,
    meta: {
      title: "Systeem beheer",
      requiresAdmin: true
    }
  },

  // Billing routes
  {
    path: "/admin/billing/customers",
    name: "admin.billing.customers",
    component: CustomerOverview,
    meta: {
      title: "Klanten overzicht",
      requiresAdmin: true
    }
  },
  {
    path: "/admin/billing/customers/:organisation/legacy",
    name: "admin.billing.customers.details.legacy",
    component: CustomerDetails,
    props: true,
    meta: {
      layout: "Default",
      title: "Klant details",
      requiresAdmin: true
    }
  },
  {
    path: "/admin/billing/customers/:organisation",
    name: "admin.billing.customers.details",
    component: CustomDetailsV2,
    props: true,
    meta: {
      layout: "Default",
      title: "Klant details",
      requiresAdmin: true
    }
  },
  {
    path: "/admin/billing/otk/balance/",
    name: "admin.billing.otk.balance",
    component: OTKBalance,
    meta: {
      layout: "Default",
      title: "OTK Saldo",
      requiresAdmin: true
    }
  },

  // Sales routes
  {
    path: "/admin/sales/customers",
    name: "admin.sales.customers",
    component: ClientOverview,
    meta: {
      title: "Klanten overzicht",
      requiresAdmin: true
    }
  },

  // Demo licenses routes.
  {
    path: "/admin/licenses/demo-licenses",
    name: "admin.demo.licenses",
    component: DemoLicenses,
    meta: {
      title: "Demo licenties",
      requiresAdmin: true
    }
  },

  // Organisation routes
  {
    path: "/customer/organisation",
    name: "customer.organisation",
    component: OrganisationDetails,
    meta: {
      layout: "Default",
      title: "Organisatie details",
      requiresCustomer: true
    }
  },
  {
    path: "/customer/organisation/license-management",
    name: "customer.organisation.licenseManagement",
    component: LicenseManagement,
    meta: {
      layout: "Default",
      title: "Licentie management",
      requiresCustomer: true
    }
  },
  {
    path: "/customer/logging/overview",
    name: "customer.logging.overview",
    component: SharedLogsOverview,
    meta: {
      layout: "Default",
      title: "Gedeelde logbestanden",
      requiresCustomer: true
    }
  },
  // {
  //   path: "/customer/organisation/invite-user",
  //   name: "customer.organisation.inviteUser",
  //   component: null,
  //   meta: {
  //     layout: "Default"
  //   }
  // },

  // User Routes
  {
    path: "/products/irc/",
    name: "products.irc",
    component: IRCOverview,
    meta: {
      layout: "Default",
      title: "iRC overzicht"
    }
  },
  {
    path: "/products/irc/logging",
    name: "products.irc.logging",
    component: LoggingOverview,
    meta: {
      layout: "Default",
      title: "Logging overzicht"
    }
  },
  {
    path: "/users/products/irc",
    name: "users.products.irc",
    component: IRCDashboard,
    meta: {
      layout: "Default",
      title: "iRC overzicht"
    }
  },

  // Account details routes
  {
    path: "/users/details",
    name: "users.details",
    component: Details,
    meta: {
      layout: "Default",
      title: "Accountgegevens"
    }
  },

  // Support routes
  {
    path: "/users/support",
    name: "users.support",
    component: Support,
    meta: {
      layout: "Default",
      title: "Support"
    }
  },

  // Error routes
  {
    path: "*",
    name: "error.notFound",
    component: NotFound,
    meta: {
      layout: "NoSidebar",
      title: "Pagina niet gevonden"
    }
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  document.title = to.meta.title
    ? `DigiPortal - ${to.meta.title}`
    : "DigiPortal";
  if (to.meta.noAuth) return next();

  if (to.meta.requiresAdmin && !store.getters["auth/isAdmin"]) {
    return next({ name: "error.notFound" });
  }

  if (to.meta.requiresCustomer && !store.getters["auth/isCustomer"]) {
    return next({ name: "error.notFound" });
  }

  if (!store.getters["auth/token"]) return next({ name: "auth.login" });
  store.dispatch("auth/reloadUser");
  next();
});

// router.onError(err => {
//   if (err.status === 401) {
//     Vue.$store.dispatch("auth/logout").then(() => {
//       router.push({ name: "auth.login" });
//     });
//   }
// });

export default router;
