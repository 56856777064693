<template>
  <v-dialog v-model="popup.show" width="600" persistent>
    <v-sheet>
      <v-tabs-items v-model="tabs.currentTab">
        <v-tab-item>
          <v-container>
            <information-block>
              <template v-slot:title>Tweefactor authenticatie</template>
              <template v-slot:content>
                <p>
                  Om uw registratie af te ronden, moet u tweefactor
                  authenticatie instellen. Hiervoor heeft u een Authenticator
                  app nodig op uw telefoon.
                </p>
                <p>
                  Als u deze nog niet heeft geïnstalleerd, kunt u de uitleg in
                  de volgende stap volgen om deze te installeren.
                </p>
                <p>
                  Heeft u wel al een Authenticator app geïnstalleerd, dan kunt u
                  de eerste stap overslaan.
                </p>
              </template>
            </information-block>
            <v-btn color="primary" @click="tabs.currentTab++"
              >Volgende stap</v-btn
            >
          </v-container>
        </v-tab-item>
        <v-tab-item>
          <v-container>
            <h3 class="headline">Download de Google Authenticator App</h3>
            <ol class="mt-2 mb-5">
              <li>
                Open de App Store als u een Apple apparaat heeft, of de Google
                PlayStore als u een Android apparaat heeft.
              </li>
              <li>Zoek op Authenticator</li>
              <li>
                Installeer de Authenticator die als uitgever Google heeft.
                Hoogstwaarschijnlijk is dit het eerste zoekresultaat.
              </li>
            </ol>
            <v-btn color="primary" @click="tabs.currentTab++"
              >Volgende stap</v-btn
            >
          </v-container>
        </v-tab-item>
        <v-tab-item>
          <v-container>
            <h3 class="headline">
              Scan de onderstaande code met uw Authenticator app
            </h3>
            <v-row>
              <v-col cols="6">
                <v-img :src="qrCode" />
              </v-col>
            </v-row>
            <v-form v-model="formRules.valid">
              <label for="2fa-code">Authenticator code</label>
              <v-otp-input
                id="2fa-code"
                length="6"
                type="number"
                :rules="formRules.authRules"
                v-model="authVerification.twoFACode"
              />
              <v-btn
                :loading="authVerification.loading"
                color="primary"
                @click="confirmGoogleAuth"
                :disabled="!formRules.valid"
                >Afronden</v-btn
              >
            </v-form>
          </v-container>
        </v-tab-item>
        <v-tab-item>
          <v-container>
            <h3 class="headline mb-2">Gelukt!</h3>
            <p class="body-1">
              U heeft uw tweefactorauthenticatie succesvol geactiveerd. U wordt
              over enkele seconden doorgestuurd naar het loginscherm.
            </p>
            <p class="body-1">
              Let op, als u voor de eerste keer inlogt, krijgt u eerst nog een
              E-Mail om uw IP adres te verifiëren.
            </p>
          </v-container>
        </v-tab-item>
      </v-tabs-items>
    </v-sheet>
  </v-dialog>
</template>

<script>
import RepositoryFactory from "@/repositories/RepositoryFactory";

export default {
  name: "GoogleAuthPopup",
  props: {
    qrCode: String,
    username: String
  },
  data() {
    return {
      popup: {
        show: false
      },
      tabs: {
        currentTab: 0
      },
      formRules: {
        authRules: [r => !!r, r => r.length === 6],
        valid: false
      },
      authVerification: {
        twoFACode: "",
        loading: false
      }
    };
  },
  methods: {
    confirmGoogleAuth() {
      this.authVerification.loading = true;
      RepositoryFactory.get("auth")
        .confirmGoogleAuth({
          email: this.username,
          twoFACode: this.authVerification.twoFACode
        })
        .then(res => {
          if (res.status === 200) {
            console.log("Getting through to status 200 condition");
            setTimeout(() => {
              return this.$router.push({ name: "auth.login" });
            }, 8000);
            this.tabs.currentTab++;
          }
        })
        .catch(err => {
          let message = "";
          console.log(err.response.status);
          if (err.response.status === 422) {
            message = err.response.data.message;
          } else {
            message = "error.generic";
          }
          this.$store.dispatch(
            "notifications/addFeedbackNotification",
            message
          );
        })
        .finally(() => {
          this.authVerification.loading = false;
        });
    }
  },
  watch: {
    qrCode: {
      handler(val) {
        if (val) {
          this.popup.show = true;
        }
      }
    }
  }
};
</script>

<style scoped></style>
