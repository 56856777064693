<template>
  <page :loading="!contentLoaded">
    <template v-slot:title>Logging</template>
    <template>
      <v-data-table
        :items="loggingItems"
        :headers="table.headers"
        :loading="table.loading"
      >
        <template v-slot:item.url="{ item }">
          <span class="overflow-text">{{ item.url.substr(0, 80) }}</span>
        </template>
        <template v-slot:item.created_at="{ item }">
          {{ item.created_at | formatDateTime }}
        </template>
      </v-data-table>
      <v-btn
        color="primary"
        :disabled="loggingItems.length < 1"
        @click="shareLogDialog.show = true"
        >Deel logboek</v-btn>
      <v-btn
        color="primary"
        :disabled="loggingItems.length < 1"
        @click="downloadLogDialog.show = true"
        class="ml-2"
      >Download logboek</v-btn>
    </template>
    <v-dialog v-model="passwordDialog.show" :width="600" persistent>
      <v-sheet>
        <v-container>
          <h1 class="headline">Wachtwoord invoeren</h1>
          <v-divider />
          <v-text-field
            :type="passwordDialog.showPassword ? 'text' : 'password'"
            label="Wachtwoord"
            v-model="passwordDialog.password"
            :append-icon="passwordDialog.showPassword ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"
            @click:append="passwordDialog.showPassword = !passwordDialog.showPassword"
          />
          <v-row>
            <v-col>
              <v-btn
                @click="fetchLogEntries"
                color="primary"
                block
                :loading="passwordDialog.authLoading"
              >Bevestigen</v-btn>
            </v-col>
            <v-col>
              <v-btn color="error" @click="cancelLogRetrieval" block
                >Annuleren</v-btn
              >
            </v-col>
          </v-row>
        </v-container>
      </v-sheet>
    </v-dialog>

    <v-dialog v-model="shareLogDialog.show" :key="increment" width="600">
      <v-toolbar color="teal" dark>
        <v-icon class="toolbar-title-icon">mdi-email-send</v-icon>
        <v-toolbar-title>Deel logboek</v-toolbar-title>
      </v-toolbar>
      <v-sheet>
        <v-container>
          <v-form v-model="shareLogDialog.form.valid">
            <v-menu max-width="290" :close-on-content-click="false">
              <v-date-picker
                :max="
                  shareLogDialog.untilDate
                    ? shareLogDialog.untilDate
                    : new Date().toISOString().slice(0, 10)
                "
                v-model="shareLogDialog.fromDate"
              />
              <template v-slot:activator="{ on }">
                <v-text-field
                  label="Log items sinds"
                  v-model="shareLogDialog.fromDate"
                  readonly
                  v-on="on"
                  :rules="rules.dateFrom"
                  prepend-icon="mdi-calendar-today"
                />
              </template>
            </v-menu>
            <v-menu max-width="290" :close-on-content-click="false">
              <v-date-picker
                :max="new Date().toISOString().slice(0, 10)"
                :min="
                  shareLogDialog.fromDate ? shareLogDialog.fromDate : undefined
                "
                v-model="shareLogDialog.untilDate"
              />
              <template v-slot:activator="{ on }">
                <v-text-field
                  label="Log items tot"
                  v-model="shareLogDialog.untilDate"
                  readonly
                  v-on="on"
                  :rules="rules.dateUntil"
                  prepend-icon="mdi-calendar"
                />
              </template>
            </v-menu>
            <v-select
              label="Versturen naar"
              v-if="currentOrganisation"
              :items="adminMembers"
              return-object
              v-model="shareLogDialog.selectedUser"
              item-text="first_name"
              :rules="rules.sendTo"
            >
              <template v-slot:item="{ item }">
                {{ item.first_name }} {{ item.last_name }}
              </template>
              <template v-slot:selection="{ item }">
                {{ item.first_name }} {{ item.last_name }}
              </template>
            </v-select>
            <v-text-field
              label="Wachtwoord"
              type="password"
              v-model="shareLogDialog.password"
            />
            <v-btn
              color="primary"
              :disabled="!shareLogDialog.form.valid"
              @click="shareLogs"
              >Versturen</v-btn
            >
          </v-form>
        </v-container>
      </v-sheet>
    </v-dialog>
    <v-dialog v-model="downloadLogDialog.show" width="600">
      <v-card max-width="700" class="mx-auto">
        <v-toolbar color="teal" dark>
          <v-icon class="toolbar-title-icon">mdi-download</v-icon>
          <v-toolbar-title>Download logboek</v-toolbar-title>
        </v-toolbar>
        <v-sheet>
          <v-container>
            <v-form v-model="downloadLogDialog.form.valid">
              <v-menu max-width="290" :close-on-content-click="false">
                <v-date-picker
                  :max="
                    downloadLogDialog.untilDate
                      ? downloadLogDialog.untilDate
                      : new Date().toISOString().slice(0, 10)
                "
                  v-model="downloadLogDialog.fromDate"
                />
                <template v-slot:activator="{ on }">
                  <v-text-field
                    label="Log items sinds"
                    v-model="downloadLogDialog.fromDate"
                    readonly
                    v-on="on"
                    :rules="rules.dateFrom"
                    prepend-icon="mdi-calendar-today"
                  />
                </template>
              </v-menu>
              <v-menu max-width="290" :close-on-content-click="false">
                <v-date-picker
                  :max="new Date().toISOString().slice(0, 10)"
                  :min="
                    downloadLogDialog.fromDate
                      ? downloadLogDialog.fromDate
                      : undefined
                  "
                  v-model="downloadLogDialog.untilDate"
                />
                <template v-slot:activator="{ on }">
                  <v-text-field
                    label="Log items tot"
                    v-model="downloadLogDialog.untilDate"
                    readonly
                    v-on="on"
                    :rules="rules.dateUntil"
                    prepend-icon="mdi-calendar"
                  />
                </template>
              </v-menu>
              <v-btn
                color="primary"
                :disabled="!downloadLogDialog.form.valid"
                @click="downloadLogs"
              >Downloaden</v-btn
              >
            </v-form>
          </v-container>
        </v-sheet>
      </v-card>
    </v-dialog>
  </page>
</template>

<script>
import RepositoryFactory from "@/repositories/RepositoryFactory";
import { mapGetters } from "vuex";

export default {
  name: "LoggingOverview",
  data() {
    return {
      increment: 0,
      loggingItems: [],
      table: {
        loading: true,
        headers: [
          {
            text: "ID",
            value: "id",
            width: 80
          },
          {
            text: "URL",
            value: "url",
            width: 200
          },
          {
            text: "Tijd",
            value: "created_at",
            width: 250
          }
        ]
      },
      passwordDialog: {
        password: "",
        show: false,
        showPassword: false,
        authLoading: false
      },
      shareLogDialog: {
        show: false,
        fromDate: undefined,
        untilDate: undefined,
        selectedUser: undefined,
        password: undefined,
        form: {
          valid: false
        }
      },
      downloadLogDialog: {
        show: false,
        fromDate: undefined,
        untilDate: undefined,
        form: {
          valid: false
        }
      }
    };
  },
  methods: {
    fetchLogEntries() {
      this.table.loading = true;
      this.passwordDialog.authLoading = true;
      RepositoryFactory.get("ircLogging")
        .index({ password: this.passwordDialog.password })
        .then(res => {
          if (res.status === 200) {
            this.loggingItems = res.data.data;
            this.passwordDialog.show = false;
          }
        })
        .finally(() => {
          this.table.loading = false;
          this.passwordDialog.authLoading = false;
        });
    },
    shareLogs() {
      this.shareLogDialog.show = false;
      this.shareLogDialog.form.valid = false;

      let data = {
        dateFrom: this.shareLogDialog.fromDate,
        dateUntil: this.shareLogDialog.untilDate,
        password: this.shareLogDialog.password,
        owner_id: this.shareLogDialog.selectedUser.id
      };
      RepositoryFactory.get("sharedLogs")
        .store(data)
        .then(() => {
          this.shareLogDialog.fromDate = undefined;
          this.shareLogDialog.untilDate = undefined;
          this.shareLogDialog.selectedUser = undefined;
          this.shareLogDialog.password = undefined;

          this.$store.dispatch(
            "notifications/addFeedbackNotification",
            "feedback.shared_logs.shared"
          );
        });
    },
    downloadLogs() {
      let csvContent = "data:text/csv;charset=utf-8,";
      csvContent +=
        "URL;Tijd\r\n" +
        this.logsWithinRange
          .map(item => {
            return `${encodeURIComponent(item.url)};${item.created_at}`;
          })
          .join("\r\n");
      let encodedUri = encodeURI(csvContent);
      let link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", "logboek.csv");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    cancelLogRetrieval() {
      this.table.loading = false;
      this.passwordDialog.show = false;
    }
  },
  computed: {
    ...mapGetters("organisations", ["getOrganisation"]),
    ...mapGetters("auth", ["user"]),
    currentOrganisation() {
      let orgId = this.user.organisation_id;
      let org = this.getOrganisation(orgId);
      if (!org) {
        this.$store.dispatch("organisations/loadOrganisations");
      }
      return org;
    },
    rules() {
      return {
        dateFrom: [
          v => !!v || "Dit veld is verplicht",
          v =>
            this.shareLogDialog.untilDate
              ? v.valueOf() < this.shareLogDialog.untilDate.valueOf()
              : true || "Datum sinds mag niet groter zijn dan datum tot"
        ],
        dateUntil: [
          v => !!v || "Dit veld is verplicht",
          v =>
            this.shareLogDialog.fromDate
              ? v.valueOf() > this.shareLogDialog.fromDate.valueOf()
              : true || "Datum tot mag niet kleiner zijn dan datum sinds"
        ],
        sendTo: [
          v => (v && v.id) || "Dit veld is verplicht",
          v =>
            (v && v.permissions.includes("customer")) ||
            "U kunt uw logs alleen delen met een admin"
        ],
        password: [v => !!v || "Dit veld is verplicht"]
      };
    },
    logsWithinRange() {
      if (!this.downloadLogDialog.form.valid) {
        return [];
      }
      return this.loggingItems.filter(
        item =>
          new Date(item.created_at).valueOf() >=
            new Date(this.downloadLogDialog.fromDate).valueOf() &&
          new Date(item.created_at).valueOf() <=
            new Date(this.downloadLogDialog.untilDate).valueOf()
      );
    },

    adminMembers() {
      return this.currentOrganisation.members.filter(
        el => el.permissions && el.permissions.includes("customer")
      );
    },
    contentLoaded() {
      return this.currentOrganisation !== undefined && this.user !== undefined;
    }
  },
  mounted() {
    this.passwordDialog.show = true;
  }
};
</script>

<style scoped>
.overflow-text {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 200px;
}
</style>
