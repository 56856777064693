<template>
  <div v-if="currentOrganisation">
    <v-row>
      <v-col>
        <license-handler
          product="irc"
          :licenses="ircLicenses"
          :users="currentOrganisation.members"
          @licenseUpdated="updateLocalLicense"
          :is-admin="isAdmin"
        />
      </v-col>

      <v-col>
        <license-handler
          product="webaccess"
          :licenses="webAccessLicenses"
          :users="currentOrganisation.members"
          @licenseUpdated="updateLocalLicense"
          :prerequisites-met="webAccessLicensePrerequisitesMet"
          :is-admin="isAdmin"
        />
      </v-col>
      <v-col>
        <license-handler
          product="otk"
          :licenses="otkLicenses"
          :users="currentOrganisation.members"
          @licenseUpdated="updateLocalLicense"
          :prerequisites-met="otkLicensePrerequisitesMet"
          :is-admin="isAdmin"
        />
      </v-col>
      <v-col>
        <license-handler
          product="darkweb"
          :licenses="darkwebLicenses"
          :users="currentOrganisation.members"
          @licenseUpdated="updateLocalLicense"
          :prerequisites-met="darkwebLicensePrerequisistesMet"
          :is-admin="isAdmin"
      />
      </v-col>
    </v-row>
    <license-edit-dialog
        v-model="licenseEditDialog.show"
        :license="licenseEditDialog.license"
        :users="usersWithProducts"
    />
    <user-details-and-licenses-dialog
        :user="this.userDetailsAndLicensesDialog.user"
        @dialogClose="userDetailsAndLicensesDialog.user = undefined"
        :current-organisation="this.currentOrganisation"
    />
    <user-details-dialog
        :user="this.userDetailsDialog.user"
        @dialogClose="userDetailsDialog.user = undefined"
    />

    <v-data-table
      :headers="users.headers"
      :items="usersWithProducts"
      @click:row="openUserDetailsAndLicensesDialog"
      class="mt-5 row-pointer"
      :search="search"
      :custom-filter="customSearch"
    >
      <template v-slot:top>
        <v-text-field
          prepend-icon="mdi-magnify"
          label="Zoeken..."
          v-model="search"
        />
      </template>

      <template v-slot:item.full_name="{ item }">
        <span>
          {{ item.full_name }}
        </span>
      </template>
      <template v-slot:item.first_name="{ item }">
        <span>
          <v-icon
              color="primary"
              v-if="item.permissions && item.permissions.includes('customer')"
          >mdi-crown</v-icon
          >
          {{ item.first_name }}
        </span>
      </template>
      <template v-slot:item.irc="{ item }">
        <v-icon v-if="item.irc" color="success">mdi-check</v-icon>
        <v-icon v-else color="error">mdi-close</v-icon>
      </template>
      <template v-slot:item.webaccess="{ item }">
        <v-icon v-if="item.webAccess" color="success">mdi-check</v-icon>
        <v-icon v-else color="error">mdi-close</v-icon>
      </template>
      <template v-slot:item.otk="{ item }">
        <v-icon v-if="item.otk" color="success">mdi-check</v-icon>
        <v-icon v-else color="error">mdi-close</v-icon>
      </template>
      <template v-slot:item.darkweb="{ item }">
        <v-icon v-if="item.darkweb" color="success">mdi-check</v-icon>
        <v-icon v-else color="error">mdi-close</v-icon>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import LicenseEditDialog from "@/components/Licenses/LicenseActions/LicenseEditDialog";
import LicenseHandler from "@/components/Licenses/LicenseHandler";
import UserDetailsDialog from "@/components/users/UserDetailsDialog";
import UserDetailsAndLicensesDialog from "@/components/Licenses/LicenseManagement/UserDetailsAndLicensesDialog";
export default {
  name: "IRCLicenses",
  components: {
    UserDetailsAndLicensesDialog,
    LicenseEditDialog,
    LicenseHandler,
    UserDetailsDialog
  },
  props: {
    isAdmin: {
      type: Boolean,
      default: false
    },
    currentOrganisation: {
      type: Object,
      default: undefined
    }
  },
  data() {
    return {
      search: "",
      users: {
        headers: [
          { text: "Voornaam", value: "first_name" },
          { text: "Achternaam", value: "last_name" },
          { text: "iRC", value: "irc" },
          { text: "WebAccess", value: "webaccess" },
          { text: "OTK", value: "otk" },
          { text: "Darkweb", value: "darkweb" }
        ]
      },
      dialog: {
        selectedDialog: "",
        show: false
      },
      licenseEditDialog: {
        show: false,
        license: undefined
      },
      userDetailsDialog: {
        user: undefined
      },
      userDetailsAndLicensesDialog: {
        user: undefined
      },
      licenses: {
        irc: {
          formValid: false,
          data: {
            selectedUser: undefined
          }
        },
        otk: {
          formValid: false,
          data: {
            selectedUser: undefined
          }
        },
        darkweb: {
          formValid: false,
          data: {
            selectedUser: undefined
          }
        },
        webAccess: {
          formValid: false,
          data: {
            selectedUser: undefined
          }
        },
        headers: [
          { text: "ID", value: "id" },
          { text: "Gebruiker", value: "user_id" },
          { text: "Geldig tot", value: "expires_at" }
        ]
      }
    };
  },
  methods: {
    customSearch(value, search, item) {
      return Object.values(item).some(v =>
        v && v.toString()
          .toLowerCase()
          .includes(search.toString().toLowerCase()))
    },
    updateLocalLicense() {
      this.$store.dispatch("organisations/loadOrganisation", this.currentOrganisation.id);
    },
    openUserDetailsAndLicensesDialog(user) {
      this.userDetailsAndLicensesDialog.user = user;
    },
    usersAndAdmins() {
      let admins = [];
      let users = [];
      this.currentOrganisation.members.forEach(user => {
        if (user.permissions && user.permissions.includes("customer")) {
          user.full_name = user.first_name + " " + user.last_name;
          admins.push(user);
        } else {
          user.full_name = user.first_name + " " + user.last_name;
          users.push(user);
        }
      });
      return [...admins, ...users];
    }
  },
  computed: {
    ...mapGetters("organisations", ["getOrganisation"]),

    usersWithProducts() {
      return this.usersAndAdmins().map(el => {
        el.irc = el.licenses.some(license => license.product === "irc");
        el.webAccess = el.licenses.some(
            license => license.product === "webaccess"
        );
        el.otk = el.licenses.some(license => license.product === "otk");
        el.darkweb = el.licenses.some(license => license.product === "darkweb");
        return el;
      });
    },
    ircLicenses() {
      return this.currentOrganisation.licenses.filter(
          el => el.product === "irc"
      );
    },
    webAccessLicenses() {
      return this.currentOrganisation.licenses.filter(
          el => el.product === "webaccess"
      );
    },
    otkLicenses() {
      return this.currentOrganisation.licenses.filter(
          el => el.product === "otk"
      );
    },
    darkwebLicenses() {
      return this.currentOrganisation.licenses.filter(
          el => el.product === "darkweb"
      );
    },
    otkLicensePrerequisitesMet() {
      return this.otkLicenses < this.ircLicenses;
    },
    webAccessLicensePrerequisitesMet() {
      return this.webAccessLicenses < this.ircLicenses;
    },
    darkwebLicensePrerequisistesMet() {
      return this.darkwebLicenses < this.otkLicenses;
    }
  }
};
</script>

<style>
.row-pointer > .v-data-table__wrapper > table > tbody > tr:hover {
  cursor: pointer;
}
</style>
