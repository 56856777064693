<template>
  <div>Page not found</div>
</template>

<script>
export default {
  name: "NotFound"
};
</script>

<style scoped></style>
