<template>
  <v-form class="col-2-grid" v-model="form.valid">
    <v-text-field
      prefix="*"
      label="Voornaam"
      v-model="form.input.first_name"
      :rules="form.rules.first_name"
    />
    <v-text-field
      prefix="*"
      label="Achternaam"
      v-model="form.input.last_name"
      :rules="form.rules.last_name"
    />
    <v-text-field
      prefix="*"
      label="E-mailadres"
      v-model="form.input.email"
      :rules="form.rules.email"
    />
    <v-select
      label="Permissies"
      v-model="form.input.permissions"
      :rules="form.rules.permissions"
      :items="permissions"
      item-value="value"
      item-text="text"
      multiple
    />
  </v-form>
</template>

<script>
export default {
  props: {
    value: {
      type: Object
    },
    user: {
      type: Object,
      default: undefined
    }
  },
  name: "AdminForm",
  data() {
    return {
      form: {
        valid: false,
        input: {
          first_name: "",
          last_name: "",
          email: "",
          permissions: []
        },
        rules: {
          first_name: [v => !!v || "Dit veld is verplicht"],
          last_name: [v => !!v || "Dit veld is verplicht"],
          email: [
            v => !!v || "Dit veld is verplicht",
            v => v.includes("@") || "Voer een geldig E-mailadres in"
          ],
          permissions: [v => v.length > 0 || "Kies ten minste 1 permissie"]
        }
      },
      permissions: [
        {
          text: "Systeembeheerder",
          value: "sysadmin"
        },
        {
          text: "Verkoop",
          value: "sales"
        },
        {
          text: "Facturering",
          value: "billing"
        }
      ]
    };
  },
  watch: {
    form: {
      handler(newVal) {
        this.$emit("input", newVal);
      },
      deep: true
    },
    user: {
      handler(newVal) {
        if (newVal !== undefined) {
          this.form.input = {
            first_name: newVal.first_name,
            last_name: newVal.last_name,
            email: newVal.email,
            permissions: newVal.permissions
          };
        } else {
          this.form.input = {
            first_name: "",
            last_name: "",
            email: "",
            permissions: []
          };
        }
      },
      immediate: true
    }
  }
};
</script>

<style scoped></style>
