<template>
  <v-card class="d-flex flex flex-column justify-space-between">
    <div>
      <v-card-title>{{ this.license.product }} (id {{ this.license.id }})</v-card-title>
      <v-simple-table dense>
        <tbody>
        <tr>
          <td>Geldig tot:</td>
          <td>{{ this.license.expiration_date | formatDate }}</td>
        </tr>
        <tr>
          <td>Aangemaakt op:</td>
          <td>{{ this.license.created_at | formatDate }}</td>
        </tr>
        <tr>
          <td>Vergrendeld tot:</td>
          <td>{{ (this.license.locked_until | formatDate) || "N.v.t." }}</td>
        </tr>
        <tr>
          <td>Toegewezen op:</td>
          <td v-if="this.license.asssignment_date">{{ this.license.assignment_date | formatDate }}</td>
          <td v-else>Nog niet toegewezen</td>
        </tr>
        <tr v-if="this.license.meta.logging !== undefined">
          <td>Logging:</td>
          <td>{{ this.license.meta.logging === true ? 'Ja' : 'Nee' }}</td>
        </tr>
        <tr v-if="this.license.meta.balance !== undefined">
          <td>Saldo</td>
          <td>&euro; {{ this.license.meta.balance }}</td>
        </tr>
        </tbody>
      </v-simple-table>
    </div>
    <v-container class="justify-end align-content-end">
    </v-container>
  </v-card>
</template>

<script>
export default {
  name: "LicenseMeta",
  props: {
    license: Object
  }
};
</script>

<style scoped>

</style>