var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('page',{attrs:{"loading":!_vm.contentLoaded},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" "+_vm._s(_vm.currentOrganisation.id)+" "+_vm._s(_vm.currentOrganisation.name)+" "),_c('div',{staticClass:"subtitle-1"},[_vm._v("Facturering")])]},proxy:true},{key:"default",fn:function(){return [_c('v-data-table',{attrs:{"headers":_vm.table.headers.users,"items":_vm.members,"show-select":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Gebruikers")]),_c('v-spacer'),_c('v-btn',{staticClass:"mb-2",attrs:{"color":"primary","disabled":!_vm.selectedUsers.length > 0},on:{"click":_vm.openExtendLicensesFromUsersDialog}},[_vm._v(" Licenties verlengen ")])],1)]},proxy:true},{key:"item.expiration_date",fn:function(ref){
var item = ref.item;
return _vm._l((item.licenses),function(license){return _c('div',{key:license.id},[_c('b',[_vm._v(_vm._s(license.product))]),_vm._v(": "+_vm._s(license.expiration_date)+" ")])})}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.first_name + " " + item.last_name)+" ")]}},{key:"item.username",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.licenses && item.licenses[0] && item.licenses[0].meta ? "irc/" + item.licenses[0].meta.username : "")+" ")]}},{key:"item.user_id",fn:function(ref){
var item = ref.item;
return [(item.user_id)?_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-check")]):_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-close")])]}}]),model:{value:(_vm.selectedUsers),callback:function ($$v) {_vm.selectedUsers=$$v},expression:"selectedUsers"}}),_c('v-data-table',{attrs:{"headers":_vm.table.headers.licenses,"items":_vm.computedLicenses,"show-select":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Niet toegewezen licenties")]),_c('v-spacer'),_c('v-btn',{staticClass:"mb-2",attrs:{"color":"primary","disabled":!_vm.selectedLicenses.length > 0},on:{"click":_vm.openBillCreatedDialog}},[_vm._v(" Licenties verlengen ")])],1)]},proxy:true},{key:"item.expiration_date",fn:function(ref){
var item = ref.item;
return [(_vm.expirationState(item.expiration_date) === 'expired')?_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(item.expiration_date))]):_vm._e(),(_vm.expirationState(item.expiration_date) === 'expiring')?_c('span',{staticClass:"orange--text"},[_vm._v(_vm._s(item.expiration_date))]):_vm._e(),(_vm.expirationState(item.expiration_date) === 'valid')?_c('span',{staticClass:"green--text"},[_vm._v(_vm._s(item.expiration_date))]):_vm._e()]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.product)+" ")]}}]),model:{value:(_vm.selectedLicenses),callback:function ($$v) {_vm.selectedLicenses=$$v},expression:"selectedLicenses"}}),_c('v-dialog',{attrs:{"width":"600"},model:{value:(_vm.extendLicensesFromUsersDialog.show),callback:function ($$v) {_vm.$set(_vm.extendLicensesFromUsersDialog, "show", $$v)},expression:"extendLicensesFromUsersDialog.show"}},[_c('v-sheet',[_c('v-container',[_c('h1',{staticClass:"headline"},[_vm._v("Licenties verlengen")]),_c('v-divider',{staticClass:"mb-3"}),_c('p',{staticClass:"body-1"},[_vm._v(" U staat op het punt om alle gekoppelde licenties van de onderstaande "+_vm._s(_vm.selectedUsers.length > 1 ? "gebruikers" : "gebruiker")+" te verlengen: ")]),_c('ol',_vm._l((_vm.selectedUsers),function(user){return _c('li',{key:user.id},[_vm._v(" "+_vm._s(user.first_name + " " + user.last_name)+" ")])}),0),_c('v-form',{staticClass:"mt-4"},[_c('v-menu',{attrs:{"min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"prepend-icon":"mdi-calendar","label":"Nieuwe verloopdatum","value":_vm.extendLicensesFromUsersDialog.date,"readonly":""}},on))]}}])},[_c('v-date-picker',{attrs:{"show-current":_vm.extendLicensesFromUsersDialog.date},model:{value:(_vm.extendLicensesFromUsersDialog.date),callback:function ($$v) {_vm.$set(_vm.extendLicensesFromUsersDialog, "date", $$v)},expression:"extendLicensesFromUsersDialog.date"}})],1),_c('v-btn',{attrs:{"loading":_vm.extendLicensesFromUsersDialog.loading,"color":"primary"},on:{"click":_vm.extendLicensesFromUsers}},[_vm._v("Verlengen")])],1)],1)],1)],1),_c('v-dialog',{attrs:{"width":"600"},model:{value:(_vm.createBillingDialog.show),callback:function ($$v) {_vm.$set(_vm.createBillingDialog, "show", $$v)},expression:"createBillingDialog.show"}},[_c('v-sheet',[_c('v-container',[_c('h1',{staticClass:"headline"},[_vm._v("Licenties verlengen")]),_c('v-divider',{staticClass:"mb-3"}),_c('p',{staticClass:"body-1"},[_vm._v(" U staat op het punt om "+_vm._s(_vm.selectedLicenses.length)+" "+_vm._s(_vm.selectedLicenses.length > 1 ? "licenties" : "licentie")+" te verlengen. ")]),_c('p',{staticClass:"body-1"},[_vm._v(" Hiervoor wordt een bill gegenereerd die u kunt terugvinden in de billing historie. ")]),_c('v-form',[_c('v-menu',{attrs:{"min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"prepend-icon":"mdi-calendar","label":"Nieuwe verloopdatum","value":_vm.createBillingDialog.date,"readonly":""}},on))]}}])},[_c('v-date-picker',{attrs:{"show-current":_vm.createBillingDialog.date},model:{value:(_vm.createBillingDialog.date),callback:function ($$v) {_vm.$set(_vm.createBillingDialog, "date", $$v)},expression:"createBillingDialog.date"}})],1),_c('v-btn',{attrs:{"loading":_vm.createBillingDialog.loading,"color":"primary"},on:{"click":_vm.createExtensionBill}},[_vm._v("Verlengen")])],1)],1)],1)],1)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }