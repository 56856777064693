<template>
  <v-container>
    <v-sheet elevation="4">
      <v-container>
        <h1 class="display-1"><slot name="title" /></h1>
        <v-divider class="divider" />
      </v-container>
      <slot name="tabs" />
      <v-container class="page-content" v-if="!loading">
        <slot />
      </v-container>
      <v-container v-else class="d-flex flex-column align-center">
        <v-progress-circular
          indeterminate
          color="primary"
          size="75"
          width="7"
        />
        <p>Inhoud wordt geladen...</p>
      </v-container>
    </v-sheet>
  </v-container>
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
      default: true
    }
  },
  name: "Page"
};
</script>

<style>
.divider {
  margin: 0.3rem 0 0 0;
}
/*.page-content {*/
/*  margin-top: 1.5rem;*/
/*}*/
.col-2-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 1rem;
}
</style>
