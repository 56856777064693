<template>
  <validation-observer ref="observer">
    <form @submit.prevent="submit">
      <validation-provider v-slot="{ errors }" name="Voornaam" rules="required">
        <v-text-field
          v-model="input.first_name"
          prefix="*"
          :error-messages="errors"
          label="Voornaam"
          required
        ></v-text-field>
      </validation-provider>
      <validation-provider
        v-slot="{ errors }"
        name="Achternaam"
        rules="required"
      >
        <v-text-field
          v-model="input.last_name"
          prefix="*"
          :error-messages="errors"
          label="Achternaam"
          required
        ></v-text-field>
      </validation-provider>
      <validation-provider
        v-slot="{ errors }"
        name="Emailadres"
        rules="required"
      >
        <v-text-field
          v-model="input.email"
          prefix="*"
          :error-messages="errors"
          label="Emailadres"
          required
        ></v-text-field>
      </validation-provider>

      <validation-provider
        v-slot="{ errors }"
        name="Mobiele telefoonnummer"
        rules="required"
      >
        <v-text-field
          v-model="input.mobile"
          :error-messages="errors"
          label="Mobiele telefoonnummer"
        ></v-text-field>

        <v-text-field
          v-model="input.phone"
          :error-messages="errors"
          label="Vast telefoonnummer"
        ></v-text-field>
      </validation-provider>
      <validation-provider v-slot="{ errors }" name="Afdeling" rules="required">
        <v-text-field
          v-model="input.department"
          prefix="*"
          :error-messages="errors"
          label="Afdeling"
          required
        ></v-text-field>
      </validation-provider>
      <validation-provider v-slot="{ errors }" name="Functie" rules="required">
        <v-text-field
          prefix="*"
          v-model="input.job_title"
          :error-messages="errors"
          label="Functie"
        ></v-text-field>
      </validation-provider>
      <v-container fluid v-if="isDigitaleOpsporing">
        <v-checkbox
          v-model="input.admin"
          :label="`Is ${input.first_name} ${input.last_name} een klantbeheerder?`"
        ></v-checkbox>
      </v-container>
    </form>
  </validation-observer>
</template>

<script>
import { mapGetters } from "vuex";
import { required } from "vee-validate/dist/rules";
import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode
} from "vee-validate";

setInteractionMode("eager");
extend("required", {
  ...required,
  message: "{_field_} is een verplicht veld."
});

export default {
  name: "UserForm",
  props: ["value", "twofactor"],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  data() {
    return {
      valid: false,
      input: {
        first_name: "",
        last_name: "",
        email: "",
        mobile: "",
        phone: "",
        admin: false,
        products: [],
        department: "",
        job_title: ""
      },
      products: ["irc"]
    };
  },
  methods: {
    updateValue() {
      this.$refs.observer.validate().then(success => {
        if (success) {
          this.$emit("input", this.input);
        } else {
          this.$emit("input", false);
        }
      });
    },

    isMobilePhoneRequired() {
      if (!this.input.mobile) {
        return 'Veld is verplicht indien "Vast telefoonnummer" niet is ingevuld.';
      }

      return true;
    },
    isPhoneRequired() {
      if (this.input.phone === "") {
        return 'Veld is verplicht indien "Mobiele telefoonnummer" niet is ingevuld.';
      }

      return true;
    },
  },
  computed: {
    ...mapGetters("organisations", ["getOrganisation"]),
    ...mapGetters("auth", ["user"]),
    asAdmin() {
      return this.$route.name === "admin.organisations";
    },
    isDigitaleOpsporing() {
      if (!this.user.organisation_id)
        return this.user.permissions.includes("admin");
      return (
        this.getOrganisation(this.user.organisation_id).name ===
        "Digitale Opsporing"
      );
    }
  },
  watch: {
    input: {
      handler() {
        this.updateValue();
      },
      deep: true
    }
  }
};
</script>

<style scoped></style>
