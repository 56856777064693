<template>
  <v-snackbar v-model="showSnackbar">{{ this.errorMessages[0] | translate }}</v-snackbar>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "ErrorMessage",
  data() {
    return {
      errorMessages: [],
      showSnackbar: false
    };
  },
  computed: {
    ...mapGetters("notifications", ["errorMessage"])
  },
  watch: {
    errorMessage: {
      handler(newValue) {
        if (newValue) {
          this.errorMessages.push(newValue);
          this.showSnackbar = true;
        }
      }
    },
    showSnackbar: {
      handler(newValue) {
        if (!newValue) {
          this.errorMessages.shift();
          if (this.errorMessages.length > 0) {
            this.showSnackbar = true;
          } else
            this.$store.dispatch("notifications/setErrorMessage", undefined);
        }
      }
    }
  }
};
</script>

<style lang="scss"></style>
