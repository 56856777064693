<template>
  <v-alert
    color="primary"
    colored-border
    border="left"
    icon="mdi-information-outline"
    dismissible
  >
    <h3 class="title">
      <slot name="title" />
    </h3>
    <slot name="content" />
  </v-alert>
</template>

<script>
export default {
  name: "InformationBlock"
};
</script>

<style>
.v-alert__icon {
  font-size: 38px !important;
  align-self: center;
}
</style>
