import AxiosFactory from "@/repositories/AxiosFactory";

const resource = "logging/irc";

export default {
  store(data) {
    return AxiosFactory.post(resource, data);
  },
  update(data, id) {
    return AxiosFactory.put(`${resource}/${id}`, data);
  },
  index(data) {
    return AxiosFactory.post(`${resource}/index`, data);
  }
};
