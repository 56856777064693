<template>
  <page :loading="false">
    <template v-slot:title>
      Wachtwoord resetten
    </template>
    <template>
      <v-expand-transition>
        <information-block>
          <template v-slot:title>Wachtwoord resetten</template>
          <template v-slot:content>
            <p>
              U bent uw wachtwoord vergeten en wilt uw wachtwoord herstellen.
              Voer hieronder uw nieuwe wachtwoord in en u kunt direct weer
              inloggen.
            </p>
            <p>
              Let wel: bepaalde gegevens die versleuteld zijn opgeslagen met uw
              oude wachtwoord zijn hierna niet meer toegankelijk.
            </p>
          </template>
        </information-block>
      </v-expand-transition>
      <v-form class="col-2-grid" v-model="form.valid">
        <v-text-field
          label="Nieuw wachtwoord"
          :rules="form.rules.passwordRules"
          v-model="form.input.password"
          :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
          :type="showPassword ? 'text' : 'password'"
          @click:append="showPassword = !showPassword"
        />
        <v-text-field
          label="Wachtwoord herhalen"
          :rules="form.rules.passwordConfirmationRules"
          v-model="form.input.password_confirmation"
          :append-icon="showPasswordConfirmation ? 'mdi-eye' : 'mdi-eye-off'"
          :type="showPasswordConfirmation ? 'text' : 'password'"
          @click:append="showPasswordConfirmation = !showPasswordConfirmation"
        />
      </v-form>
      <v-btn @click="resetPassword" color="primary" :disabled="!form.valid"
        >Opslaan</v-btn
      >
    </template>
  </page>
</template>

<script>
import RepositoryFactory from "@/repositories/RepositoryFactory";

export default {
  props: {
    signedLink: {
      type: String
    }
  },
  name: "ResetPassword",
  data() {
    return {
      form: {
        valid: false,
        loading: false,
        showPassword: false,
        showPasswordConfirmation: false,
        rules: {
          passwordRules: [
            v => !!v || "Dit veld is verplicht",
            v =>
              v.length >= 12 || "Wachtwoord moet ten minste 12 tekens bevatten"
          ],
          passwordConfirmationRules: [
            v => !!v || "Dit veld is verplicht",
            v =>
              v === this.form.input.password ||
              "Wachtwoorden komen niet overeen"
          ]
        },
        input: {
          password: "",
          password_confirmation: ""
        }
      }
    };
  },
  methods: {
    resetPassword() {
      let url = atob(this.signedLink);
      let endpoint = url.slice(url.indexOf("/api/v1") + 7);
      RepositoryFactory.get("auth")
        .resetPassword(this.form.input, endpoint)
        .then(res => {
          if (res.status === 200) {
            this.$store.dispatch(
              "notifications/addFeedbackNotification",
              "feedback.resetPasswordSuccess"
            );
          }
        })
        .catch(err => {
          if (err.message === "error.password_unsafe") {
            this.$store.dispatch(
              "notifications/addFeedbackNotification",
              "error.password_unsafe"
            );
          } else {
            this.$store.dispatch(
              "notifications/addFeedbackNotification",
              "error.generic"
            );
          }
        });
    }
  }
};
</script>

<style scoped></style>
