<template>
  <page :loading="!contentLoaded">
    <template v-slot:title>Klantoverzicht</template>
    <template v-slot:default>
      <v-btn
        color="primary"
        @click="downloadContactDetailsAsExcel"
        outlined
        :loading="contactDetailsExport.loading"
        ><v-icon>mdi-download</v-icon> contactgegevens</v-btn
      >
      <v-btn
        class="ml-1"
        color="primary"
        @click="downloadCompleteContactDetailsAsExcel"
        outlined
        :loading="completeContactDetailsExport.loading"
        ><v-icon>mdi-download</v-icon> contactgegevens compleet</v-btn
      >
      <v-btn
        class="ml-1"
        color="primary"
        @click="downloadOrganisationsMailinglist"
        outlined
        :loading="organisationsMailinglistExport.loading"
        ><v-icon>mdi-download</v-icon> mailinglist</v-btn
      >
      <v-data-table
        :headers="table.headers"
        :items="computedOrganisationsInPeriod"
      >
        <template v-slot:item.created_at="{ item }">
          {{ item.created_at | formatDate }}
        </template>
        <template v-slot:top>
          <v-row>
            <v-col>
              <v-text-field
                label="Zoeken"
                prepend-inner-icon="mdi-magnify"
                v-model="table.searchTerm"
              />
            </v-col>
            <v-col>
              <v-combobox
                label="Periode"
                :items="table.periods"
                item-text="text"
                item-value="value"
                v-model="table.selectedPeriod"
              />
            </v-col>
          </v-row>
        </template>
      </v-data-table>
    </template>
  </page>
</template>

<script>
import { mapGetters } from "vuex";
import RepositoryFactory from "../../../repositories/RepositoryFactory";
export default {
  name: "ClientOverview",
  data() {
    return {
      table: {
        headers: [
          {
            text: "ID",
            value: "id"
          },
          {
            text: "Naam",
            value: "name"
          },
          {
            text: "Aanmaakdatum",
            value: "created_at"
          }
        ],
        periods: [
          {
            text: "Afgelopen week",
            value: "week"
          },
          {
            text: "Afgelopen maand",
            value: "month"
          },
          {
            text: "Afgelopen 6 maanden",
            value: "sixMonths"
          },
          {
            text: "Afgelopen jaar",
            value: "year"
          },
          {
            text: "Alles",
            value: "all"
          }
        ],
        selectedPeriod: {
          text: "Alles",
          value: "all"
        },
        searchTerm: ""
      },
      contactDetailsExport: {
        loading: false
      },
      completeContactDetailsExport: {
        loading: false
      },
      organisationsMailinglistExport: {
        loading: false
      }
    };
  },
  methods: {
    dateIsInPeriod(date) {
      let today = new Date();
      let periodDate = undefined;
      switch (this.table.selectedPeriod.value) {
        case "week":
          periodDate = today.setDate(today.getDate() - 7);
          break;
        case "month":
          periodDate = today.setMonth(today.getMonth() - 1);
          break;
        case "sixMonths":
          periodDate = today.setMonth(today.getMonth() - 6);
          break;
        case "year":
          periodDate = today.setFullYear(today.getFullYear() - 1);
          break;
      }
      return periodDate < new Date(date).getTime();
    },
    downloadCompleteContactDetailsAsExcel() {
      this.completeContactDetailsExport.loading = true;
      RepositoryFactory.get("sales")
        .downloadCompleteContactDetails()
        .then(res => {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "contactgegevens.xlsx");
          document.body.appendChild(link);
          link.click();
        })
        .catch(() => {
          this.$store.dispatch(
            "notifications/addFeedbackNotification",
            "error.generic"
          );
        })
        .finally(() => {
          this.contactDetailsExport.loading = false;
        });
    },
    downloadContactDetailsAsExcel() {
      this.contactDetailsExport.loading = true;
      RepositoryFactory.get("sales")
        .downloadContactDetails()
        .then(res => {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "contactgegevens.xlsx");
          document.body.appendChild(link);
          link.click();
        })
        .catch(() => {
          this.$store.dispatch(
            "notifications/addFeedbackNotification",
            "error.generic"
          );
        })
        .finally(() => {
          this.contactDetailsExport.loading = false;
        });
    },
    downloadOrganisationsMailinglist() {
      this.organisationsMailinglistExport.loading = true;
      RepositoryFactory.get("sales")
        .downloadOrganisationsMailinglist()
        .then(res => {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "mailinglist.xlsx");
          document.body.appendChild(link);
          link.click();
        })
        .catch(() => {
          this.$store.dispatch(
            "notifications/addFeedbackNotification",
            "error.generic"
          );
        })
        .finally(() => {
          this.organisationsMailinglistExport.loading = false;
        });
    },
  },
  computed: {
    ...mapGetters(["organisations/organisations"]),
    organisations() {
      return this["organisations/organisations"];
    },
    computedOrganisationsInPeriod() {
      if (!this.organisations) return [];
      return this.organisations
        .filter(el => {
          if (this.table.selectedPeriod.value === "all") return true;
          return this.dateIsInPeriod(el.created_at);
        })
        .filter(el => {
          return (
            el.name.includes(this.table.searchTerm) ||
            this.$options.filters
              .formatDate(el.created_at)
              .includes(this.table.searchTerm)
          );
        });
    },
    contentLoaded() {
      return this.organisations !== undefined;
    }
  },
  mounted() {
    if (!this["organisations/organisations"]) {
      this.$store.dispatch("organisations/loadOrganisations");
    }
  }
};
</script>

<style scoped></style>
