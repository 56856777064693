<template>
  <page :loading="!contentLoaded">
    <template v-slot:title>iRC Dashboard</template>
    <template v-slot:default>
      <v-card class="mb-4">
        <v-container v-if="!(ircStatus && ircStatus.state)">
          <p class="title">
            Status: <v-icon color="green" small>mdi-circle</v-icon>
          </p>
          <p class="subtitle-2">
            Er zijn geen storingen bekend; alle systemen zijn beschikbaar
          </p>
        </v-container>
        <v-container v-else>
          <p class="title">
            Status: <v-icon :color="ircStateColor" small>mdi-circle</v-icon>
          </p>
          <p class="subtitle-2">{{ ircStatus.message }}</p>
        </v-container>
      </v-card>
      <v-card class="mb-4">
        <v-container>
          <p class="title">Mededelingen</p>
          <p v-if="!newsItems || newsItems.length === 0">
            {{ Date.now() | formatDate }}: Geen mededelingen
          </p>
          <template v-else>
            <div v-for="newsItem in newsItems" :key="newsItem.title">
              <h1 class="title">{{ newsItem.title }}</h1>
              <h3 class="subtitle-2">{{ newsItem.created_at | formatDate }}</h3>
              <p class="content body-2">{{ newsItem.message }}</p>
              <v-divider class="mb-4" />
            </div>
          </template>
        </v-container>
      </v-card>
      <v-card>
        <v-container>
          <p class="title">Uw account</p>
          <p>
            <template v-if="otkLicense">
            Uw balans:
            {{ balance }}

            <br><br>
            </template>

            Uw gebruikersnaam:
            {{
              ircLicense ? "IRC\\" + ircLicense.meta["username"] : "onbekend"
            }}
          </p>

          <p class="title">iRC</p>
          <v-btn
            color="primary"
            outlined
            x-large
            class="mr-3"
            @click="downloadShortcut"
            :loading="dashboardButtons.shortcutLoading"
            :disabled="!ircActivated"
          >
            <template v-slot:default>
              <div class="d-flex flex-column">
                <v-icon>mdi-download</v-icon>
                <span class="body-1 text-capitalize">Snelkoppeling</span>
              </div>
            </template>
          </v-btn>
          <v-btn
            color="primary"
            outlined
            x-large
            class="mr-3"
            @click="openManual"
            :loading="dashboardButtons.manualLoading"
            :disabled="!ircActivated"
          >
            <template v-slot:default>
              <div class="d-flex flex-column">
                <v-icon>mdi-book-open-variant</v-icon>
                <span class="body-1 text-capitalize">Handleiding</span>
              </div>
            </template>
          </v-btn>
          <v-btn
            color="primary"
            outlined
            x-large
            class="mr-3"
            @click="changeIRCPasswordDialog.show = true"
            v-if="ircLicense"
            :disabled="!ircActivated"
          >
            <template v-slot:default>
              <div class="d-flex flex-column">
                <v-icon>mdi-lock-reset</v-icon>
                <span class="body-1 text-capitalize">Wijzig wachtwoord</span>
              </div>
            </template>
          </v-btn>
          <v-btn
            color="primary"
            outlined
            x-large
            class="mr-3"
            @click="resetOTKDialog.show = true"
            v-if="otkLicense"
            :disabled="!ircActivated"
          >
            <template v-slot:default>
              <div class="d-flex flex-column">
                <v-icon>mdi-shield-key-outline</v-icon>
                <span class="body-1 text-capitalize">Reset OTK</span>
              </div>
            </template>
          </v-btn>
        </v-container>
      </v-card>
      <v-dialog v-model="changeIRCPasswordDialog.show" width="600">
        <v-sheet>
          <v-toolbar color="teal" dark>
            <v-icon class="toolbar-title-icon">mdi-account</v-icon>
            <v-toolbar-title>Wachtwoord wijzigen</v-toolbar-title>
          </v-toolbar>
          <v-container>
            <information-block>
              <template v-slot:title>iRC wachtwoord wijzigen</template>
              <template v-slot:content>
                Via dit formulier kunt u uw iRC wachtwoord wijzigen waarmee u
                via de RDP koppeling inlogt.
              </template>
            </information-block>
            <v-form v-model="changeIRCPasswordDialog.valid">
              <v-text-field
                label="Wachtwoord"
                :rules="changeIRCPasswordDialog.rules.password"
                v-model="changeIRCPasswordDialog.input.password"
                :append-icon="
                  changeIRCPasswordDialog.showPassword
                    ? 'mdi-eye'
                    : 'mdi-eye-off'
                "
                :type="
                  changeIRCPasswordDialog.showPassword ? 'text' : 'password'
                "
                @click:append="
                  changeIRCPasswordDialog.showPassword = !changeIRCPasswordDialog.showPassword
                "
              />
              <v-text-field
                label="Herhaal wachtwoord"
                :rules="changeIRCPasswordDialog.rules.passwordConfirmation"
                v-model="changeIRCPasswordDialog.input.password_confirmation"
                :append-icon="
                  changeIRCPasswordDialog.showPassword
                    ? 'mdi-eye'
                    : 'mdi-eye-off'
                "
                :type="
                  changeIRCPasswordDialog.showPassword ? 'text' : 'password'
                "
                @click:append="
                  changeIRCPasswordDialog.showPassword = !changeIRCPasswordDialog.showPassword
                "
              />
              <v-btn
                color="primary"
                :disabled="!changeIRCPasswordDialog.valid"
                class="mt-2"
                :loading="changeIRCPasswordDialog.loading"
                @click="changeIRCPassword"
                >Opslaan</v-btn
              >
            </v-form>
          </v-container>
        </v-sheet>
      </v-dialog>
      <v-dialog v-model="resetOTKDialog.show" width="600">
        <v-sheet>
          <v-container>
            <information-block>
              <template v-slot:title>OSINT Toolkit account resetten</template>
              <template v-slot:content>
                <p>
                  Door deze actie uit te voeren vernietigt u alle data die u
                  heeft opgeslagen in uw verleden cases. Vanwege ons
                  encryptiebeleid is deze data hierna onherstelbaar verloren.
                  Wees u bewust van de consequenties die dit mogelijk kan
                  hebben. Voer deze actie alleen uit als u uw wachtwoord niet
                  meer weet.
                </p>
                <p>
                  Weet u uw wachtwoord nog wel, en wilt u deze alleen wijzigen?
                  Dan kunt u dit doen in uw account opties binnen de OSINT
                  Toolkit.
                </p>
              </template>
            </information-block>
            <v-form v-model="resetOTKDialog.form.valid">
              <v-switch
                label="Ik heb bovenstaande tekst gelezen en ga hiermee akkoord"
                :disabled="!resetOTKDialog.form.switchEnabled"
                :rules="resetOTKDialog.form.rules.confirmed"
                v-model="resetOTKDialog.form.input.confirmed"
              />
              <v-btn
                color="primary"
                :disabled="!resetOTKDialog.form.valid"
                :loading="resetOTKDialog.loading"
                @click="resetOTKAccount"
                >Account resetten</v-btn
              >
            </v-form>
          </v-container>
        </v-sheet>
      </v-dialog>
    </template>
  </page>
</template>

<script>
import { mapGetters } from "vuex";
import RepositoryFactory from "@/repositories/RepositoryFactory";
export default {
  name: "IRCDashboard",
  data() {
    return {
      ircStatus: undefined,
      newsItems: undefined,
      changeIRCPasswordDialog: {
        showPassword: false,
        showPasswordConfirmation: false,
        show: false,
        password: "",
        valid: false,
        loading: false,
        input: {
          password: "",
          password_confirmation: ""
        },
        rules: {
          password: [
            v => !!v || "Dit veld is verplicht",
            v => v.length >= 12 || "Wachtwoord moet minimaal 12 tekens bevatten"
          ],
          passwordConfirmation: [
            v => !!v || "Dit veld is verplicht",
            v =>
              v === this.changeIRCPasswordDialog.input.password ||
              "Dit veld moet gelijk zijn aan wachtwoord"
          ]
        }
      },
      resetOTKDialog: {
        show: false,
        loading: false,
        form: {
          valid: false,
          switchEnabled: false,
          input: {
            confirmed: false
          },
          rules: {
            confirmed: [v => !!v || "U moet de voorwaarden accepteren"]
          }
        }
      },
      dashboardButtons: {
        shortcutLoading: false,
        manualLoading: false
      },
      otkDialogTimeout: undefined,
      balance: undefined
    };
  },
  methods: {
    downloadShortcut() {
      this.dashboardButtons.shortcutLoading = true;
      RepositoryFactory.get("ircProduct")
        .getShortcut()
        .then(res => {
          let blob = new Blob([res.data], {
            type: "application/octet-stream"
          });
          const link = document.createElement("a");
          const url = URL.createObjectURL(blob);
          link.setAttribute("href", url);
          link.setAttribute("download", "iRC-Browser.rdp");
          link.style.visibility = "hidden";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
        .catch(() => {
          this.$store.dispatch(
            "notifications/addFeedbackNotification",
            "error.generic"
          );
        })
        .finally(() => {
          this.dashboardButtons.shortcutLoading = false;
        });
    },
    openManual() {
      this.dashboardButtons.manualLoading = true;
      RepositoryFactory.get("ircProduct")
        .downloadManual()
        .then(res => {
          let blob = new Blob([res.data], {
            type: "application/pdf"
          });
          const link = document.createElement("a");
          let url = URL.createObjectURL(blob);
          link.setAttribute("href", url);
          link.setAttribute("download", "iRC-Browser-handleiding.pdf");
          link.style.visibility = "hidden";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
        .catch(() => {
          this.$store.dispatch(
            "notifications/addFeedbackNotification",
            "error.generic"
          );
        })
        .finally(() => {
          this.dashboardButtons.manualLoading = false;
        });
    },
    changeIRCPassword() {
      this.changeIRCPasswordDialog.loading = true;
      RepositoryFactory.get("ircLicenses")
        .changeIRCPassword(
          this.changeIRCPasswordDialog.input,
          this.ircLicense.id
        )
        .then(() => {
          this.$store.dispatch(
            "notifications/addFeedbackNotification",
            "feedback.irc.password_changed"
          );
        })
        .catch(err => {
          let message = "error.generic";
          if (err.response.status === 406) {
            console.log(err.response.data.message);
            message = err.response.data.message;
          }
          this.$store.dispatch(
            "notifications/addFeedbackNotification",
            message
          );
        })
        .finally(() => {
          this.changeIRCPasswordDialog.loading = false;
          this.changeIRCPasswordDialog.show = false;
        });
    },
    resetOTKAccount() {
      this.resetOTKDialog.loading = true;
      RepositoryFactory.get("otkLicenses")
        .resetAccount(this.resetOTKDialog.form.input, this.otkLicense.id)
        .then(() => {
          this.$store.dispatch(
            "notifications/addFeedbackNotification",
            "feedback.otk.account_reset"
          );
        })
        .catch(() => {
          this.$store.dispatch(
            "notifications/addFeedbackNotification",
            "error.generic"
          );
        })
        .finally(() => {
          this.resetOTKDialog.loading = false;
          this.resetOTKDialog.show = false;
        });
    },
    getIRCState() {
      RepositoryFactory.get("ircState")
        .getIRCState()
        .then(data => (this.ircStatus = data.data));
    },
    getNewsItems() {
      RepositoryFactory.get("newsItems")
        .getNewsItems()
        .then(res => {
          this.newsItems = res.data;
        });
    },
    getBalance(username) {
      RepositoryFactory.get("users")
        .getBalance(username)
        .then(res => {
          this.balance = res.data.balance;
        });
    }
  },
  computed: {
    ...mapGetters("auth", ["user"]),
    ircLicense() {
      return this.user.licenses.length > 0
        ? this.user.licenses.find(el => el.product === "irc")
        : false;
    },
    ircActivated() {
      try {
        //return this.ircLicense.meta.ldapActivated;
        return this.ircLicense.meta;
      } catch (err) {
        return false;
      }
    },
    otkLicense() {
      return this.user.licenses.length > 0
        ? this.user.licenses.find(el => el.product === "otk")
        : false;
    },
    ircStateColor() {
      if (!(this.ircStatus && this.ircStatus.state)) return false;
      switch (this.ircStatus.state) {
        case "online":
          return "green";
        case "offline":
          return "red";
        default:
          return "green";
      }
    },
    contentLoaded() {
      return (
        this.user !== undefined &&
        this.newsItems !== undefined &&
        this.ircStatus !== undefined
      );
    }
  },
  watch: {
    resetOTKDialog: {
      handler(newVal) {
        if (newVal.show) {
          this.otkDialogTimeout = setTimeout(() => {
            this.resetOTKDialog.form.switchEnabled = true;
          }, 3000);
        } else {
          clearTimeout(this.otkDialogTimeout);
          this.resetOTKDialog.form.switchEnabled = false;
        }
      },
      deep: true
    }
  },
  mounted() {
    this.getIRCState();
    this.getNewsItems();
    console.log(this.ircLicense.meta["username"]);
    this.getBalance(this.ircLicense.meta["username"]);
  }
};
</script>

<style scoped>
.content {
  white-space: pre-wrap;
}
</style>
