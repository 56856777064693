import AxiosFactory from "@/repositories/AxiosFactory";

const resource = "licenses/otk";

export default {
  store(data) {
    return AxiosFactory.post(resource, data);
  },
  update(data, id) {
    return AxiosFactory.put(`${resource}/${id}`, data);
  },
  destroy(id) {
    return AxiosFactory.delete(`${resource}/${id}`);
  },
  resetAccount(data, id) {
    return AxiosFactory.post(`${resource}/${id}/reset-otk-account`, data);
  },
  getBalance(id) {
    return AxiosFactory.get(`${resource}/${id}/balance`);
  },
  delete(id) {
    return AxiosFactory.delete(`${resource}/${id}`);
  }
};
