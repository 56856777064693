import AxiosFactory from "@/repositories/AxiosFactory";

const resource = "licenses/webaccess";

export default {
  store(data) {
    return AxiosFactory.post(resource, data);
  },
  update(data, id) {
    return AxiosFactory.put(`${resource}/${id}`, data);
  },
  delete(id) {
    return AxiosFactory.delete(`${resource}/${id}`);
  }
};
