<template>
  <div>
    <v-data-table
      :headers="licenseList.headers"
      :items="this.getOpenLicenses"
      @click:row="this.openLicenseDialog"
      class="mt-5 row-pointer"
    >
      <template v-slot:top>
        <v-text-field
          prepend-icon="mdi-magnify"
          label="Zoeken..."
        />
      </template>
    </v-data-table>
    <v-dialog v-model="licenseDialog.show" :width="600" persistent>
      <v-toolbar color="teal" dark>
        <v-icon class="toolbar-title-icon">mdi-card</v-icon>
        <v-toolbar-title>Licentie</v-toolbar-title>
        <div class="flex-grow-1"></div>
        <v-btn
          fab
          small
          class="btnCloseDialog"
          @click="licenseDialog.show = false"
          ><v-icon>mdi-close</v-icon></v-btn
        >
      </v-toolbar>
      <v-sheet>
        <v-container>
          <v-list two-line v-if="this.selected.license !== null">
            <v-list-item class="pa-0">
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>ID</v-list-item-title>
                  <v-list-item-subtitle>{{ this.selected.license.id }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>Product</v-list-item-title>
                  <v-list-item-subtitle>{{ this.selected.license.product }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list-item>

            <v-list-item class="pa-0">
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>Aangemaakt op</v-list-item-title>
                  <v-list-item-subtitle>{{ this.selected.license.created_at }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>Verloopt op</v-list-item-title>
                  <v-list-item-subtitle>{{ this.selected.license.expiration_date }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list-item>
          </v-list>

          <v-divider></v-divider>

          <v-btn @click="deleteLicense" class="mt-3" color="error">Verwijderen</v-btn>
        </v-container>
      </v-sheet>
    </v-dialog>
  </div>
</template>

<script>
import RepositoryFactory from "@/repositories/RepositoryFactory";

export default {
  name: "LicenseList",
  components: {},
  props: {
    organisation: Object
  },
  data() {
    return {
      selected: {
        license: null
      },
      licenseDialog: {
        show: false
      },
      editLicenseDialog: {
        show: false
      },
      licenseList: {
        headers: [
          { text: "ID", value: "id" },
          { text: "Product", value: "product" },
          { text: "Aangemaakt op", value: "created_at" },
          { text: "Verloopt op", value: "expiration_date" }
        ]
      }
    };
  },
  methods: {
    openLicenseDialog(license) {
      this.licenseDialog.show = true;
      this.selected.license = license;
    },
    deleteLicense() {
      let repo = this.selected.license.product + 'Licenses';
      if (this.selected.license.product === 'webaccess') {
        repo = "webAccessLicenses";
      }

      console.log(repo);

      RepositoryFactory.get(repo)
        .delete(this.selected.license.id)
        .then(() => {
          this.selected.license = null;
          this.licenseDialog.show = false;
          this.$store.dispatch(
            "notifications/addFeedbackNotification",
            "feedback.users.deleted"
          );
        });
    },

  },
  computed: {
    // Open licenses are licenses where the userId = 0.
    getOpenLicenses() {
      return this.organisation.licenses.filter(
        license => license.user_id === 0 || license.user_id === null
      );
    }
  }
}
</script>

<style scoped>

</style>