<template>
  <page :loading="!contentLoaded" style="padding-top: 0px;">
    <template v-slot:title>
      Dashboard
    </template>
    <template v-slot:default>
      <p style="font-weight: bold;">
        Welkom, {{ user.first_name }} {{ user.last_name }}.
      </p>

      <v-row>
        <v-col cols="4">
          <v-card v-if="user.permissions.includes('customer')">
            <v-container>
              <span style="font-weight: bold;">Uw organisatie:</span>
              <v-divider class="mb-3 mt-3"></v-divider>
              <p>{{ currentOrganisation.name }}</p>
              <p class="mb-0">
                Twee factor authenticatie:
                {{
                  currentOrganisation.twofactor_active
                    ? "geactiveerd"
                    : "niet geactiveerd"
                }}
              </p>
              <p class="mb-0">
                Geregistreerde gebruikers:
                {{ currentOrganisation.members.length }}
              </p>
              <p class="mb-0">
                Uitgenodigde gebruikers:
                {{ currentOrganisation.pending_members.length }}
              </p>
            </v-container>
          </v-card>
        </v-col>
        <v-col cols="4">
          <v-card>
            <v-container>
              <span style="font-weight: bold;">Uw licenties:</span>
              <v-divider class="mb-3 mt-3"></v-divider>
              <p class="mb-0" v-for="license in user.licenses" :key="license.id">
                {{ getProductName(license.product) }} <span style="font-size: 13px;">(geldig tot: {{ license.expiration_date }})</span>
              </p>
            </v-container>
          </v-card>
        </v-col>
        <v-col cols="4">
          <admin-tile />
        </v-col>
      </v-row>

      <v-divider class="mt-4 mb-2" />
      <h2 class="headline">Uw producten</h2>
      <div class="d-flex" v-if="licenses ? licenses.length > 0 : false">
        <v-card
          width="24%"
          min-width="200px"
          class="mr-3"
          :to="{ name: 'users.products.irc' }"
        >
          <v-container>
            <v-icon>mdi-open-in-new</v-icon> Naar iRC dashboard
          </v-container>
        </v-card>
      </div>
      <div v-else>
        <v-card>
          <v-container>
            U heeft nog geen producten. Neem contact op met uw accountbeheerder.
          </v-container>
        </v-card>
      </div>
    </template>
  </page>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import AdminTile from "@/views/Shared/Dashboard/AdminTile";
export default {
  name: "DashboardV2",
  props: ["organisation"],
  components: {
    AdminTile
  },
  data() {
    return {};
  },
  methods: {
    ...mapActions("auth", ["reloadUser"]),

    getProductName(productKey) {
      switch (productKey) {
        case "irc":
          return "iRC";
        case "otk":
          return "OTK (OSINT Toolkit)";
        case "webaccess":
          return "WebAccess";
        case "darkweb":
          return "Darkweb";
        default:
          return "Onbekend";
      }
    }
  },
  computed: {
    ...mapGetters("auth", ["user", "licenses"]),
    ...mapGetters("organisations", ["getOrganisation"]),
    contentLoaded() {
      return this.user !== undefined && this.currentOrganisation !== undefined;
    },
    currentOrganisation() {
      return this.getOrganisation(this.user.organisation_id);
    },
  },
  mounted() {
    this.$store.dispatch("organisations/loadOrganisations");
  }
};
</script>

<style scoped>
.page-content {
  padding-top: 0px !important;
}
</style>
