import AxiosFactory from "@/repositories/AxiosFactory";

const resource = "logging/irc/shared-log";

export default {
  store(data) {
    return AxiosFactory.post(resource, data);
  },
  update(data, id) {
    return AxiosFactory.put(`${resource}/${id}`, data);
  },
  index() {
    return AxiosFactory.get(`${resource}`);
  },
  get(data, id) {
    return AxiosFactory.post(`${resource}/${id}`, data, {
      responseType: "blob"
    });
  }
};
