var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-navigation-drawer',{staticClass:"elevation-3",attrs:{"floating":"","app":"","clipped":"","permanent":"","mini-variant":!_vm.selectedCategory,"color":"#eee"}},[_c('v-row',{staticClass:"fill-height",attrs:{"no-gutters":""}},[_c('v-navigation-drawer',{staticClass:"mt-n3",attrs:{"permanent":"","mini-variant":"","dark":"","height":"auto","floating":""}},[_c('div',{staticClass:"d-flex flex flex-column justify-space-between fill-height"},[_c('v-list',{model:{value:(_vm.selectedCategory),callback:function ($$v) {_vm.selectedCategory=$$v},expression:"selectedCategory"}},_vm._l((_vm.categoriesWithFilteredProducts.filter(
                function (el) { return !el.hidden; }
              )),function(cat){return _c('v-list-item',{key:cat.value,class:[
                _vm.selectedCategory === cat.value
                  ? 'selectedMainMenuItem'
                  : undefined
              ],attrs:{"title":cat.value},on:{"click":function($event){_vm.selectedCategory = cat.value}}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":_vm.selectedCategory === cat.value
                      ? 'grey darken-3'
                      : undefined}},[_vm._v(_vm._s(cat.icon))])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(cat.title))])],1)],1)}),1),_c('v-list',{staticStyle:{"padding-bottom":"0 !important"}},[_c('v-list-item',{class:[
                _vm.selectedCategory === 'account'
                  ? 'selectedMainMenuItem'
                  : undefined
              ],attrs:{"link":"","title":"Account"},on:{"click":function($event){_vm.selectedCategory = 'account'}}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":_vm.selectedCategory === 'account'
                      ? 'grey darken-3'
                      : undefined}},[_vm._v("mdi-account")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Account")])],1)],1)],1)],1)]),(_vm.selectedCategoryByValue)?_c('v-list',{staticClass:"grow d-flex flex-column grow justify-space-between",staticStyle:{"padding-bottom":"0"}},[_c('div',[_c('v-list-item',{attrs:{"two-line":""}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.selectedCategoryByValue.title)+" ")])],1),_c('v-divider'),_vm._l((_vm.selectedCategoryByValue.subCategories),function(cat){return [(cat.route)?_c('v-list-item',{key:cat.title,attrs:{"link":"","exact":"","to":{ name: cat.route }}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(cat.title))])],1)],1):_c('v-list-item',{key:cat.value,attrs:{"link":""},on:{"click":function($event){return _vm.dynamicMethodCaller(cat.method)}}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(cat.title))])],1)],1)]})],2),_c('div',[(_vm.$route.name !== 'Dashboard')?_c('v-list-item',{attrs:{"exact":"","to":{ name: 'Dashboard' }}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Terug naar dashboard")])],1)],1):_vm._e()],1)]):_vm._e()],1)],1),_c('v-dialog',{attrs:{"width":"600"},model:{value:(_vm.logoutConfirmation.show),callback:function ($$v) {_vm.$set(_vm.logoutConfirmation, "show", $$v)},expression:"logoutConfirmation.show"}},[_c('v-sheet',[_c('v-container',[_c('h1',{staticClass:"headline"},[_vm._v("U staat op het punt om uit te loggen")]),_c('p',{staticClass:"body-1"},[_vm._v("Weet u dit zeker?")]),_c('v-row',[_c('v-col',[_c('v-btn',{attrs:{"block":"","color":"primary"},on:{"click":_vm.logout}},[_vm._v("Bevestigen")])],1),_c('v-col',[_c('v-btn',{attrs:{"block":"","color":"error"},on:{"click":function($event){_vm.logoutConfirmation.show = false}}},[_vm._v("Annuleren")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }