<template>
  <v-slide-y-reverse-transition>
    <v-alert
      elevation="6"
      class="notification"
      v-if="notificationToShow && notification.show"
      width="400"
      border="left"
      colored-border
      color="primary"
      icon="mdi-information-outline"
      prominent
    >
      {{ notificationToShow | translate }}
    </v-alert>
  </v-slide-y-reverse-transition>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
export default {
  name: "Notification",
  data() {
    return {
      notification: {
        show: true,
        duration: 4000
      }
    };
  },
  methods: {
    ...mapMutations("notifications", ["clearFirstFeedbackNotification"])
  },
  computed: {
    ...mapGetters("notifications", ["feedbackNotifications"]),
    notificationToShow() {
      return this.feedbackNotifications.length > 0
        ? this.feedbackNotifications[0]
        : undefined;
    }
  },
  watch: {
    notificationToShow: {
      handler(newVal, oldVal) {
        if (newVal && newVal !== oldVal) {
          console.log("Updated value: " + newVal);
          setTimeout(() => {
            console.log("clearing " + newVal);
            this.clearFirstFeedbackNotification();
            this.notification.show = false;
          }, this.notification.duration);
          setTimeout(() => {
            this.notification.show = true;
          }, this.notification.duration + 500);
        } else if (!newVal && this.notification.show) {
          setTimeout(() => {
            this.notification.show = false;
          }, (this.notification.duration || 0) + 600);
        }
      },
      deep: true
    }
  }
};
</script>

<style scoped>
.notification {
  margin-top: 0.5em;
  position: fixed;
  bottom: 1rem;
  left: calc(50vw - 200px);
}
</style>
