import AxiosFactory from "@/repositories/AxiosFactory";

const resource = "/support";

export default {
  downloadUserManual() {
    return AxiosFactory.get(`${resource}/download-user-manual`, {
      responseType: "blob",
      responseEncoding: "utf-8"
    });
  },
  downloadCustomerManual() {
    return AxiosFactory.get(`${resource}/download-customer-manual`, {
      responseType: "blob",
      responseEncoding: "utf-8"
    });
  }
};
