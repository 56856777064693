<template>
  <div>
    <sidebar />
    <v-app-bar color="primary" app clipped-left dark src="@/assets/topbar.png">
      <v-img
        src="@/assets/logo.png"
        max-width="50"
        contain
        position="left"
        @click="$router.push({ name: 'Dashboard' })"
        style="cursor: pointer"
      ></v-img>
      <h1 class="display-1 ml-5">DigiPortal</h1>

      <h6 v-if="this.checkEnv" class="subtitle-1 ml-5">
        {{ this.getLatestBuildDate }}
      </h6>
      <v-spacer></v-spacer>
      <v-btn icon :to="{ name: 'users.support' }">
        <v-icon large>mdi-help-circle-outline</v-icon>
      </v-btn>
    </v-app-bar>
    <slot></slot>
  </div>
</template>

<script>
// import Sidebar from "@/components/layout/Sidebar";
import SidebarV2 from "@/components/layout/SidebarV2";
// import Footer from "@/components/layout/Footer";
export default {
  name: "Default",
  components: {
    sidebar: SidebarV2
    // Footer
  },

  computed: {
    checkEnv() {
      return process.env.NODE_ENV === 'development';
    },
    getLatestBuildDate() {
      return process.env.VUE_APP_LATEST_BUILD_DATE;
    }
  }
};
</script>

<style scoped></style>
