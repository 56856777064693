<template>
  <div>
    <v-navigation-drawer
      floating
      app
      clipped
      permanent
      class="elevation-3"
      :mini-variant="!selectedCategory"
      color="#eee"
    >
      <v-row class="fill-height" no-gutters>
        <v-navigation-drawer
          permanent
          mini-variant
          dark
          class="mt-n3"
          height="auto"
          floating
        >
          <div
            class="d-flex flex flex-column justify-space-between fill-height"
          >
            <v-list v-model="selectedCategory">
              <v-list-item
                v-for="cat in categoriesWithFilteredProducts.filter(
                  el => !el.hidden
                )"
                :key="cat.value"
                @click="selectedCategory = cat.value"
                :title="cat.value"
                :class="[
                  selectedCategory === cat.value
                    ? 'selectedMainMenuItem'
                    : undefined
                ]"
              >
                <v-list-item-icon>
                  <v-icon
                    :color="
                      selectedCategory === cat.value
                        ? 'grey darken-3'
                        : undefined
                    "
                    >{{ cat.icon }}</v-icon
                  >
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ cat.title }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <v-list style="padding-bottom: 0 !important;">
              <v-list-item
                link
                :class="[
                  selectedCategory === 'account'
                    ? 'selectedMainMenuItem'
                    : undefined
                ]"
                @click="selectedCategory = 'account'"
                title="Account"
              >
                <v-list-item-icon>
                  <v-icon
                    :color="
                      selectedCategory === 'account'
                        ? 'grey darken-3'
                        : undefined
                    "
                    >mdi-account</v-icon
                  >
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Account</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </div>
        </v-navigation-drawer>
        <v-list
          v-if="selectedCategoryByValue"
          class="grow d-flex flex-column grow justify-space-between"
          style="padding-bottom: 0"
        >
          <div>
            <v-list-item two-line>
              <v-list-item-title>
                {{ selectedCategoryByValue.title }}
              </v-list-item-title>
            </v-list-item>
            <v-divider />
            <template v-for="cat in selectedCategoryByValue.subCategories">
              <v-list-item
                :key="cat.title"
                link
                exact
                :to="{ name: cat.route }"
                v-if="cat.route"
              >
                <v-list-item-content>
                  <v-list-item-title>{{ cat.title }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                :key="cat.value"
                v-else
                link
                @click="dynamicMethodCaller(cat.method)"
              >
                <v-list-item-content>
                  <v-list-item-title>{{ cat.title }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </div>
          <div>
            <v-list-item
              exact
              :to="{ name: 'Dashboard' }"
              v-if="$route.name !== 'Dashboard'"
            >
              <v-list-item-content>
                <v-list-item-title>Terug naar dashboard</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </div>
        </v-list>
      </v-row>
    </v-navigation-drawer>
    <v-dialog v-model="logoutConfirmation.show" width="600">
      <v-sheet>
        <v-container>
          <h1 class="headline">U staat op het punt om uit te loggen</h1>
          <p class="body-1">Weet u dit zeker?</p>
          <v-row>
            <v-col>
              <v-btn block @click="logout" color="primary">Bevestigen</v-btn>
            </v-col>
            <v-col>
              <v-btn
                block
                @click="logoutConfirmation.show = false"
                color="error"
                >Annuleren</v-btn
              >
            </v-col>
          </v-row>
        </v-container>
      </v-sheet>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "SidebarV2",
  data() {
    return {
      mainCategories: [
        {
          title: "Administrator",
          permissions: ["admin"],
          icon: "mdi-cog",
          value: "admin",
          subCategories: [
            {
              title: "Gebruikers",
              route: "admin.users"
            },
            {
              title: "Organisaties",
              route: "admin.organisations"
            },
            {
              title: "Systeembeheer",
              route: "admin.sysadmin",
              permissions: ["sysadmin"]
            },
            {
              title: "Facturering",
              route: "admin.billing.customers",
              permissions: ["billing"]
            },
            {
              title: "Verkopen",
              route: "admin.sales.customers",
              permissions: ["sales"]
            },
            {
              title: "Demo's",
              route: "admin.demo.licenses"
            }
          ]
        },
        {
          title: "Organisatie",
          icon: "mdi-domain",
          permissions: ["customer"],
          value: "organisation",
          subCategories: [
            {
              title: "Overzicht",
              route: "customer.organisation"
            },
            {
              title: "Logging",
              route: "customer.logging.overview"
            }
          ]
        },
        {
          title: "Producten",
          icon: "mdi-apps",
          value: "products",
          subCategories: [
            {
              title: "Logging",
              route: "products.irc.logging",
              license: "irc"
            },
            {
              title: "iRC",
              route: "users.products.irc",
              license: "irc"
            }
          ]
        },
        {
          title: "Account",
          icon: "mdi-account",
          value: "account",
          hidden: true,
          subCategories: [
            {
              title: "Accountgegevens",
              route: "users.details",
              method: null
            },
            {
              title: "Support",
              route: "users.support",
              method: null
            },
            {
              title: "Uitloggen",
              route: null,
              method: "openLogoutConfirmation"
            }
          ]
        }
      ],
      selectedCategory: undefined,
      showSubCategories: false,
      logoutConfirmation: {
        show: false
      }
    };
  },
  methods: {
    dynamicMethodCaller(method) {
      return this[method]();
    },
    openLogoutConfirmation() {
      this.logoutConfirmation.show = true;
    },
    logout() {
      this.$store.dispatch("auth/logout").then(() => {
        this.$router.push({ name: "auth.login" });
      });
    },
    newNotification() {
      this.$store.dispatch(
        "notifications/addFeedbackNotification",
        "Notification " + new Date().getTime()
      );
    }
  },
  computed: {
    ...mapGetters("auth", ["user", "licenses"]),
    selectedCategoryByValue() {
      return this.selectedCategory
        ? this.categoriesWithFilteredProducts.filter(
            el => el.value === this.selectedCategory
          )[0]
        : undefined;
    },
    categoriesFilteredByPermission() {
      return this.mainCategories.filter(el =>
        el.permissions
          ? el.permissions.some(permission =>
              this.user !== undefined
                ? this.user.permissions.some(
                    userPermission => permission === userPermission
                  )
                : false
            )
          : true
      );
    },
    categoriesWithFilteredProducts() {
      let products = this.categoriesFilteredByPermission.filter(
        el => el.value === "products"
      )[0];
      if (!this.licenses) return [];
      let filteredProducts = this.licenses
        ? products.subCategories.filter(el => {
            return this.licenses.some(license => el.license === license.product);
          })
        : [];
      return this.categoriesFilteredByPermission.map(el => {
        if (el.value === "products") el.subCategories = filteredProducts;
        return el;
      });
    }
  },
  watch: {
    selectedCategory: {
      handler(newVal) {
        if (newVal !== undefined) {
          this.$nextTick().then(() => {
            this.showSubCategories = true;
          });
        }
      }
    }
  },
  mounted() {
    this.selectedCategory = this.categoriesFilteredByPermission[0].value;
  }
};
</script>

<style scoped>
.selectedMainMenuItem {
  background-color: #eee;
}
</style>
