import AxiosFactory from "@/repositories/AxiosFactory";

const resource = "/organisations";

export default {
  createOrganisation(data) {
    return AxiosFactory.post(resource, data);
  },
  index() {
    return AxiosFactory.get(
      `${resource}?include[]=licenses.product&include[]=members.licenses.product&include[]=pendingMembers&include[]=contacts&include[]=members.ircLogins&include[]=members.logins&include[]=ircOrganisation`
    );
  },
  get(orgId) {
    return AxiosFactory.get(
      `${resource}/${orgId}?include[]=licenses.product&include[]=members.licenses.product&include[]=pendingMembers&include[]=contacts&include[]=members.ircLogins&include[]=members.logins&include[]=ircOrganisation`
    );
  },
  delete(orgId) {
    return AxiosFactory.delete(`${resource}/${orgId}`);
  },
  update(orgId, data) {
    return AxiosFactory.put(`${resource}/${orgId}`, data);
  },
  downloadOrganisationAsExcel(id) {
    return AxiosFactory.get(`${resource}/${id}/export`, {
      responseType: "blob"
    });
  }
};
