import AxiosFactory from "@/repositories/AxiosFactory";

const resource = "/products/irc";

export default {
  getShortcut() {
    return AxiosFactory.get(`${resource}/download-shortcut`, {
      contentType: "blob"
    });
  },
  downloadManual() {
    return AxiosFactory.get(`${resource}/download-manual`, {
      responseType: "blob",
      responseEncoding: "utf-8"
    });
  }
};
