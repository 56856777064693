const notifications = {
  namespaced: true,
  state: () => ({
    pushNotifications: [],
    feedbackNotifications: [],
    errorMessage: ""
  }),
  mutations: {
    addPushNotification(state, message) {
      state.pushNotifications.push(message);
    },
    addFeedbackNotification(state, message) {
      state.feedbackNotifications = [...state.feedbackNotifications, message];
    },
    setErrorMessage(state, message) {
      state.errorMessage = message;
    },
    clearFirstFeedbackNotification(state) {
      state.feedbackNotifications = state.feedbackNotifications.slice(1);
    }
  },
  actions: {
    addPushNotification({ commit }, message) {
      commit("addPushNotification", message);
    },
    addFeedbackNotification({ commit }, message) {
      commit("addFeedbackNotification", message);
    },
    setErrorMessage({ commit }, message) {
      commit("setErrorMessage", message);
    },
    clearFirstFeedbackNotification({ commit }) {
      commit("clearFirstFeedbackNotification");
    }
  },
  getters: {
    pushNotifications(state) {
      return state.pushNotifications;
    },
    feedbackNotifications(state) {
      return state.feedbackNotifications;
    },
    errorMessage(state) {
      return state.errorMessage;
    }
  }
};

export default notifications;
