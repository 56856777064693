import AxiosFactory from "@/repositories/AxiosFactory";

const resource = "/trial-orders";

export default {
  index() {
    return AxiosFactory.get(`${resource}`);
  },
  update(id, payload) {
    return AxiosFactory.put(`${resource}/${id}`, payload);
  },
  delete(id) {
    return AxiosFactory.delete(`${resource}/${id}`);
  },
  store(data) {
    return AxiosFactory.post(`${resource}`, data);
  }
};
