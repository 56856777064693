<template>
  <v-card class="d-flex flex flex-column justify-space-between">
    <div>
      <v-card-title>iRC</v-card-title>
      <v-card-subtitle
        >Geldig tot:
        {{ this.ircLicense.expiration_date | formatDate }}</v-card-subtitle
      >
    </div>
    <v-container class="justify-end align-content-end">
      <v-switch
        label="Logging"
        @change="updateLicense"
        v-model="input.logging"
        :loading="loading.logging"
      />
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <div v-on="revokingDisabled.locked ? on : null">
            <v-btn
              color="warning"
              @click="openLicenseRevokeDialog"
              :disabled="revokingDisabled.locked"
              >Licentie intrekken</v-btn
            >
          </div>
        </template>
        {{ revokingDisabled.reason | translate }}
      </v-tooltip>
    </v-container>
    <v-dialog v-model="revokeDialog.show" width="600">
      <v-sheet>
        <v-container>
          <h1 class="headline">Licentie intrekken</h1>
          <p>U staat op het punt een licentie in te trekken</p>
          <p>Weet u dit zeker?</p>
          <v-btn
            @click="revokeLicense"
            class="mr-2"
            :loading="revokeDialog.loading"
            color="primary"
            >Bevestigen</v-btn
          >
          <v-btn color="error" @click="revokeDialog.show = false"
            >Annuleren</v-btn
          >
        </v-container>
      </v-sheet>
    </v-dialog>
  </v-card>
</template>

<script>
import RepositoryFactory from "@/repositories/RepositoryFactory";

export default {
  props: {
    user: {
      type: Object
    },
    ircLicense: {
      type: Object
    }
  },
  name: "IRCLicenseActions",
  data() {
    return {
      input: {
        id: undefined,
        logging: false
      },
      loading: {
        logging: false
      },
      revokeDialog: {
        show: false,
        loading: false
      }
    };
  },
  created() {
    if (this.ircLicense.meta !== null) {
      this.input.logging = this.ircLicense.meta.logging;
    }
  },
  methods: {
    updateLicense() {
      this.loading.logging = true;
      this.input.id = this.ircLicense.id;
      RepositoryFactory.get("ircLicenses")
        .update(this.input, this.ircLicense.id)
        .then(() => {
          this.$store.dispatch("organisations/loadOrganisations");
          this.revokeDialog.show = false;
        })
        .catch(() => (this.input.logging = !this.input.logging))
        .finally(() => (this.loading.logging = false));
    },
    revokeLicense() {
      if (!this.revokingDisabled.locked) {
        this.revokeDialog.loading = true;
        let data = this.ircLicense;
        data.user_id = null;
        RepositoryFactory.get("ircLicenses")
          .update(data, data.id)
          .then(() => {
            this.$store.dispatch("organisations/loadOrganisations");
            this.revokeDialog.show = false;
          })
          .catch(err => {
            this.$store.dispatch(
              "notifications/addFeedbackNotification",
              err.response.data.message || "error.generic"
            );
          })
          .finally(() => {
            this.revokeDialog.loading = false;
          });
      }
    },
    openLicenseRevokeDialog() {
      this.revokeDialog.show = true;
    }
  },
  computed: {
    revokingDisabled() {
      let locked = {
        locked: false
      };
      if (
        this.licenseTimeLocked &&
        !this.$store.getters["auth/user"].permissions.includes("admin")
      ) {
        locked.locked = true;
        locked.reason = "license.prereq.timelock";
      }
      if (this.user.licenses.some(el => el.product === "otk")) {
        locked.locked = true;
        locked.reason = "license.prereq.otk";
      }
      return locked;
    },
    licenseTimeLocked() {
      let assignmentDate = new Date(this.ircLicense.assignment_date);
      let timeLockExpiration = assignmentDate.setMonth(
        assignmentDate.getMonth() + 3
      );
      let today = new Date();
      return Date.parse(today) < timeLockExpiration;
    }
  }
};
</script>

<style scoped></style>
