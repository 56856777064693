<template>
  <page :loading="!contentLoaded">
    <template v-slot:title>
      {{ currentOrganisation.id }} {{ currentOrganisation.name }}
      <div class="subtitle-1">Licentiebeheer</div>
    </template>
    <template v-slot:tabs>
      <v-tabs v-model="tab">
        <v-tab>iRC</v-tab>
        <v-tab v-if="asAdmin">Licentielijst</v-tab>
      </v-tabs>
    </template>
    <template>
      <v-tabs-items v-model="tab">
        <v-tab-item>
          <irc-licenses
            :is-admin="asAdmin"
            :current-organisation="currentOrganisation"
          />
        </v-tab-item>
        <v-tab-item>
          <LicenseList v-if="asAdmin" :organisation="currentOrganisation"/>
        </v-tab-item>
      </v-tabs-items>
    </template>
  </page>
</template>

<script>
import IRCLicenses from "@/components/Licenses/IRCLicenses";
import { mapGetters } from "vuex";
import LicenseList from "@/components/Licenses/LicenseList";
export default {
  name: "LicenseManagement",
  props: {
    organisation: {
      type: Number
    }
  },
  components: {
    LicenseList,
    "irc-licenses": IRCLicenses
  },
  data() {
    return {
      tab: 0
    };
  },
  computed: {
    ...mapGetters("organisations", ["getOrganisation"]),
    ...mapGetters("auth", ["user"]),
    currentOrganisation() {
      if (!this.user || !this.user.organisation_id) return undefined;
      let orgId = this.user.organisation_id;
      if (this.asAdmin) {
        orgId = this.organisation;
      }
      return this.getOrganisation(orgId);
    },
    asAdmin() {
      return this.$route.name === "admin.organisations.licenseManagement";
    },
    contentLoaded() {
      return this.currentOrganisation !== undefined;
    }
  },
  mounted() {
    let orgId = this.organisation || this.user.organisation_id;
    if (orgId) {
      this.$store.dispatch("organisations/loadOrganisation", orgId).then(() => {
        if (!this.currentOrganisation)
          this.$router.push({name: "error.notFound"});
      });
    } else {
      this.$router.push({name: "error.notFound"});
    }
  }
};
</script>

<style scoped></style>
