<template>
  <div>
    <v-app-bar color="primary" app clipped-left dark src="@/assets/topbar.png">
      <v-img
        src="@/assets/logo.png"
        max-width="50"
        contain
        position="left"
        style="cursor: pointer"
      ></v-img>
      <h1 class="display-1 ml-5">DigiPortal</h1>
    </v-app-bar>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "NoSidebar"
};
</script>

<style scoped></style>
