<template>
  <page :loading="!contentLoaded">
    <template v-slot:title>
      Klantenoverzicht
    </template>
    <template v-slot:default>
      <v-form class="d-flex">
        <v-checkbox
          label="Bijna verlopen licenties"
          class="mr-5"
          v-model="table.filters.almostExpired"
        />
        <v-checkbox
          label="Verlopen licenties"
          v-model="table.filters.expired"
        />
      </v-form>
      <v-data-table
        :items="computedOrganisations"
        :headers="table.headers"
        @click:row="selectOrganisation"
        class="row-pointer"
        :search="search"
      >
        <template v-slot:top>
          <v-text-field
            prepend-icon="mdi-magnify"
            label="Zoeken..."
            v-model="search"
          />
        </template>
      </v-data-table>
      <!--      <license-management-->
      <!--        :show="licenseManagement.show"-->
      <!--        :organisation="licenseManagement.selectedOrganisation"-->
      <!--        @updateShow="val => (licenseManagement.show = val)"-->
      <!--      />-->
    </template>
  </page>
</template>

<script>
import { mapGetters } from "vuex";
// import LicenseManagement from "@/components/Billing/LicenseManagement";
export default {
  name: "CustomerOverview",
  components: {
    // LicenseManagement
  },
  data() {
    return {
      search: "",
      table: {
        headers: [
          {
            text: "ID",
            value: "id"
          },
          {
            text: "Naam",
            value: "name"
          },
          {
            text: "Aantal gebruikers",
            value: "member_count"
          },
          {
            text: "Aantal licenties",
            value: "license_count"
          }
        ],
        filters: {
          almostExpired: false,
          expired: false
        }
      },
      licenseManagement: {
        selectedOrganisation: undefined,
        show: false
      }
    };
  },
  methods: {
    compareDates(first, second) {
      let firstUnix = new Date(first).getTime();
      let secondUnix = new Date(second).getTime();
      return firstUnix < secondUnix;
    },
    selectOrganisation(org) {
      this.licenseManagement.selectedOrganisation = org;
      // this.licenseManagement.show = true;
      this.$router.push({
        name: "admin.billing.customers.details",
        params: { organisation: org.id }
      });
    }
  },
  computed: {
    ...mapGetters("organisations", ["organisations"]),
    computedOrganisations() {
      return this.organisations
        ? this.organisations
            .map(el => {
              el.license_count = el.licenses.length;
              return el;
            })
            .filter(el => {
              if (this.table.filters.expired) {
                return el.licenses.some(license =>
                  this.compareDates(license.expiration_date, Date.now())
                );
              }
              if (this.table.filters.almostExpired) {
                let nextMonth = new Date();
                nextMonth.setMonth(nextMonth.getMonth() + 1);
                return el.licenses.some(license =>
                  this.compareDates(license.expiration_date, nextMonth)
                );
              } else return true;
            })
        : [];
    },
    contentLoaded() {
      return this.organisations !== undefined;
    }
  },
  mounted() {
    if (!this.organisations || this.organisations.length === 0) {
      this.$store.dispatch("organisations/loadOrganisations");
    }
  }
};
</script>

<style scoped>
.row-pointer > .v-data-table__wrapper > table > tbody > tr:hover {
  cursor: pointer;
}
</style>
