<template>
  <page :loading="!contentLoaded">
    <template v-slot:title>Organisaties</template>
    <v-data-table
      :headers="headers"
      :items="organisations"
      :search="searchTerm"
      @click:row="navigateToOrganisation"
      class="row-pointer"
    >
      <template v-slot:top>
        <v-text-field
          prepend-icon="mdi-magnify"
          label="Zoeken..."
          v-model="searchTerm"
        />
      </template>
      <template v-slot:footer>
        <v-divider />
        <v-layout justify-end>
          <v-btn
            text
            color="primary"
            class="mt-2 mb-1"
            :to="{ name: 'admin.organisations.new' }"
            >Nieuwe organisatie</v-btn
          >
        </v-layout>
      </template>
    </v-data-table>
  </page>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "Organisations",
  components: {},
  data() {
    return {
      headers: [
        {
          text: "ID",
          value: "id",
          width: 100
        },
        {
          text: "Naam",
          value: "name"
        },
        {
          text: "Aantal gebruikers",
          value: "member_count"
        }
      ],
      searchTerm: ""
    };
  },
  methods: {
    navigateToOrganisation(row) {
      this.$router.push({
        name: "admin.organisations.details",
        params: { organisation: row.id }
      });
    }
  },
  computed: {
    ...mapGetters("organisations", ["organisations"]),
    contentLoaded() {
      return this.organisations !== undefined;
    }
  },
  mounted() {
    this.$store.dispatch("organisations/loadOrganisations");
  }
};
</script>

<style scoped>
.row-pointer > .v-data-table__wrapper > table > tbody > tr:hover {
  cursor: pointer;
}
</style>
