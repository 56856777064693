import Vue from "vue";
import Vuetify from "vuetify/lib";
import "@mdi/font/css/materialdesignicons.css";

Vue.use(Vuetify);

import nl from "vuetify/lib/locale/nl";

export default new Vuetify({
  lang: {
    locales: { nl },
    current: "nl"
  },
  icons: {
    iconfont: "mdi"
  },
  theme: {
    themes: {
      light: {
        // primary: "#385072"
        // primary: "#16162f"
        primary: "#305e80",
        secondary: "#217798"
      },
      dark: {
        primary: "#385072"
      }
    }
  }
});
