var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('page',{attrs:{"loading":!_vm.contentLoaded},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("Gebruikeroverzicht globaal")]},proxy:true}])},[[_c('v-card',[_c('v-card-title',[_vm._v(" Alle gebruikers "),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Zoeken","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.allUsers,"item-key":this.setItemKey,"search":_vm.search,"custom-filter":_vm.customSearch},on:{"click:row":_vm.openUserDialog},scopedSlots:_vm._u([{key:"item.full_name",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.first_name + " " + item.last_name)+" ")])]}},{key:"item.first_name",fn:function(ref){
var item = ref.item;
return [_c('span',[(item.permissions && item.permissions.includes('customer'))?_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-crown")]):_vm._e(),_vm._v(" "+_vm._s(item.first_name)+" ")],1)]}},{key:"item.email",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.email)+" "),(item.pending)?_c('v-chip',{staticClass:"ma-2",attrs:{"color":"primary","x-small":""}},[_vm._v("Gebruiker nog niet geregistreerd")]):_vm._e()],1)]}},{key:"item.organisation_name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.organisation_name)+" ")]}},{key:"item.username",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.licenses && item.licenses[0] && item.licenses[0].meta ? "irc/" + item.licenses[0].meta.username : "")+" ")])]}}])})],1)],_c('user-details-dialog',{attrs:{"user":_vm.userDialog.user,"organisation":_vm.userDialog.organisation},on:{"dialogClose":_vm.destroyUserDialogUser}}),_c('v-dialog',{attrs:{"width":"600"},model:{value:(_vm.pendingUserDialog.show),callback:function ($$v) {_vm.$set(_vm.pendingUserDialog, "show", $$v)},expression:"pendingUserDialog.show"}},[_c('v-card',[_c('v-toolbar',{attrs:{"color":"teal","dark":""}},[_c('v-icon',{staticClass:"toolbar-title-icon"},[_vm._v("mdi-account-alert")]),_c('v-toolbar-title',[_vm._v("Gebruiker (pending)")])],1),_c('v-sheet',{staticClass:"pa-2"},[_c('v-btn',{attrs:{"depressed":"","color":"error"},on:{"click":_vm.deletePendingUser}},[_vm._v(" Gebruiker verwijderen ")])],1)],1)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }