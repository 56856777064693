<template>
  <page :loading="!contentLoaded">
    <template v-slot:title>{{ currentOrganisation.id }} {{ currentOrganisation.name }}</template>
    <template v-if="currentOrganisation">
      <v-row>
        <v-col cols="6">
          <v-card height="100%">
            <v-card-title class="flex justify-space-between">
              <span>Details</span>
              <div v-if="organisationHasMembers">
                <v-btn v-if="asAdmin" color="primary" class="justify-end" text @click="downloadOrganisationAsExcel">Gegevens exporteren</v-btn>
              </div>
              <div v-else>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-if="asAdmin" color="primary" class="justify-end" v-bind="attrs" v-on="on" text>Gegevens exporteren</v-btn>
                  </template>
                  <span>Organisatie heeft geen gegevens om te exporteren.</span>
                </v-tooltip>
              </div>
            </v-card-title>
            <v-card-text>
              <table>
                <col width="120">
                <tr>
                  <td>Naam</td>
                  <td>{{ currentOrganisation.name }}</td>
                </tr>
                <tr>
                  <td>Telefoon</td>
                  <td>{{ currentOrganisation.phone }}</td>
                </tr>
                <tr>
                  <td>Website</td>
                  <td>{{ currentOrganisation.website }}</td>
                </tr>
                <tr>
                  <td>Toegevoegd</td>
                  <td>{{ currentOrganisation.created_at | formatDate }}</td>
                </tr>
              </table>
              <v-switch
                v-if="this.asAdmin"
                label="Tweefactor authenticatie"
                :input-value="currentOrganisation.twofactor_active"
                @click="update2faDialog.show = true"
                readonly
                :loading="twofactorStatus.loading"
              />
              <v-btn text color="error" v-if="asAdmin" @click="removeCustomerDialog.show = true">Organisatie verwijderen</v-btn>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="6">
          <v-card height="100%">
            <v-card-title>Beheer</v-card-title>
              <v-btn class="ml-2" text color="primary" :to="licenseManagement">Licenties</v-btn>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-card>
            <v-card-title>Gebruikers</v-card-title>
            <v-card-text>
              <v-data-table
                :headers="headers"
                :items="membersAndPending"
                :item-key="this.setItemKey"
                @click:row="openUserDialog"
                :item-class="rowItemClasses"
                class="row-pointer"
                :search="search"
                :custom-filter="customSearch"
              >
                <template v-slot:top>
                  <v-text-field
                    prepend-icon="mdi-magnify"
                    label="Zoeken..."
                    v-model="search"
                  />
                </template>
                <template v-slot:item.first_name="{ item }">
                  <span>
                    <v-icon color="primary" v-if="item.permissions && item.permissions.includes('customer')">mdi-crown</v-icon>
                    {{ item.first_name }}
                  </span>
                </template>
                <template v-slot:item.email="{ item }">
                  <span>
                    {{ item.email }}
                    <v-chip v-if="item.pending" class="ma-2" color="primary" x-small>Gebruiker nog niet geregistreerd</v-chip>
                  </span>
                </template>
                <template v-slot:item.username="{ item }">
                  <span>
                    {{ getIRCUsername(item) }}
                  </span>
                </template>
                <template v-slot:footer>
                  <v-divider />
                  <v-row class="pt-2 pb-2 pr-4" justify="end">
                    <v-btn color="primary" text @click="openCeateUserDialog">Nieuwe gebruiker</v-btn>
                  </v-row>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row v-if="asAdmin">
        <v-col>
          <v-card height="100%">
            <v-card-title>Admin notes</v-card-title>
            <v-card-text>
              <p>Hier komen aantekeningen</p>
              <v-divider />
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </template>
    <user-details-dialog :user="userDialog.user" @dialogClose="destroyUserDialogUser" :organisation="currentOrganisation" />
    <create-user-dialog v-model="createUserDialog.show" :organisation="organisation" />
    <v-dialog v-model="removeCustomerDialog.show" width="600">
      <v-card max-width="700" class="mx-auto">
        <v-toolbar color="teal" dark>
          <v-icon class="toolbar-title-icon">mdi-bank-remove</v-icon>
          <v-toolbar-title>Licentie toewijzen</v-toolbar-title>
        </v-toolbar>
        <v-sheet>
          <v-container>
            <p>U staat op het punt een organisatie te verwijderen. Hiermee worden alle aan de organisatie gekoppelde gebruikers en licenties onomkeerbaar vernietigd.</p>
            <p>Weet u dit zeker?</p>
            <v-btn
              @click="deleteOrganisation"
              class="mr-2"
              :loading="removeCustomerDialog.loading"
              color="primary"
            >Bevestigen</v-btn
            >
            <v-btn color="error" @click="removeCustomerDialog.show = false"
            >Annuleren</v-btn
            >
          </v-container>
        </v-sheet>
      </v-card>
    </v-dialog>

    <v-dialog v-model="pendingUserDialog.show" width="600">
      <v-card>
        <v-toolbar color="teal" dark>
          <v-icon class="toolbar-title-icon">mdi-account-alert</v-icon>
          <v-toolbar-title>Gebruiker (pending)</v-toolbar-title>
        </v-toolbar>
        <v-sheet class="pa-2">
          <v-btn depressed color="error" @click="deletePendingUser">
            Gebruiker verwijderen
          </v-btn>
        </v-sheet>
      </v-card>
    </v-dialog>
    <v-dialog v-model="update2faDialog.show" width="600">
      <v-card>
        <v-toolbar color="teal" dark>
          <v-icon class="toolbar-title-icon">mdi-account-alert</v-icon>
          <v-toolbar-title>Tweefactor authenticatie instellingen wijzigen</v-toolbar-title>
        </v-toolbar>
        <v-sheet>
          <v-container>
            <p>U staat op het punt de tweefactor authenticatie methode van deze organisatie te wijzigen. Hiermee worden alle aan de organisatie gekoppelde gebruikers in Duo aangemaakt of verwijderd.</p>
            <p>Weet u dit zeker?</p>
            <v-btn
                @click="update2faStatus"
                class="mr-2"
                :loading="twofactorStatus.loading"
                color="primary"
            >Bevestigen</v-btn
            >
            <v-btn color="error" @click="update2faDialog.show = false"
            >Annuleren</v-btn
            >
          </v-container>
        </v-sheet>
      </v-card>
    </v-dialog>
  </page>
</template>

<script>
import { mapGetters } from "vuex";
import UserDetailsDialog from "@/components/users/UserDetailsDialog";
import CreateUserDialog from "@/components/users/CreateUserDialog";
import RepositoryFactory from "@/repositories/RepositoryFactory";
export default {
  name: "OrganisationDetails",
  props: ["organisation"],
  components: {
    UserDetailsDialog,
    CreateUserDialog
  },
  data() {
    return {
      search: "",
      headers: [
        { text: 'Voornaam', value: 'first_name' },
        { text: 'Achternaam', value: 'last_name' },
        { text: 'E-mailadres', value: 'email' },
        { text: 'Gebruikersnaam iRC', value: 'username', sortable: false }
      ],
      pendingUserDialog: {
        user: undefined,
        show: false
      },
      userDialog: {
        user: undefined
      },
      createUserDialog: {
        show: false
      },
      removeUserDialog: {
        show: false,
        user: undefined
      },
      removeCustomerDialog: {
        show: false,
        loading: false
      },
      twofactorStatus: {
        loading: false
      },
      update2faDialog: {
        show: false,
      }
    };
  },
  methods: {
    customSearch (value, search, item) {
      let searchVals = {
        first_name: item.first_name,
        last_name: item.last_name,
        email: item.email,
        username: this.getIRCUsername(item)
      }
      return Object.values(searchVals).some(v=> v&&v.toString().toLowerCase().includes(search.toLowerCase()))
    },
    deletePendingUser() {
      if (this.pendingUserDialog.user.pending) {
        RepositoryFactory.get("pendingUsers")
          .delete(this.pendingUserDialog.user.id)
          .then(() => {
            this.$store.dispatch("notifications/addFeedbackNotification",
              "feedback.users.deleted");
          })
          .catch(() => {
            this.$store.dispatch("notifications/addFeedbackNotification",
              "error.generic");
          })
          .finally(() => {
            this.pendingUserDialog.show = false;
          })
      }
    },
    openUserDialog(user) {
      if (user.pending) {
        this.pendingUserDialog.user = user;
        this.pendingUserDialog.show = true;
        return;
      }

      if (user) {
        this.userDialog.user = user;
      }
    },
    destroyUserDialogUser() {
      this.userDialog.user = undefined;
    },
    openCeateUserDialog() {
      this.createUserDialog.show = true;
    },
    downloadOrganisationAsExcel() {
      RepositoryFactory.get("organisations").downloadOrganisationAsExcel(this.organisation)
        .then(res => {
          const blob = new Blob([res.data], { type: "text/xml" });
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `${this.currentOrganisation.name}.xlsx`);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
    },
    getIRCUsername(user) {
      if (user) {
        if (!user.licenses || !user.licenses[0] || !user.licenses[0].meta)
          return "";
        if (user.licenses[0].meta.username) {
          return `irc\\${user.licenses[0].meta.username}`;
        }
      }
      return "";
    },
    deleteOrganisation() {
      this.removeCustomerDialog.loading = true;
      RepositoryFactory.get("organisations")
        .delete(this.currentOrganisation.id)
        .then(() => {
          this.$store.dispatch("notifications/addFeedbackNotification",
            "feedback.organisation.deleted");
          this.$store.dispatch("organisations/loadOrganisations");
          this.$router.push({ name: "admin.organisations" });
        })
        .catch(() => {
          this.$store.dispatch("notifications/addFeedbackNotification",
            "error.generic");
        })
        .finally(() => {
          this.removeCustomerDialog.loading,
          this.removeCustomerDialog.show = false
        })
    },
    update2faStatus() {
      this.twofactorStatus.loading = true;
      RepositoryFactory.get("organisations")
        .update(this.organisation, { twofactor_active: !this.currentOrganisation.twofactor_active })
        .then(() => this.$store.dispatch("organisations/loadOrganisation", this.currentOrganisation.id))
        .catch(() => this.$store
          .dispatch("notifications/addFeedbackNotification", "error.generic"))
        .finally(() => {
          this.twofactorStatus.loading = false;
          this.update2faDialog.show = false;
        });
    },
    cancelUpdate2faStatus() {

    },
    rowItemClasses(item) {
      if (item.pending) return "text--lighten-2";
      return ""
    },
    usersAndAdmins() {
      let admins = [];
      let users = [];
      this.currentOrganisation.members.forEach(user => {
        if (user.permissions && user.permissions.includes("customer")) {
          admins.push(user);
        } else {
          users.push(user);
        }
      });
      return [...admins, ...users];
    }
  },
  computed: {
    ...mapGetters("organisations", [
      "getOrganisation"
    ]),
    ...mapGetters("auth", [
        "user"
    ]),
    setItemKey(id) {
      return id + "item";
    },
    currentOrganisation() {
      let orgId = this.user.organisation_id;
      if (this.asAdmin) {
        orgId = this.organisation;
      }
      let org = this.getOrganisation(orgId);
      return org;
    },
    organisationHasMembers() {
      return this.currentOrganisation.members.length > 0;
    },
    asAdmin() {
       return this.$route.name === "admin.organisations.details";
    },
    licenseManagement() {
      if (this.asAdmin) {
        return { name: "admin.organisations.licenseManagement" };
      }
      return { name: "customer.organisation.licenseManagement"}
    },
    coursesOverview() {
      if (this.asAdmin) {
        return { name: "admin.organisations.coursesOverview" };
      }
      return { name: "customer.organisation.coursesOverview"}
    },
    contentLoaded() {
      return this.currentOrganisation !== undefined;
    },
    membersAndPending() {
      if (!this.currentOrganisation) return [];
      return [
          ...this.usersAndAdmins().map(el => (
            {...el, pending: false, full_name: el.first_name + " " + el.last_name}
          )),
          ...this.currentOrganisation.pending_members.map(el => (
            {...el, pending: true, full_name: el.first_name + " " + el.last_name}
          ))
      ];
    }
  },
  mounted() {
    let orgId = this.organisation || this.user.organisation_id;
    if (orgId) {
      this.$store.dispatch("organisations/loadOrganisation", orgId)
          .then(() => {
            if (!this.currentOrganisation)
              this.$router.push({name: "error.notFound"});
          });
    } else {
      this.$router.push({name: "error.notFound"});
    }
  }
};
</script>

<style scoped>
.row-pointer > .v-data-table__wrapper > table > tbody > tr:hover {
  cursor: pointer;
}
</style>
