<template>
  <page :loading="!contentLoaded">
    <template v-slot:title>Gebruikeroverzicht globaal</template>
    <template>
      <v-card>
        <v-card-title>
          Alle gebruikers
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Zoeken"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>

        <v-data-table
          :headers="headers"
          :items="allUsers"
          :item-key="this.setItemKey"
          class="elevation-1"
          :search="search"
          :custom-filter="customSearch"
          @click:row="openUserDialog"
        >
          <template v-slot:item.full_name="{ item }">
            <span>
              {{ item.first_name + " " + item.last_name }}
            </span>
          </template>
          <template v-slot:item.first_name="{ item }">
            <span>
              <v-icon
                color="primary"
                v-if="item.permissions && item.permissions.includes('customer')"
                >mdi-crown</v-icon
              >
              {{ item.first_name }}
            </span>
          </template>
          <template v-slot:item.email="{ item }">
            <span>
              {{ item.email }}
              <v-chip v-if="item.pending" class="ma-2" color="primary" x-small
                >Gebruiker nog niet geregistreerd</v-chip
              >
            </span>
          </template>
          <template v-slot:item.organisation_name="{ item }">
            {{ item.organisation_name }}
          </template>
          <template v-slot:item.username="{ item }">
            <span>
              {{
                item.licenses && item.licenses[0] && item.licenses[0].meta
                  ? "irc/" + item.licenses[0].meta.username
                  : ""
              }}
            </span>
          </template>
        </v-data-table>
      </v-card>
    </template>
    <user-details-dialog
      :user="userDialog.user"
      @dialogClose="destroyUserDialogUser"
      :organisation="userDialog.organisation"
    />
    <v-dialog v-model="pendingUserDialog.show" width="600">
      <v-card>
        <v-toolbar color="teal" dark>
          <v-icon class="toolbar-title-icon">mdi-account-alert</v-icon>
          <v-toolbar-title>Gebruiker (pending)</v-toolbar-title>
        </v-toolbar>
        <v-sheet class="pa-2">
          <v-btn depressed color="error" @click="deletePendingUser">
            Gebruiker verwijderen
          </v-btn>
        </v-sheet>
      </v-card>
    </v-dialog>
  </page>
</template>

<script>
import { mapGetters } from "vuex";
import UserDetailsDialog from "@/components/users/UserDetailsDialog";
import RepositoryFactory from "@/repositories/RepositoryFactory";

export default {
  name: "GlobalOverview",
  components: {
    UserDetailsDialog
  },
  data() {
    return {
      search: "",
      userDialog: {
        user: undefined,
        organisation: undefined
      },
      pendingUserDialog: {
        user: undefined,
        show: false
      },
      headers: [
        { text: "Voornaam", value: "first_name" },
        { text: "Achternaam", value: "last_name" },
        { text: "Emailadres", value: "email" },
        { text: "Organisatie", value: "organisation_name" },
        { text: "Aantal logins", value: "irc_login_count" },
        { text: "Gebruikersnaam iRC", value: "username" }
      ]
    };
  },

  methods: {
    customSearch (value, search, item) {
      let searchVals = {
        first_name: item.first_name,
        last_name: item.last_name,
        email: item.email,
        organisation_name: item.organisation_name,
        username: item.licenses && item.licenses[0] && item.licenses[0].meta
            ? "irc/" + item.licenses[0].meta.username
            : ""
      };
      return Object.values(searchVals).some(v=>v&&v.toString().toLowerCase().includes(search.toLowerCase()))
    },
    deletePendingUser() {
      if (this.pendingUserDialog.user.pending) {
        RepositoryFactory.get("pendingUsers")
          .delete(this.pendingUserDialog.user.id)
          .then(() => {
            this.$store.dispatch(
              "notifications/addFeedbackNotification",
              "feedback.users.deleted"
            );
          })
          .catch(() => {
            this.$store.dispatch(
              "notifications/addFeedbackNotification",
              "error.generic"
            );
          })
          .finally(() => {
            this.pendingUserDialog.show = false;
          });
      }
    },
    openUserDialog(user) {
      if (user.pending) {
        this.pendingUserDialog.user = user;
        this.pendingUserDialog.show = true;
        return;
      }

      if (user) {
        this.userDialog.user = user;
        //this.userDialog.organisation = user.organisation_id;
      }
    },
    destroyUserDialogUser() {
      this.userDialog.user = undefined;
    }
  },

  computed: {
    ...mapGetters("organisations", ["organisations"]),
    ...mapGetters("organisations", ["getOrganisation"]),
    contentLoaded() {
      return this.organisations !== undefined;
    },
    setItemKey(id) {
      return id + "item";
    },
    allUsers() {
      let users = [];

      // use map to get all users from all organisations
      this.organisations.map(organisation => {
        organisation.members.map(user => {
          user.full_name = user.first_name + " " + user.last_name;
          user.organisation_name = organisation.name;
          users.push(user);
        });
        organisation.pending_members.map(pendingUser => {
          pendingUser.full_name = pendingUser.first_name + " " + pendingUser.last_name;
          pendingUser.organisation_name = organisation.name;
          pendingUser.pending = true;
          users.push(pendingUser);
        });
      });

      return users;
    }
  },
  mounted() {
    this.$store.dispatch("organisations/loadOrganisations");
  }
};
</script>

<style scoped></style>
