<template>
  <page :loading="!contentLoaded">
    <template v-slot:title>
      Support
    </template>
    <template v-slot:default>
      <v-card max-width="344" outlined style="display: inline-block">
        <v-list-item three-line>
          <v-list-item-content>
            <v-list-item-title class="text-h5 mb-1">
              iRC Handleiding
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-card-actions>
          <v-btn text @click="ircGuide">
            Downloaden
          </v-btn>
        </v-card-actions>
      </v-card>
      <v-card
        outlined
        style="display: inline-block; margin-left: 1rem"
      >
        <v-list-item three-line>
          <v-list-item-content>
            <v-list-item-title class="text-h5 mb-1">
              DigiPortal Gebruikers Handleiding
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-card-actions>
          <v-btn text @click="digiPortalUserGuide">
            Downloaden
          </v-btn>
        </v-card-actions>
      </v-card>
      <v-card
        outlined
        style="display: inline-block; margin-left: 1rem"
      >
        <v-list-item three-line>
          <v-list-item-content>
            <v-list-item-title class="text-h5 mb-1">
              DigiPortal Beheerders Handleiding
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-card-actions>
          <v-btn text @click="digiPortalCustomerGuide">
            Downloaden
          </v-btn>
        </v-card-actions>
      </v-card>
    </template>
  </page>
</template>

<script>
import { mapGetters } from "vuex";
import RepositoryFactory from "@/repositories/RepositoryFactory";

export default {
  name: "Support",
  data() {
    return {
      dashboardButtons: {
        ircManualLoading: false,
        digiPortalManualLoading: false
      }
    };
  },
  computed: {
    ...mapGetters("auth", ["user"]),
    contentLoaded() {
      return this.user !== undefined;
    }
  },
  methods: {
    ircGuide() {
      this.dashboardButtons.ircManualLoading = true;
      RepositoryFactory.get("ircProduct").downloadManual()
        .then(res => {
          let blob = new Blob([res.data], { type: "application/pdf" });
          const link = document.createElement("a");
          let url = window.URL.createObjectURL(blob);
          link.setAttribute("href", url);
          link.setAttribute("download", "iRC Handleiding.pdf");
          link.style.visibility = "hidden";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
        .catch(() => {
          this.$store.dispatch(
            "notifications/addFeedbackNotification",
            "error.generic"
          );
        })
        .finally(() => {
          this.dashboardButtons.ircManualLoading = false;
        });
    },

    digiPortalUserGuide() {
      this.dashboardButtons.digiPortalManualLoading = true;
      RepositoryFactory.get("support")
        .downloadUserManual()
        .then(res => {
          let blob = new Blob([res.data], {
            type: "application/pdf"
          });
          const link = document.createElement("a");
          let url = URL.createObjectURL(blob);
          link.setAttribute("href", url);
          link.setAttribute("download", "DigiPortal Handleiding.pdf");
          link.style.visibility = "hidden";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
        .catch(() => {
          this.$store.dispatch(
            "notifications/addFeedbackNotification",
            "error.generic"
          );
        })
        .finally(() => {
          this.dashboardButtons.digiPortalManualLoading = false;
        });
    },
    digiPortalCustomerGuide() {
      this.dashboardButtons.digiPortalManualLoading = true;
      RepositoryFactory.get("support")
        .downloadCustomerManual()
        .then(res => {
          let blob = new Blob([res.data], {
            type: "application/pdf"
          });
          const link = document.createElement("a");
          let url = URL.createObjectURL(blob);
          link.setAttribute("href", url);
          link.setAttribute("download", "DigiPortal Handleiding.pdf");
          link.style.visibility = "hidden";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
        .catch(() => {
          this.$store.dispatch(
            "notifications/addFeedbackNotification",
            "error.generic"
          );
        })
        .finally(() => {
          this.dashboardButtons.digiPortalManualLoading = false;
        });
    }
  }
};
</script>

<style scoped></style>
