import store from "@/store";
import { setAuthHeader } from "@/repositories/AxiosFactory";

let interval = setInterval(() => {
  let token = store.getters["auth/token"];
  if (token) {
    setAuthHeader(token);
    clearInterval(interval);
  }
}, 2000);
