<template>
  <v-dialog width="800" v-model="show">
    <v-sheet v-if="license">
      <v-container>
        <v-select
          :items="usersWithoutLicense"
          label="Gebruiker"
          item-text="name"
          return-object
          v-model="selectedUser"
        />
        <v-row>
          <v-col>
            <v-btn
              color="primary"
              block
              @click="editLicense"
              :loading="loadingEdit"
              >Opslaan</v-btn
            >
          </v-col>
          <v-col>
            <v-btn color="error" block :disabled="!license.user_id"
              >Licentie intrekken</v-btn
            >
          </v-col>
        </v-row>
      </v-container>
    </v-sheet>
  </v-dialog>
</template>

<script>
import RepositoryFactory from "@/repositories/RepositoryFactory";

export default {
  props: {
    value: Boolean,
    license: Object,
    users: Array
  },
  name: "LicenseEditDialog",
  data() {
    return {
      show: false,
      loadingEdit: false,
      loadingRevoke: false,
      selectedUser: undefined
    };
  },
  methods: {
    editLicense() {
      let updatedLicense = this.license;
      updatedLicense.user_id = this.selectedUser.id;
      this.loadingEdit = true;
      RepositoryFactory.get("ircLicenses")
        .update(updatedLicense, updatedLicense.id)
        .then(() =>
          this.$store.dispatch(
            "notifications/addFeedbackNotification",
            "feedback.licenses.assigned"
          )
        )
        .catch(() =>
          this.$store.dispatch(
            "notifications/setErrorMessage",
            "feedback.licenses.error"
          )
        )
        .finally(() => {
          this.show = false;
          this.loadingEdit = false;
        });
    }
  },
  computed: {
    usersWithoutLicense() {
      if (!this.license) return [];
      return this.users.filter(el => {
        el.name = `${el.first_name} ${el.last_name}`.trim();
        if (this.license.user_id === el.id) return true;
        return el.licenses.every(
          license => license.product !== this.license.product
        );
      });
    }
  },
  watch: {
    value(val) {
      this.show = val;
    },
    show(val) {
      this.$emit("input", val);
    }
  }
};
</script>

<style scoped></style>
