<template>
  <page :loading="!contentLoaded">
    <template v-slot:title>
      {{ currentOrganisation.id }} {{ currentOrganisation.name }}
      <div class="subtitle-1">Facturering</div>
    </template>
    <template v-slot:default>
      <v-data-table
        :headers="table.headers.users"
        :items="members"
        show-select
        v-model="selectedUsers"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>Gebruikers</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              class="mb-2"
              @click="openExtendLicensesFromUsersDialog"
              :disabled="!selectedUsers.length > 0"
            >
              Licenties verlengen
            </v-btn>
          </v-toolbar>
        </template>
        <template v-slot:item.expiration_date="{ item }">
<!--          <div v-if="item.licenses[0]">-->
<!--            <span-->
<!--              class="red&#45;&#45;text"-->
<!--              v-if="-->
<!--                expirationState(item.licenses[0].expiration_date) === 'expired'-->
<!--              "-->
<!--              >{{ item.licenses[0].expiration_date }}</span-->
<!--            >-->
<!--            <span-->
<!--              class="orange&#45;&#45;text"-->
<!--              v-if="-->
<!--                expirationState(item.licenses[0].expiration_date) === 'expiring'-->
<!--              "-->
<!--              >{{ item.licenses[0].expiration_date }}</span-->
<!--            >-->
<!--            <span-->
<!--              class="green&#45;&#45;text"-->
<!--              v-if="-->
<!--                expirationState(item.licenses[0].expiration_date) === 'valid'-->
<!--              "-->
<!--              >{{ item.licenses[0].expiration_date }}</span-->
<!--            >-->
<!--          </div>-->
          <div v-for="license in item.licenses" :key="license.id">
            <b>{{ license.product }}</b>: {{ license.expiration_date }}
          </div>
        </template>
        <template v-slot:item.name="{ item }">
          {{ item.first_name + " " + item.last_name }}
        </template>
        <template v-slot:item.username="{ item }">
          {{
            item.licenses && item.licenses[0] && item.licenses[0].meta
              ? "irc/" + item.licenses[0].meta.username
              : ""
          }}
        </template>
        <template v-slot:item.user_id="{ item }">
          <v-icon color="green" v-if="item.user_id">mdi-check</v-icon>
          <v-icon color="red" v-else>mdi-close</v-icon>
        </template>
<!--        <template v-slot:item.licenses="{ item }">-->
<!--          <div v-for="license in item.licenses" :key="license.id">-->
<!--            {{ license.product }}-->
<!--          </div>-->
<!--        </template>-->
      </v-data-table>

      <v-data-table
        :headers="table.headers.licenses"
        :items="computedLicenses"
        show-select
        v-model="selectedLicenses"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>Niet toegewezen licenties</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              class="mb-2"
              @click="openBillCreatedDialog"
              :disabled="!selectedLicenses.length > 0"
            >
              Licenties verlengen
            </v-btn>
          </v-toolbar>
        </template>
        <template v-slot:item.expiration_date="{ item }">
          <span
            class="red--text"
            v-if="expirationState(item.expiration_date) === 'expired'"
          >{{ item.expiration_date }}</span
          >
          <span
            class="orange--text"
            v-if="expirationState(item.expiration_date) === 'expiring'"
          >{{ item.expiration_date }}</span
          >
          <span
            class="green--text"
            v-if="expirationState(item.expiration_date) === 'valid'"
          >{{ item.expiration_date }}</span
          >
        </template>
        <template v-slot:item.name="{ item }">
          {{ item.product }}
        </template>
      </v-data-table>

      <v-dialog width="600" v-model="extendLicensesFromUsersDialog.show">
        <v-sheet>
          <v-container>
            <h1 class="headline">Licenties verlengen</h1>
            <v-divider class="mb-3" />
            <p class="body-1">
              U staat op het punt om alle gekoppelde licenties van de onderstaande
              {{ selectedUsers.length > 1 ? "gebruikers" : "gebruiker" }} te
              verlengen:
            </p>
            <ol>
              <li v-for="user in selectedUsers" :key="user.id">
                {{ user.first_name + " " + user.last_name }}
              </li>
            </ol>
            <v-form class="mt-4">
              <v-menu min-width="auto">
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-on="on"
                    prepend-icon="mdi-calendar"
                    label="Nieuwe verloopdatum"
                    :value="extendLicensesFromUsersDialog.date"
                    readonly
                  />
                </template>
                <v-date-picker
                  v-model="extendLicensesFromUsersDialog.date"
                  :show-current="extendLicensesFromUsersDialog.date"
                />
              </v-menu>
              <v-btn
                :loading="extendLicensesFromUsersDialog.loading"
                color="primary"
                @click="extendLicensesFromUsers"
              >Verlengen</v-btn
              >
            </v-form>
          </v-container>
        </v-sheet>
      </v-dialog>

      <v-dialog width="600" v-model="createBillingDialog.show">
        <v-sheet>
          <v-container>
            <h1 class="headline">Licenties verlengen</h1>
            <v-divider class="mb-3" />
            <p class="body-1">
              U staat op het punt om {{ selectedLicenses.length }}
              {{ selectedLicenses.length > 1 ? "licenties" : "licentie" }} te
              verlengen.
            </p>
            <p class="body-1">
              Hiervoor wordt een bill gegenereerd die u kunt terugvinden in de
              billing historie.
            </p>
            <v-form>
              <v-menu min-width="auto">
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-on="on"
                    prepend-icon="mdi-calendar"
                    label="Nieuwe verloopdatum"
                    :value="createBillingDialog.date"
                    readonly
                  />
                </template>
                <v-date-picker
                  v-model="createBillingDialog.date"
                  :show-current="createBillingDialog.date"
                />
              </v-menu>
              <v-btn
                :loading="createBillingDialog.loading"
                color="primary"
                @click="createExtensionBill"
              >Verlengen</v-btn
              >
            </v-form>
          </v-container>
        </v-sheet>
      </v-dialog>
    </template>
  </page>
</template>

<script>
import { mapGetters } from "vuex";
import RepositoryFactory from "@/repositories/RepositoryFactory";
export default {
  props: {
    organisation: {
      type: String,
      required: true
    }
  },
  name: "CustomerDetails",
  data() {
    return {
      table: {
        headers: {
          licenses: [
            {
              text: "ID",
              value: "id"
            },
            {
              text: "Name",
              value: "name"
            },
            {
              text: "Verlopingsdatum",
              value: "expiration_date"
            }
          ],

          users: [
            {
              text: "ID",
              value: "id"
            },
            {
              text: "Name",
              value: "name"
            },
            {
              text: "Username",
              value: "username"
            },
            // {
            //   text: "Toegewezen licenties",
            //   value: "licenses"
            // },
            {
              text: "Verlopingsdatum",
              value: "expiration_date"
            }
          ]
        },
        filters: {
          expiredOnly: false,
          expiringOnly: false
        }
      },
      createBillingDialog: {
        show: false,
        date: undefined,
        loading: false
      },
      extendLicensesFromUsersDialog: {
        show: false,
        date: undefined,
        loading: false
      },
      selectedUsers: [],
      selectedLicenses: []
    };
  },
  methods: {
    expirationState(date) {
      let unixDate = new Date(date).getTime();
      let oneMonth = new Date();
      oneMonth.setMonth(oneMonth.getMonth() + 1);
      let now = new Date();
      if (unixDate < now.getTime()) return "expired";
      if (unixDate < oneMonth.getTime()) return "expiring";
      else return "valid";
    },
    openBillCreatedDialog() {
      this.createBillingDialog.date = this.oneYearFromNow;
      this.createBillingDialog.show = true;
    },
    createExtensionBill() {
      this.createBillingDialog.loading = true;
      let data = {
        expiration_date: this.createBillingDialog.date,
        licenses: this.selectedLicenses.map(el => el.id),
        organisation_id: this.currentOrganisation.id
      };
      RepositoryFactory.get("billing")
        .store(data)
        .then(() => {
          this.$store.dispatch("organisations/loadOrganisations");
          this.createBillingDialog.show = false;
          this.$store.dispatch(
            "notifications/addFeedbackNotification",
            "feedback.billing.created"
          );
        })
        .catch(() => {
          this.$store.dispatch(
            "notifications/setErrorMessage",
            "error.generic"
          );
        })
        .finally(() => {
          this.createBillingDialog.loading = false;
        });
    },

    openExtendLicensesFromUsersDialog() {
      this.extendLicensesFromUsersDialog.date = this.oneYearFromNow;
      this.extendLicensesFromUsersDialog.show = true;
    },
    extendLicensesFromUsers() {
      this.extendLicensesFromUsersDialog.loading = true;
      this.selectedUsers.forEach(user => {
        if (user.licenses.length > 0) {
          let data = {
            expiration_date: this.extendLicensesFromUsersDialog.date
          };
          RepositoryFactory.get("users")
            .updateAllLicenses(data, user.id)
            .then(() => {
              this.$store.dispatch("organisations/loadOrganisations");
              this.extendLicensesFromUsersDialog.show = false;
              this.$store.dispatch(
                "notifications/addFeedbackNotification",
                "feedback.billing.created"
              );
            })
            .catch(() => {
              this.$store.dispatch(
                "notifications/setErrorMessage",
                "error.generic"
              );
            })
            .finally(() => {
              this.extendLicensesFromUsersDialog.loading = false;
            });
        }
      });
    }
  },
  computed: {
    ...mapGetters("organisations", ["organisations", "getOrganisation"]),
    computedLicenses() {
      if (!this.currentOrganisation) return [];
      return this.currentOrganisation.licenses.filter(license => {
        return license.user_id === null;
      });
    },
    currentOrganisation() {
      return this.getOrganisation(this.organisation);
    },
    oneYearFromNow() {
      let date = new Date();
      date.setFullYear(date.getFullYear() + 1);
      return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
    },
    contentLoaded() {
      return this.currentOrganisation !== undefined;
    },
    members() {
      return this.currentOrganisation.members;
    }
  },
  mounted() {
    if (!this.organisations || this.organisations.length === 0) {
      this.$store.dispatch("organisations/loadOrganisations");
    }
  }
};
</script>

<style scoped></style>
