<template>
  <v-form class="col-2-grid">
    <v-text-field prefix="*" label="Bedrijfsnaam" v-model="input.name" />
    <v-text-field label="Telefoonnummer" v-model="input.phone" />
    <v-text-field label="Website" v-model="input.website" />
    <v-textarea label="Opmerkingen" v-model="input.notes" />
  </v-form>
</template>

<script>
export default {
  name: "OrganisationForm",
  props: ["value"],
  data() {
    return {
      input: {
        name: "",
        phone: "",
        website: "",
        notes: ""
      }
    };
  },
  methods: {
    updateValue() {
      this.$emit("input", this.input);
    }
  },
  watch: {
    input: {
      handler() {
        this.updateValue();
      },
      deep: true
    }
  }
};
</script>

<style scoped></style>
