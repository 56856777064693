import AxiosFactory from "@/repositories/AxiosFactory";

const resource = "/user-courses";

export default {
  post(data) {
    return AxiosFactory.post(`${resource}`, data);
  },
  update(data, id) {
    return AxiosFactory.put(`${resource}/${id}`, data);
  }
};
