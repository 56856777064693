import RepositoryFactory from "@/repositories/RepositoryFactory";
import { setAuthHeader, destroyAuthHeader } from "@/repositories/AxiosFactory";

const auth = {
  namespaced: true,
  state: () => ({
    user: undefined,
    token: undefined
  }),
  mutations: {
    login(state, payload) {
      state.user = payload.user;
      state.token = payload.token;
      setAuthHeader(payload.token);
    },
    logout(state) {
      state.user = undefined;
      state.token = undefined;
      destroyAuthHeader();
    },
    setUser(state, payload) {
      state.user = payload;
    }
  },
  actions: {
    authenticate({ commit }, credentials) {
      return new Promise((resolve, reject) => {
        RepositoryFactory.get("auth")
          .login(credentials)
          .then(res => {
            let data = {
              user: res.data.user,
              token: res.data.access_token
            };
            commit("login", data);
            return resolve(data);
          })
          .catch(err => reject(err));
      });
    },
    logout({ commit, dispatch }) {
      dispatch("organisations/clearOrganisations", null, { root: true });
      return commit("logout");
    },
    reloadUser({ commit }) {
      console.log("User is being reloaded");
      return new Promise(resolve => {
        RepositoryFactory.get("users")
          .reloadSelf()
          .then(res => {
            commit("setUser", res.data.data);
            return resolve(res.data.data);
          });
      });
    }
  },
  getters: {
    user(state) {
      return state.user;
    },
    licenses(state) {
      return state.user ? state.user.licenses : undefined;
    },
    token(state) {
      return state.token;
    },
    isAdmin(state) {
      return state.user.permissions && state.user.permissions.includes("admin");
    },
    isCustomer(state) {
      return (
        state.user.permissions && state.user.permissions.includes("customer")
      );
    }
  }
};

export default auth;
