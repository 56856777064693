import AxiosFactory from "@/repositories/AxiosFactory";

const resource = "/auth";

export default {
  login(credentials) {
    return AxiosFactory.post(`${resource}/login?include[]=licenses`, credentials);
  },
  register(credentials, signedLink) {
    return AxiosFactory.post(`${resource}/register?${signedLink}`, credentials);
  },
  registerAdmin(credentials, signedLink) {
    return AxiosFactory.post(
      `${resource}/admin/register?${signedLink}`,
      credentials
    );
  },
  confirmGoogleAuth(credentials) {
    return AxiosFactory.post(`${resource}/confirm-2fa`, credentials);
  },
  requestNewPassword(data) {
    return AxiosFactory.post(`${resource}/request-password-reset`, data);
  },
  resetPassword(data, endpoint) {
    return AxiosFactory.post(endpoint, data);
  }
};
