<template>
  <page :loading="false">
    <template v-slot:title>
      Tweefactorauthenticatie resetten
    </template>
    <div v-if="google2FA.username === '' && !expired">
      <information-block>
        <template v-slot:content>
          Voer uw wachtwoord in om de tweefactorauthenticatie te resetten
        </template>
      </information-block>
      <v-form class="col-2-grid">
        <v-text-field
          label="Wachtwoord"
          v-model="form.input.password"
          :append-icon="form.showPassword ? 'mdi-eye' : 'mdi-eye-off'"
          :type="form.showPassword ? 'text' : 'password'"
          @click:append="form.showPassword = !form.showPassword"
        />
      </v-form>
      <v-btn
        @click="reset2fa"
        color="primary"
        :disabled="form.input.password === ''"
      >Doorgaan</v-btn
      >
    </div>

    <div v-if="google2FA.activationQR !== undefined" style="width: 600px;">
      <h3 class="headline">
        Scan de onderstaande code met uw Authenticator app
      </h3>
      <v-row>
        <v-col cols="6">
          <v-img :src="this.google2FA.activationQR" />
        </v-col>
      </v-row>
      <v-form v-model="formRules.valid">
        <label for="2fa-code">Authenticator code</label>
        <v-otp-input
          id="2fa-code"
          length="6"
          type="number"
          :rules="formRules.authRules"
          v-model="authVerification.twoFACode"
        />
        <v-btn
          :loading="authVerification.loading"
          color="primary"
          @click="confirmGoogleAuth"
          :disabled="!formRules.valid"
          >Afronden</v-btn
        >
      </v-form>
    </div>

    <div v-if="authVerification.success">
      <h3 class="headline mb-2">Gelukt!</h3>
      <p class="body-1">
        U heeft uw tweefactorauthenticatie succesvol geactiveerd. U wordt
        over enkele seconden doorgestuurd naar het loginscherm.
      </p>
    </div>

    <div v-if="expired">
      <h3 class="headline mb-2">Verlopen!</h3>
      <p class="body-1">
        Uw tweefactorauthenticatie reset is verlopen. Indien u nogmaals uw tweefactorauthenticatie wilt
        resetten vraag dat dan aan uw accountbeheerder van uw organisatie.
      </p>
    </div>
  </page>
</template>

<script>
import RepositoryFactory from "@/repositories/RepositoryFactory";
import QRCode from "qrcode";
export default {
  name: "ResetTwoFactor",
  props: {
    signedUrl: String
  },

  data() {
    return {
      form: {
        showPassword: false,
        input: {
          password: ""
        }
      },
      google2FA: {
        activationQR: undefined,
        username: ""
      },
      formRules: {
        authRules: [r => !!r, r => r.length === 6],
        valid: false
      },
      authVerification: {
        twoFACode: "",
        loading: false,
        success: false
      },
      expired: false
    };
  },

  methods: {
    isExpired() {
      let url = atob(this.signedUrl);
      let signedParameters = url.substr(url.indexOf("?") + 1);
      let urlParams = new URLSearchParams(signedParameters);
      const userId = urlParams.get("user_id");

      RepositoryFactory.get("users")
        .checkReset2fa(userId)
        .then(res => {
          if (res.status === 200) {
            this.expired = res.data.expired;
          }
        })
        .catch(err => {
          console.log(err);

          this.$store.dispatch(
            "notifications/addFeedbackNotification",
            "error.generic"
          );
        });
    },

    reset2fa() {
      let url = atob(this.signedUrl);
      let endpoint = url.slice(url.indexOf("/api/v1") + 7);

      RepositoryFactory.get("users")
        .reset2fa(endpoint, this.form.input)
        .then(res => {
          if (res.status === 200) {
            const dataUrl = res.data.qr_code_url;
            QRCode.toDataURL(dataUrl).then(url => {
              this.google2FA.username = res.data.user.email;
              this.google2FA.activationQR = url;
            });
            // this.$store.dispatch(
            //   "notifications/addFeedbackNotification",
            //   "feedback.users.reset2fa"
            // );
          }
        })
        .catch(err => {
          console.log(err);

          this.$store.dispatch(
            "notifications/addFeedbackNotification",
            "error.generic"
          );
        });
    },

    confirmGoogleAuth() {
      this.authVerification.loading = true;

      RepositoryFactory.get("auth")
        .confirmGoogleAuth({
          email: this.google2FA.username,
          twoFACode: this.authVerification.twoFACode
        })
        .then(res => {
          if (res.status === 200) {
            console.log('updated');
          }
        })
        .catch(err => {
          let message = "";
          if (err.response.status === 422) {
            message = err.response.data.message;
          } else {
            message = "error.generic";
          }
          this.$store.dispatch(
            "notifications/addFeedbackNotification",
            message
          );
        })
        .finally(() => {
          this.authVerification.loading = false;
          this.authVerification.success = true;
        });
    }
  },

  mounted() {
    this.isExpired();
  },

  watch: {
    // expired: {
    //   handler(val) {
    //     if (val) {
    //       console.log("redirect");
    //     }
    //   }
    // }
  }
};
</script>

<style scoped>

</style>