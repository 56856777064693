<template>
  <page :loading="!contentLoaded">
    <template v-slot:title>Nieuwe organisatie</template>
    <template>
      <v-stepper v-model="step" class="elevation-0">
        <v-stepper-header class="elevation-0">
          <v-stepper-step step="1" :complete="step > 1"
            >Organisatie</v-stepper-step
          >
          <v-stepper-step step="2" :complete="step > 2"
            >Gebruikers</v-stepper-step
          >
        </v-stepper-header>
        <v-divider />
        <v-stepper-items>
          <v-stepper-content step="1">
            <organisation-form v-model="organisation.data" />
            <v-btn
              color="primary"
              :loading="organisation.loading"
              @click="createOrganisation"
              >Verder</v-btn
            >
          </v-stepper-content>
          <v-stepper-content step="2">
            <v-layout justify-end>
              <v-btn text color="primary" @click="contact.users.push({})"
                >Gebruiker toevoegen <v-icon>mdi-plus</v-icon></v-btn
              >
            </v-layout>
            <template v-for="(user, index) in contact.users">
              <user-form
                v-model="contact.users[index]"
                :key="index"
                :twofactor="organisation.data.twofactor_active"
              />
              <v-divider
                :key="index + 'divider'"
                v-if="index < contact.users.length - 1"
              />
            </template>
            <v-btn
              color="primary"
              @click="createContacts"
              :disabled="
                contact.users.some(el => !el || el.first_name === undefined)
              "
              >Opslaan</v-btn
            >
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </template>
  </page>
</template>

<script>
import OrganisationForm from "@/components/forms/OrganisationForm";
import UserForm from "@/components/forms/UserForm";
import RepositoryFactory from "@/repositories/RepositoryFactory";
export default {
  name: "NewOrganisation",
  components: {
    OrganisationForm,
    UserForm
  },
  data() {
    return {
      step: 1,
      organisation: {
        data: {
          twofactor_active: false
        },
        loading: false
      },
      contact: {
        loading: false,
        organisationId: undefined,
        users: [{}]
      }
    };
  },
  methods: {
    createOrganisation() {
      this.organisation.loading = true;
      RepositoryFactory.get("organisations")
        .createOrganisation(this.organisation.data)
        .then(res => {
          this.contact.organisationId = res.data.id;
          this.step = 2;
          this.$store.dispatch("organisations/loadOrganisations");
          this.$store.dispatch(
            "notifications/addFeedbackNotification",
            "feedback.organisation.created"
          );
        })
        .catch(() => {
          this.$store.dispatch(
            "notifications/addFeedbackNotification",
            "error.generic"
          );
        })
        .finally(() => (this.organisation.loading = false));
    },
    createContacts() {
      this.contact.users.map(el => {
        el.organisation_id = this.contact.organisationId;
        if (el.contact && el.products.length === 0) {
          el.products[0] = 0;
        }
        RepositoryFactory.get("invite").inviteUser(el);
      });
      this.$store.dispatch(
        "notifications/setErrorMessage",
        "feedback.users.invited"
      );
      this.$router.push({
        name: "admin.organisations.details",
        params: { organisation: this.contact.organisationId }
      });
    }
  },
  computed: {
    contentLoaded() {
      return true;
    }
  }
};
</script>

<style scoped></style>
