import AxiosFactory from "@/repositories/AxiosFactory";

const resource = "/admin/sales";

export default {
  downloadContactDetails() {
    return AxiosFactory.get(resource + "/contact-details", {
      responseType: "blob"
    });
  },

  downloadCompleteContactDetails() {
    return AxiosFactory.get(resource + "/contact-details-complete", {
      responseType: "blob"
    });
  },

  downloadOrganisationsMailinglist() {
    return AxiosFactory.get(resource + "/organisations-mailing-list", {
      responseType: "blob"
    });
  }
};
