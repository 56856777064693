<template>
  <page :loading="!contentLoaded">
    <template v-slot:title>Product: iRC</template>
    <template v-slot:tabs>
      <v-tabs v-model="tabs">
        <v-tab>iRC</v-tab>
        <v-tab>OTK</v-tab>
        <v-tab>DarkWeb</v-tab>
      </v-tabs>
    </template>
    <template>
      <v-tabs-items v-model="tabs">
        <v-tab-item>
          <!-- iRC  -->
        </v-tab-item>
        <v-tab-item>
          <!-- OTK -->
          <manage-otk :license="otkLicense" />
        </v-tab-item>
      </v-tabs-items>
    </template>
  </page>
</template>

<script>
import ManageOTK from "@/components/users/Products/ManageOTK";
import { mapGetters } from "vuex";
export default {
  name: "IRCOverview",
  components: {
    "manage-otk": ManageOTK
  },
  data() {
    return {
      tabs: 0
    };
  },
  computed: {
    ...mapGetters(["auth/user"]),
    currentOrganisation() {
      let org = this.$store.getters["organisations/getOrganisation"](
        this["auth/user"].organisation_id
      );
      if (!org) {
        this.$store.dispatch("organisations/loadOrganisations");
      }
      return org;
    },
    otkLicense() {
      if (!this.currentOrganisation) return undefined;
      return this.currentOrganisation.licenses.filter(el => {
        return el.product === "otk" && el.user_id === this["auth/user"].id;
      })[0];
    },
    contentLoaded() {
      return this.currentOrganisation !== undefined;
    }
  }
};
</script>

<style scoped></style>
