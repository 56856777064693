<template>
  <v-dialog v-model="dialog.show" width="800">
    <v-card max-width="800" class="mx-auto">
      <v-toolbar color="teal" dark>
        <v-icon class="toolbar-title-icon">mdi-account-plus</v-icon>
        <v-toolbar-title>Nieuwe gebruiker</v-toolbar-title>
      </v-toolbar>
      <v-sheet>
        <v-container>
          <information-block>
            <template v-slot:title>Gebruiker uitnodigen</template>
            <template v-slot:content>
              <p>
                Via dit scherm kunt u een gebruiker uitnodigen. De gebruiker
                krijgt vervolgens een e-mail met een link om een account aan te
                maken.
              </p>
              <p>
                Let wel: De gebruiker verschijnt pas in het overzicht als
                hij/zij het account geactiveerd heeft.
              </p>
            </template>
          </information-block>

          <template v-for="(user, index) in contact.users">
            <div :key="index + 'user'" class="text-h6">
              Gebruiker {{ index + 1 }}
              <div v-if="index">
                <v-btn
                  color="error"
                  @click="removeUser(index)"
                  class="ml-2"
                  small
                  >Verwijderen</v-btn
                >
              </div>
            </div>
            <user-form
              v-model="contact.users[index]"
              :key="index"
              :twofactor="currentOrganisation.twofactor_active"
            />
            <v-divider
              :key="index + 'divider'"
              v-if="index < contact.users.length - 1"
            />
          </template>

          <v-btn
            color="primary"
            @click="createContacts"
            :disabled="
              contact.users.some(el => !el || el.first_name === undefined)
            "
            >Uitnodigen</v-btn
          >
          <v-btn color="error" @click="dialog.show = false" class="ml-5"
            >Annuleren</v-btn
          >

          <v-btn color="primary" class="ml-5" @click="contact.users.push({})"
            >Gebruiker toevoegen <v-icon>mdi-plus</v-icon></v-btn
          >
        </v-container>
      </v-sheet>
    </v-card>
  </v-dialog>
</template>

<script>
import UserForm from "@/components/forms/UserForm";
import RepositoryFactory from "@/repositories/RepositoryFactory";
import { mapGetters } from "vuex";
export default {
  name: "CreateUserDialog",
  props: {
    value: Boolean,
    organisation: [Number, String]
  },
  components: {
    UserForm
  },
  data() {
    return {
      dialog: {
        show: false,
        loading: false
      },
      contact: {
        loading: false,
        organisationId: undefined,
        users: [{}]
      },
    };
  },
  methods: {
    removeUser(index) {
      index = index + "user";
      this.contact.users.splice(index, 1);
    },
    createContacts() {
      this.dialog.loading = true;
      this.contact.organisationId = this.currentOrganisation.id;
      this.contact.users.map(el => {
        el.organisation_id = this.contact.organisationId;
        if (el.contact && el.products.length === 0) {
          el.products[0] = 0;
        }
        RepositoryFactory.get("invite").inviteUser(el);
      });
      this.$store.dispatch(
        "notifications/addFeedbackNotification",
        "feedback.users.invited"
      );
      this.dialog.loading = false;
      this.dialog.show = false;
      this.contact.users = [];
    },
  },
  computed: {
    ...mapGetters("auth", ["user"]),
    ...mapGetters("organisations", ["getOrganisation"]),
    currentOrganisation() {
      let orgId = this.user.organisation_id;
      if (this.asAdmin) {
        orgId = this.organisation;
      }
      return this.getOrganisation(orgId);
    },
    asAdmin() {
      return this.$route.name.includes("admin.");
    }
  },
  watch: {
    dialog: {
      handler(val) {
        if (val.show !== this.value) {
          this.$emit("input", val.show);
        }
      },
      deep: true
    },
    value: {
      handler(val) {
        if (val !== this.dialog.show) {
          this.dialog.show = val;
        }
      }
    }
  }
};
</script>

<style scoped></style>
