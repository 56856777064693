import RepositoryFactory from "@/repositories/RepositoryFactory";

export default {
  namespaced: true,
  state: () => ({
    organisations: undefined
  }),
  mutations: {
    setOrganisations(state, organisations) {
      state.organisations = organisations;
    },
    updateOrSetOrganisation(state, organisation) {
      const index = state.organisations.findIndex(el => el.id == organisation.id);
      if (index !== -1) {
        state.organisations.splice(index, 1);
      }
      state.organisations = [...state.organisations, { ...organisation }].sort(
        (a, b) => a.id - b.id
      );
    },
    deleteMember(state, payload) {
      payload.organisation.members.splice(payload.member, 1);
    }
  },
  actions: {
    loadOrganisations({ commit }) {
      return new Promise(resolve => {
        RepositoryFactory.get("organisations")
          .index()
          .then(res => {
            let orgs = res.data.data;
            if (!Array.isArray(orgs)) orgs = [orgs];
            orgs = orgs.map(org => {
              if (org.members && org.licenses) {
                org.members = org.members.map(member => {
                  if (!member.licenses) {
                    member.licenses = org.licenses.filter(el => el.user_id === member.id)
                  }
                  return member;
                });
              }
              return org;
            });
            commit("setOrganisations", orgs);
            return resolve(res);
          });
      });
    },
    loadOrganisation({ commit }, id) {
      return new Promise(resolve => {
        RepositoryFactory.get("organisations")
          .get(id)
          .then(res => {
            let org = res.data.data;
            commit("updateOrSetOrganisation", org);
            return resolve(res);
          });
      });
    },
    clearOrganisations({ commit }) {
      commit("setOrganisations", []);
    },
    deleteMember({ commit }, member, organisation) {
      commit("deleteMember", member, organisation);
    }
  },
  getters: {
    organisations(state) {
      return state.organisations;
    },
    getOrganisation(state) {
      return orgId =>
        state.organisations
          ? state.organisations.filter(el => el.id == orgId)[0]
          : undefined;
    },
    getAllLicenses(state) {
      return state.organisations.map(el => el.licenses).flat();
    }
  }
};
