import AxiosFactory from "@/repositories/AxiosFactory";

const resource = "/invite";

export default {
  inviteUser(data) {
    return AxiosFactory.post(resource, data);
  },
  inviteAdmin(data) {
    return AxiosFactory.post(`${resource}/admin`, data);
  }
};
