import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "@/store";
import vuetify from "./plugins/vuetify";
import "@/components";
import "@/plugins/tokenChecker";
import moment from "moment";
import i18n from "./i18n";

Vue.config.productionTip = false;

Vue.filter("formatDate", date => {
  return moment(date).format("DD-MM-YYYY");
});

Vue.filter("formatDateTime", date => {
  return moment(date).format("DD-MM-YYYY HH:mm:ss");
});

Vue.filter("translate", text => {
  return i18n.t(text);
});

export default new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount("#app");
