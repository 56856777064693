<template>
  <v-card class="d-flex flex flex-column justify-space-between">
    <div>
      <v-card-title>DarkWeb</v-card-title>
      <v-card-subtitle
        >Geldig tot:
        {{ this.darkwebLicense.expiration_date | formatDate }}</v-card-subtitle
      >
    </div>
    <v-container class="">
      <v-switch
        label="Ingeschakeld"
        @change="updateDarkwebStatus"
        v-model="input.enabled"
        :loading="loading"
      />
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <div v-on="revokingDisabled.locked ? on : null">
            <v-btn
              color="warning"
              @click="openLicenseRevokeDialog"
              :disabled="revokingDisabled.locked"
              >Licentie intrekken</v-btn
            >
          </div>
        </template>
        {{ revokingDisabled.reason | translate }}
      </v-tooltip>
    </v-container>
    <v-dialog v-model="revokeDialog.show" width="600">
      <v-sheet>
        <v-container>
          <h1 class="headline">Licentie intrekken</h1>
          <p>U staat op het punt een licentie in te trekken</p>
          <p>Weet u dit zeker?</p>
          <v-btn
            @click="revokeLicense"
            class="mr-2"
            :loading="revokeDialog.loading"
            color="primary"
            >Bevestigen</v-btn
          >
          <v-btn color="error" @click="revokeDialog.show = false"
            >Annuleren</v-btn
          >
        </v-container>
      </v-sheet>
    </v-dialog>
  </v-card>
</template>

<script>
import RepositoryFactory from "@/repositories/RepositoryFactory";

export default {
  name: "DarkWebLicenseActions",
  props: {
    darkwebLicense: Object
  },
  data() {
    return {
      input: {
        enabled: false
      },
      revokeDialog: {
        show: false,
        loading: false
      },
      loading: false
    };
  },
  methods: {
    updateDarkwebStatus() {
      this.loading = true;
      RepositoryFactory.get("darkwebLicenses")
        .update(
          { ...this.darkwebLicense, active: this.input.enabled },
          this.darkwebLicense.id
        )
        .catch(() => (this.input.enabled = !this.input.enabled))
        .finally(() => (this.loading = false));
    },
    revokeLicense() {
      this.revokeDialog.loading = true;
      RepositoryFactory.get("darkwebLicenses")
        .update(
          { ...this.darkwebLicense, user_id: null },
          this.darkwebLicense.id
        )
        .then(() => {
          this.$store.dispatch("organisations/loadOrganisations");
          this.revokeDialog.show = false;
        })
        .catch(err => {
          this.$store.dispatch(
            "notifications/addFeedbackNotification",
            err.response.data.message || "error.generic"
          );
        })
        .finally(() => {
          this.revokeDialog.loading = false;
        });
    },
    openLicenseRevokeDialog() {
      this.revokeDialog.show = true;
    }
  },
  computed: {
    revokingDisabled() {
      let locked = {
        locked: false
      };
      if (
        this.licenseTimeLocked &&
        !this.$store.getters["auth/user"].permissions.includes("admin")
      ) {
        locked.locked = true;
        locked.reason = "license.prereq.timelock";
      }
      return locked;
    },
    licenseTimeLocked() {
      let assignmentDate = new Date(this.darkwebLicense.assignment_date);
      let timeLockExpiration = assignmentDate.setMonth(
        assignmentDate.getMonth() + 3
      );
      let today = new Date();
      return Date.parse(today) < timeLockExpiration;
    }
  },
  mounted() {
    this.input.enabled = this.darkwebLicense.active;
  }
};
</script>

<style scoped></style>
