<template>
  <page :loading="!contentLoaded">
    <template v-slot:title>
      Accountgegevens
      <span class="editDetailsSpan">
        <v-btn elevation="2" @click="editUserDialog.show = true">
          Wijzigen
        </v-btn>
      </span>
    </template>
    <template v-slot:default>
      <UserDetailsCard class="mb-4" :user="user" />
      <v-card>
        <v-container>
          <v-btn
            color="primary"
            outlined
            x-large
            class="mr-3"
            @click="changePasswordDialog.show = true"
          >
            <template v-slot:default>
              <div class="d-flex flex-column">
                <v-icon>mdi-lock-reset</v-icon>
                <span class="body-1 text-capitalize">Wijzig wachtwoord</span>
              </div>
            </template>
          </v-btn>
        </v-container>
      </v-card>
      <v-dialog v-model="editUserDialog.show" width="800">
        <v-card>
          <v-toolbar color="teal" dark>
            <v-icon class="toolbar-title-icon">mdi-account-edit</v-icon>
            <v-toolbar-title>Gebruikergegevens wijzigen</v-toolbar-title>
          </v-toolbar>
          <v-sheet>
            <UserDetailsForm
              :user="user"
              @submit="editUser"
              @cancel="editUserDialog.show = false"
            />
          </v-sheet>
        </v-card>
      </v-dialog>
      <v-dialog v-model="changePasswordDialog.show" width="800">
        <v-card>
          <v-toolbar color="teal" dark>
            <v-icon class="toolbar-title-icon">mdi-lock-reset</v-icon>
            <v-toolbar-title>Wijzig wachtwoord</v-toolbar-title>
          </v-toolbar>
          <v-sheet>
            <v-container>
              <information-block>
                <template v-slot:title>Wachtwoord wijzigen</template>
                <template v-slot:content>
                  Via dit formulier kunt u uw DigiPortal wachtwoord wijzigen.
                </template>
              </information-block>
              <v-form v-model="changePasswordDialog.valid">
                <v-text-field
                  label="Huidig wachtwoord"
                  v-model="changePasswordDialog.input.current_password"
                  :append-icon="
                    changePasswordDialog.showPassword
                      ? 'mdi-eye'
                      : 'mdi-eye-off'
                  "
                  :type="
                    changePasswordDialog.showPassword ? 'text' : 'password'
                  "
                  @click:append="
                    changePasswordDialog.showPassword = !changePasswordDialog.showPassword
                  "
                />
                <v-text-field
                  label="Wachtwoord"
                  :rules="changePasswordDialog.rules.password"
                  v-model="changePasswordDialog.input.password"
                  :append-icon="
                    changePasswordDialog.showPassword
                      ? 'mdi-eye'
                      : 'mdi-eye-off'
                  "
                  :type="
                    changePasswordDialog.showPassword ? 'text' : 'password'
                  "
                  @click:append="
                    changePasswordDialog.showPassword = !changePasswordDialog.showPassword
                  "
                />
                <v-text-field
                  label="Herhaal wachtwoord"
                  :rules="changePasswordDialog.rules.passwordConfirmation"
                  v-model="changePasswordDialog.input.password_confirmation"
                  :append-icon="
                    changePasswordDialog.showPassword
                      ? 'mdi-eye'
                      : 'mdi-eye-off'
                  "
                  :type="
                    changePasswordDialog.showPassword ? 'text' : 'password'
                  "
                  @click:append="
                    changePasswordDialog.showPassword = !changePasswordDialog.showPassword
                  "
                />
                <v-btn
                  color="primary"
                  :disabled="!changePasswordDialog.valid"
                  class="mt-2"
                  :loading="changePasswordDialog.loading"
                  @click="changePassword"
                  >Opslaan</v-btn
                >
              </v-form>
            </v-container>
          </v-sheet>
        </v-card>
      </v-dialog>
    </template>
  </page>
</template>

<script>
import { mapGetters } from "vuex";
import UserDetailsCard from "@/components/users/UserDetailsCard";
import UserDetailsForm from "@/components/users/UserDetailsForm";
import RepositoryFactory from "@/repositories/RepositoryFactory";

export default {
  name: "Details",
  components: { UserDetailsCard, UserDetailsForm },
  data() {
    return {
      editUserDialog: {
        show: false,
        loading: false
      },
      changePasswordDialog: {
        showPassword: false,
        showPasswordConfirmation: false,
        show: false,
        password: "",
        valid: false,
        loading: false,
        input: {
          current_password: "",
          password: "",
          password_confirmation: ""
        },
        rules: {
          password: [
            v => !!v || "Dit veld is verplicht",
            v => v.length >= 12 || "Wachtwoord moet minimaal 12 tekens bevatten"
          ],
          passwordConfirmation: [
            v => !!v || "Dit veld is verplicht",
            v =>
              v === this.changePasswordDialog.input.password ||
              "Dit veld moet gelijk zijn aan wachtwoord"
          ]
        }
      }
    };
  },
  methods: {
    editUser() {
      this.editUserDialog.loading = true;
      RepositoryFactory.get("users")
        .update(this.user, this.user.id)
        .then(() => {
          this.$store.dispatch(
            "notifications/addFeedbackNotification",
            "feedback.users.updated"
          );
          this.editUserDialog.show = false;
        });
    },

    changePassword() {
      let data = {
        current_password: this.changePasswordDialog.input.current_password,
        new_password: this.changePasswordDialog.input.password,
        new_password_confirmation: this.changePasswordDialog.input.password_confirmation
      };
      RepositoryFactory.get("users")
        .changePassword(data, this.user.id)
        .then(() => {
          this.$store.dispatch(
            "notifications/addFeedbackNotification",
            "feedback.users.updated"
          );
          this.changePasswordDialog.show = false;
        });
    }
  },
  computed: {
    ...mapGetters("auth", ["user"]),
    contentLoaded() {
      return this.user !== undefined;
    }
  }
};
</script>

<style scoped>
.editDetailsSpan {
  font-size: medium;
  float: right;
  cursor: pointer;
}

.editDetailsSpan:hover {
  color: #eaa53eff !important;
}
</style>
